import * as PIXI from 'pixi.js'
import { FloorPlanArtwork } from '../../../../types/FloorPlan/Artwork.types'
import { FloorPlanElement } from './floorPlanElement'
import { WallElement } from './wallElement'

class ArtowrkPreview extends PIXI.Container {
    constructor(title: string, arworkWidth: number, artworkHeight: number, url: string) {
        super()
        const width = 200
        const height = width * artworkHeight / arworkWidth

        const container = new PIXI.Container()

        const border = 20
        const graphic = new PIXI.Graphics()
            .beginFill(0xeeeeee)
            .lineStyle(2,0xdddddd)
            .drawRect(-border / 2, -border / 2, width + border, height + border * 2)
            .endFill()

        graphic.x = -width / 2
        graphic.y = -height / 2

        const texture = PIXI.Sprite.from(url)
        texture.width = width
        texture.height = height
        graphic.addChild(texture)

        container.addChild(graphic)

        const text = new PIXI.Text(title, { fontSize: 20 })
        text.resolution = 2
        text.x = -text.width / 2
        text.y = height / 2
        container.addChild(text)

        container.x = width / 2 + border * 2

        setTimeout(() => this.addChild(container), 500)

    }
}

export class ArtworkElement extends FloorPlanElement {
    wall: WallElement
    artworkData: FloorPlanArtwork
    graphic: PIXI.Graphics
    preview: ArtowrkPreview | null = null

    artworkWidth: number

    readonly ARTWORK_THICKNESS = 4

    constructor(
        wall: WallElement,
        artworkData: FloorPlanArtwork,
    ) {
        super("Artwork", artworkData.mapRefUUID)

        this.artworkData = artworkData
        this.wall = wall

        this.artworkWidth = 0

        this.graphic = new PIXI.Graphics()
        this.addChild(this.graphic)

        const _this = this

        this.graphic.on('pointerover', () => {
            _this.handlePointerOver()

            _this.preview = _this.app.stage.addChild(
                new ArtowrkPreview(
                    artworkData.title,
                    artworkData.width,
                    artworkData.height,
                    artworkData.imageURL
                ))
            _this.preview.x = _this.graphic.getGlobalPosition().x
            _this.preview.y = _this.graphic.getGlobalPosition().y
        })
        this.graphic.on('pointerout', () => {
            _this.handlePointerOut()
            if (_this.preview) {
                _this.preview.destroy()
            }
        })
        this.graphic.interactive = true

        this.reDraw()
        this.setHighlight(false)
    }

    handlePointerOver(): void {
        this.graphic.tint = 0xff7777
    }
    handlePointerOut(): void {
        this.setHighlight(false)
    }

    reDraw() {
        if (this.artworkWidth !== this.artworkData.width) {
            this.artworkWidth = this.artworkData.width
            this.graphic.clear()
                .beginFill(0xffffff)
                .drawRect(-this.artworkWidth / 2, -this.ARTWORK_THICKNESS / 2, this.artworkWidth, this.ARTWORK_THICKNESS)
                .endFill()
        }

        const sideSign = this.artworkData.side === 'right' ? -1 : 1
        this.pivot.y = (this.wall.wallThickness / 2 + this.ARTWORK_THICKNESS / 2) * sideSign
        this.pivot.x = this.artworkData.x * sideSign

        if (this.artworkData.side === 'left')
            this.x = this.wall.wallWidth / 2 - this.artworkWidth / 2
        else
            this.x = -(this.wall.wallWidth / 2 - this.artworkWidth / 2)
    }

    setHighlight(highlighted: boolean) {
        this.graphic.tint = highlighted ? 0x0000ff : 0xff0000
    }

    setInteractive(interactive: boolean): void {
        this.graphic.interactive = interactive
    }
}