import { Fragment } from "react"

export function HeaderProperty({ header }: { header: string }) {
    return (
        <Fragment>
            <div className='header'>
                <h3>{header}</h3>
            </div>
        </Fragment>
    )
}