import { OrientableComponent } from "./OrientableComponent.types"


export class Entrance extends OrientableComponent {

  constructor() {
    super('entrance')

    // default position
    this.x = 600
    this.y = 800
    this.orientation = "TOP"
  }
}
