import { createContext, useState } from "react";
import { SxProps } from "@mui/material/styles";
import { Box } from "@mui/material";

export const HideOverflowYContext = createContext<{ hideOverflowY: boolean, setHideOverflowY: any }>({
    hideOverflowY: false,
    setHideOverflowY: (a: boolean) => { }
});

export function InspectorBody({ children, sx }: { children: React.ReactNode, sx?: SxProps }) {
    const [hideOverflowY, setHideOverflowY] = useState(false)

    return (
        <Box sx={{ overflowY: "auto" }}>
            <HideOverflowYContext.Provider value={{ hideOverflowY, setHideOverflowY }}>
                <Box className='inspector-body body' sx={{ overflowY: hideOverflowY ? "hidden" : "unset", ...sx }}>
                    {children}
                </Box>
            </HideOverflowYContext.Provider>
        </Box>
    )
}