import { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setPropertyEdgeColor, setPropertyFrameColor, setPropertyDepth, setPropertyFrameMaterial, setPropertyFrameWidth, setPropertyHasFrame, setPropertyHeight, setPropertyName, setPropertyPassepartoutColor, setPropertyPassepartoutWidth, setPropertyWidth, setPropertyX, setPropertyY, setPropertyDeleted } from "../../../store/reducers/FloorPlan/FloorPlan";
import { FloorPlanArtwork, ArtworkFrameMatterial, ArtworkFrameMatterialToEngText } from "../../../types/FloorPlan/Artwork.types";
import { NumberPropertyInput } from "../Properties/NumberPropertyInput";
import { PropertyInput } from "../Properties/PropertyInput";
import { SelectPropertyInput } from "../Properties/SelectPropertyInput";
import { AccordionBooleanPropertyInput } from "../Properties/AccordionBooleanPropertyInput";
import { ColorPropertyInput } from "../Properties/ColorPropertyInput";
import { HeaderProperty } from "../Properties/HeaderProperty";
import { ButtonPropertyInput } from "../Properties/ButtonPropertyInput";
import { InspectorBody } from "../Layout/inspectorBody";
import { InspectorFooter } from "../Layout/inspectorFooter";
import { selectImageArtworks } from "../../../store/reducers/Artworks";
import { FileButtonInput } from "../Components/fileButtonInput";
import { IconButton } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Cookies from "js-cookie";
import { BaseInspectorOptions } from "../Components/defaultInspectorOptions";
import TidyUpIcon from './../../../assets/img/icon-tidy-up.svg'
import CenterIcon from './../../../assets/img/icon-center.svg'
import { FloorPlanApp } from "../../Content/pixi/floorPlanApp";

export function ArtworksSelectedInspector() {

    const floorPlanArtworks = useAppSelector(state => state.floorPlan.floorPlan?.artworks)
    const selectedData = useAppSelector(state => state.editor.selectedData)

    const allArtworks = useAppSelector(selectImageArtworks)

    const [keepAR, setKeepAr] = useState(Cookies.get("keepArtworkAR") !== "false") // default set to true
    useEffect(() => {
        Cookies.set("keepArtworkAR", keepAR.toString())
    }, [keepAR])

    const [artworks, setArtworks] = useState<FloorPlanArtwork[]>([])

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (floorPlanArtworks) {
            const newArtworks: FloorPlanArtwork[] = []
            selectedData.filter((e) => e.type === "Artwork").forEach(element => {
                const artwork = floorPlanArtworks[element.id]
                if (artwork)
                    newArtworks.push(artwork)
                else
                    console.warn(element.id + " not found as a artwork")
            })
            setArtworks(newArtworks)
        }
    }, [floorPlanArtworks, selectedData])


    return (
        <Fragment>
            <InspectorBody sx={{ position: "relative" }}>
                <PropertyInput
                    propertyName="title"
                    label="Name"
                    objects={artworks}
                    onValueChange={(name: string) => {
                        selectedData.forEach(data => {
                            dispatch(setPropertyName({
                                type: data.type,
                                id: data.id,
                                name
                            }))
                        })
                    }}
                />
                <NumberPropertyInput
                    propertyName={"width"}
                    label="Width"
                    objects={artworks}
                    onValueChange={(width) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyWidth({
                                type: data.type,
                                id: data.id,
                                width: width,
                                keepAR
                            }))
                        })
                    }
                />
                <IconButton onClick={() => { setKeepAr((pr) => !!!(pr)) }} sx={{ position: "absolute", marginTop: "-20px" }}>
                    {
                        keepAR ?
                            <LinkIcon /> :
                            <LinkOffIcon />
                    }
                </IconButton>
                <NumberPropertyInput
                    propertyName={"height"}
                    label="Height"
                    objects={artworks}
                    onValueChange={(height) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyHeight({
                                type: data.type,
                                id: data.id,
                                height: height,
                                keepAR
                            }))
                        })
                    }
                />
                <HeaderProperty header="Position" />
                <NumberPropertyInput
                    propertyName={"x"}
                    label="X"
                    objects={artworks}
                    onValueChange={(newX) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyX({
                                type: data.type,
                                id: data.id,
                                x: newX
                            }))
                        })
                    }
                />
                <NumberPropertyInput
                    propertyName={"y"}
                    label="Y"
                    objects={artworks}
                    onValueChange={(newY) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyY({
                                type: data.type,
                                id: data.id,
                                y: newY
                            }))
                        })
                    }
                />{/* 
            <NumberPropertyInput
                propertyName={"x"}
                label="Right"
                objects={artworks}
                onValueChange={(newX) =>
                    selectedData.forEach(data => {
                        dispatch(setPropertyX({
                            type: data.type,
                            id: data.id,
                            x: newX
                        }))
                    })
                }
                transformOperation={(x) => x - 100}
                inverseTransformOperation={(x) => x + 100}
            />
            <NumberPropertyInput
                propertyName={"y"}
                label="Top"
                objects={artworks}
                onValueChange={(newY) =>
                    selectedData.forEach(data => {
                        dispatch(setPropertyY({
                            type: data.type,
                            id: data.id,
                            y: newY
                        }))
                    })
                }
            /> */}
                <HeaderProperty header="Edge" />
                <NumberPropertyInput
                    propertyName={"depth"}
                    label="Depth"
                    objects={artworks}
                    onValueChange={(depth) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyDepth({
                                type: data.type,
                                id: data.id,
                                depth
                            }))
                        })
                    }
                    step={2}
                />
                <ColorPropertyInput
                    propertyName="edgeColor"
                    label="Color"
                    objects={artworks}
                    onValueChange={(color) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyEdgeColor({
                                type: data.type,
                                id: data.id,
                                edgeColor: color
                            }))
                        })
                    }
                />

                <HeaderProperty header="Frame" />
                <AccordionBooleanPropertyInput

                    propertyName="hasFrame"
                    label="Has Frame"
                    objects={artworks}
                    onValueChange={(hasFrame) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyHasFrame({
                                type: data.type,
                                id: data.id,
                                hasFrame: hasFrame
                            }))
                        })
                    }
                >

                    <NumberPropertyInput
                        propertyName={"frameWidth"}
                        label="Width"
                        objects={artworks}
                        onValueChange={(frameWidth) =>
                            selectedData.forEach(data => {
                                dispatch(setPropertyFrameWidth({
                                    type: data.type,
                                    id: data.id,
                                    frameWidth
                                }))
                            })
                        }
                        step={2}
                    />
                    <ColorPropertyInput
                        propertyName="frameColor"
                        label="Color"
                        objects={artworks}
                        onValueChange={(color) =>
                            selectedData.forEach(data => {
                                dispatch(setPropertyFrameColor({
                                    type: data.type,
                                    id: data.id,
                                    frameColor: color
                                }))
                            })
                        }
                    />
                    <SelectPropertyInput<ArtworkFrameMatterial, "frameMaterial">
                        keyValue={ArtworkFrameMatterialToEngText}
                        propertyName="frameMaterial"
                        label="Material"
                        objects={artworks}
                        onValueChange={(frameMaterial) =>
                            selectedData.forEach(data => {
                                dispatch(setPropertyFrameMaterial({
                                    type: data.type,
                                    id: data.id,
                                    frameMaterial
                                }))
                            })
                        }
                    />

                    <HeaderProperty header="Passepartout" />

                    <NumberPropertyInput
                        propertyName={"passepartoutWidth"}
                        label="Width"
                        objects={artworks}
                        onValueChange={(passepartoutWidth) =>
                            selectedData.forEach(data => {
                                dispatch(setPropertyPassepartoutWidth({
                                    type: data.type,
                                    id: data.id,
                                    passepartoutWidth
                                }))
                            })
                        }
                        step={2}
                    />

                    <ColorPropertyInput
                        propertyName="passepartoutColor"
                        label="Color"
                        objects={artworks}
                        onValueChange={(passepartoutColor) =>
                            selectedData.forEach(data => {
                                dispatch(setPropertyPassepartoutColor({
                                    type: data.type,
                                    id: data.id,
                                    passepartoutColor
                                }))
                            })
                        }
                    />

                </AccordionBooleanPropertyInput >

            </InspectorBody>

            <InspectorFooter>
                {Object.values(allArtworks).filter((s) => selectedData.map(sd => sd.id).includes(s.uuid)).map(o => o.mediaType)[0] === "VIDEO" &&
                    (
                        <FileButtonInput allArtworks={allArtworks} fileTypesAccept=".mp4" />
                    )}
                <ButtonPropertyInput
                    onClick={() => {
                        selectedData.forEach(data => {
                            dispatch(setPropertyDeleted({
                                type: data.type,
                                id: data.id,
                                deleted: true
                            }))
                        })
                    }}
                    color={"error"}
                    variant={"contained"}
                >
                    <span>Remove from wall</span>
                </ButtonPropertyInput>
                <BaseInspectorOptions options={
                    [
                        {
                            iconSrc: TidyUpIcon,
                            onClick: () => { FloorPlanApp.getInstance().artworkDndCanvas.tidyUpArtwork(selectedData[0].id) },
                            selected: false,
                            title: "Tidy up"
                        },
                        {
                            iconSrc: CenterIcon,
                            // onClick: () => dispatch(centerArtworkInLine({ artworkId: selectedData[0].id })),
                            onClick: () => {FloorPlanApp.getInstance().artworkDndCanvas.centerArtwork(selectedData[0].id)},
                            selected: false,
                            title: "Center"
                        },
                    ]
                } />
            </InspectorFooter>
        </Fragment>
    )
} 