import { Fragment, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { selectImageArtworks } from "../../store/reducers/Artworks"
import { createAndAddNewFloorPlanData, setSelectData, setToolDetails } from "../../store/reducers/Editor"
import { setPropertyArtowrkWall, setPropertyDepth, setPropertyHeight, setPropertyImageUrl, setPropertyTitle, setPropertyWidth, setPropertyX } from "../../store/reducers/FloorPlan/FloorPlan"
import DataPreviewGrid from "./Components/dataPreviewGrid"
import { InspectorBody } from "./Layout/inspectorBody"
import { InspectorHeader } from "./Layout/inspectorHeader"
import { ArtworksSelectedInspector } from "./SelectedInspector/artworksSelectedInspector"
import { InspectorFooter } from "./Layout/inspectorFooter"

import TidyUpIcon from './../../assets/img/icon-tidy-up.svg'
import CenterIcon from './../../assets/img/icon-center.svg'
import { BaseInspectorOptions } from "./Components/defaultInspectorOptions"
import { FloorPlanApp } from "../Content/pixi/floorPlanApp"


export function PlaceArtworksToolInspector() {

    const dispatch = useAppDispatch()

    const artworks = useAppSelector(selectImageArtworks)
    // const floorPlanWalls = useAppSelector(state => state.floorPlan.floorPlan?.walls)
    const selectedData = useAppSelector(state => state.editor.selectedData)

    const floorPlanArtworks = useAppSelector(state => state.floorPlan.floorPlan?.artworks)

    const selectedWall = useAppSelector(state => state.editor.currentTool.type === "PlaceArtworks" ? state.editor.currentTool.wallSelected : undefined)
    const selectedSide = useAppSelector(state => state.editor.currentTool.type === "PlaceArtworks" ? state.editor.currentTool.sideSelected : undefined)

    function setNewArtworkProperties(uuid: string) {
        const artwork = artworks[uuid]
        if (!artwork) return
        if (!selectedWall) return
        if (!selectedSide) return

        dispatch(createAndAddNewFloorPlanData({ type: "Artwork", id: uuid }))
        dispatch(setPropertyArtowrkWall({ type: "Artwork", id: uuid, wallId: selectedWall, side: selectedSide }))
        dispatch(setPropertyWidth({ type: "Artwork", id: uuid, width: artwork.width }))
        dispatch(setPropertyHeight({ type: "Artwork", id: uuid, height: artwork.height }))
        dispatch(setPropertyDepth({ type: "Artwork", id: uuid, depth: artwork.depth }))
        dispatch(setPropertyImageUrl({ type: "Artwork", id: uuid, imageURL: artwork.photo.medium }))
        dispatch(setPropertyTitle({ type: "Artwork", id: uuid, title: artwork.title.en_EN }))
        dispatch(setPropertyX({ type: "Artwork", id: uuid, x: 1000 }))
    }
    // const [selectedWall, setSelectedWall] = useState<SelectedData>()
    /*
        const [floorPlanArtworks, setFloorPlanArtworks] = useState<{ [id: string]: Artwork }>() 
        useEffect(() => {
            if (!floorPlanWalls) return
            setFloorPlanArtworks(Object.fromEntries(
                Object.values(floorPlanWalls)
                    .map(w => Object.values(w.artworks))
                    .reduce<Artwork[]>((pr, cr) => { return [...pr, ...cr] }, [])
                    .map(a => [a.mapRefUUID, a])
            ))
        }, [floorPlanWalls]) */

    const [placedArtworks, setPlacedArtworks] = useState<{ [key: string]: boolean }>({})
    useEffect(() => {
        if (!!selectedData[0]) {
            setPlacedArtworks(
                Object.fromEntries(Object.entries(artworks).map(([k, v]) => [k, !!(floorPlanArtworks && floorPlanArtworks[v.uuid] && floorPlanArtworks[v.uuid].deleted === false)]))

            )
        }
    }, [artworks, floorPlanArtworks, selectedData])

    useEffect(() => {
        setPlacedArtworks(
            Object.fromEntries(Object.entries(artworks).map(([k, v]) => [k, !!(floorPlanArtworks && floorPlanArtworks[v.uuid] && floorPlanArtworks[v.uuid].deleted === false)]))
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (selectedWall === undefined)
        return (
            <InspectorHeader>Select wall</InspectorHeader>
        )

    if (selectedData.length > 0)
        if (selectedData[0].type === "Artwork") {
            return (
                <Fragment>
                    <InspectorHeader
                        onClick={() => dispatch(setSelectData([]))}
                    >
                        Artwork
                    </InspectorHeader>
                    <ArtworksSelectedInspector />
                </Fragment>
            )
        }

    // console.log("fpartworks", floorPlanArtworks)
    if (selectedSide)
        return (
            <Fragment>
                <InspectorHeader
                    onClick={() => {
                        dispatch(setSelectData([]))
                        dispatch(setToolDetails({}))
                    }}
                >Place Artworks</InspectorHeader>
                <InspectorBody>
                    <DataPreviewGrid
                        data={Object.values(artworks).map((a) => {
                            return {
                                id: a.uuid,
                                previewImageURL: a.photo.original,
                                altText: a.title?.en_EN || a.photo.fileName
                            }
                        })}
                        dissabledData={placedArtworks}
                        onPointerDownData={(a) => {
                            if (!!!placedArtworks[a.id])
                                setNewArtworkProperties(a.id)

                            dispatch(setSelectData([{ id: a.id, type: "Artwork" }]))
                        }}
                    />
                </InspectorBody>

                <InspectorFooter>
                    <BaseInspectorOptions options={
                        [
                            {
                                iconSrc: TidyUpIcon,
                                onClick: () => { FloorPlanApp.getInstance().artworkDndCanvas.tidyUpArtworks() },
                                selected: false,
                                title: "Tidy up all"
                            },
                            {
                                iconSrc: CenterIcon,
                                onClick: () => { FloorPlanApp.getInstance().artworkDndCanvas.centerArtworks() },
                                selected: false,
                                title: "Center all"
                            },
                        ]
                    } />
                </InspectorFooter>
            </Fragment>
        )

    return (<div>None</div>)
} 