
import { Fragment } from 'react'

import ToolsPanel from "../components/ToolsPanel";

import Content from '../components/Content';
import { InspectorSelector } from '../components/Inspector/inspectorSelector';
import { LoadingPage } from './LoadingPage';
import { StepByStepHelp } from '../components/Content/stepByStepHelp';
import { ConfirmDeleteModal } from '../components/Commun/confirmDeleteModal';
import { useFloorPlanMapData } from '../hooks/useFloorPlanMapData';


export default function Editor() {
    const { loadingPr, loadingText } = useFloorPlanMapData()

    return (
        <Fragment>
            <StepByStepHelp />
            <ConfirmDeleteModal />
            <div className='tools-panel'>
                <ToolsPanel />
            </div>
            <Content />
            <InspectorSelector />
            <LoadingPage loadingPr={loadingPr} text={loadingText} displayNone={loadingPr === 100} />
        </Fragment>
    )
}