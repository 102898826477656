import { Button } from "../../../styles/MUI/Button"
import { BasePropertyInput } from "./BasePropertyInput"

export function ButtonPropertyInput(
    {
        children,
        color,
        variant,
        onClick,
        disabled,
    }: {
        children: React.ReactNode,
        color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined,
        variant?: "text" | "outlined" | "contained" | undefined,
        onClick: () => void,
        disabled?: boolean,
    }) {

    return (
        <BasePropertyInput disabled={disabled}>
            <Button
                fullWidth
                onClick={() => onClick()}
                color={color}
                variant={variant}
                disabled={disabled}
            >
                {children}
            </Button>
        </BasePropertyInput >
    )
}