import { LightElement } from "../components/orientableFloorPlanElements/lightElement";
import { PlaceOrientableComponentTool } from "./placeOrientableComponentTool";

export class PlaceLightTool extends PlaceOrientableComponentTool<LightElement> {
    constructor() {
        super("Lights", "Light")
    }

    set light(light: LightElement) {
        this.floorPlanElement = light
    }
}