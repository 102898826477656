import { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setPropertyAngle, setPropertyColor, setPropertyColorBack, setPropertyHasColorBack, setPropertyName, /* setPropertyRegion, */ setPropertyThickness, setPropertyWidth, setPropertyX, setPropertyY } from "../../../store/reducers/FloorPlan/FloorPlan";
import { Wall } from "../../../types/FloorPlan/Wall.types";
import { NumberPropertyInput } from "../Properties/NumberPropertyInput";
import { ColorPropertyInput } from "../Properties/ColorPropertyInput";
import { PropertyInput } from "../Properties/PropertyInput";
import { confirmDeleteModalOpen, /* toggleWallsCorners */ } from "../../../store/reducers/UI";
import { BooleanPropertyInput } from "../Properties/BooleanPropertyInput";
// import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ButtonPropertyInput } from "../Properties/ButtonPropertyInput";
import { InspectorBody } from "../Layout/inspectorBody";
import { InspectorFooter } from "../Layout/inspectorFooter";
import { DefaultInspectorOptions } from "../Components/defaultInspectorOptions";
// import { InspectorHeaderNumberProperty } from "../Properties/InspectorHeaderNumberProperty";
// import CategoryIcon from '@mui/icons-material/Category';
// import { MAX_GROUND_REGIONS } from "../../../types/FloorPlan/FloorPlan.types";

export function WallsSelectedInspector({ canDelete = true }: { canDelete?: boolean }) {

    const allWalls = useAppSelector(state => state.floorPlan.floorPlan?.walls)
    const selectedData = useAppSelector(state => state.editor.selectedData)

    // const wallsCornersExpanded = useAppSelector(state => state.ui.inspector.wallsCornersExpanded)

    const [walls, setWalls] = useState<Wall[]>([])

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (allWalls) {
            const newWalls: Wall[] = []
            selectedData.filter((e) => e.type === "Wall").forEach(element => {
                const wall = allWalls[element.id]
                if (wall)
                    newWalls.push(wall)
                else
                    console.warn(element.id + " not found as a wall")
            })
            setWalls(newWalls)
        }
    }, [allWalls, selectedData])

    const allHaveColorBack = walls.reduce<boolean>((pr, cr) => { return cr.hasColorBack && pr }, walls.length > 0)

    return (
        <Fragment>{/* 
            <InspectorHeaderNumberProperty
                propertyName="region"
                objects={walls}
                startAdornment={<CategoryIcon fontSize="small" sx={{ paddingRight: 1 }}/>}
                onValueChange={(region: number) => {
                    selectedData.forEach(data => {
                        dispatch(setPropertyRegion({
                            type: "Wall",
                            id: data.id,
                            region
                        }))
                    })
                }}
                min={0}
                max={MAX_GROUND_REGIONS}
            /> */}
            <InspectorBody>
                <PropertyInput
                    propertyName="name"
                    label="Name"
                    objects={walls}
                    onValueChange={(name: string) => {
                        selectedData.forEach(data => {
                            dispatch(setPropertyName({
                                type: "Wall",
                                id: data.id,
                                name
                            }))
                        })
                    }}
                />{/* 
                <NumberPropertyInput
                    propertyName={"x"}
                    label="X"
                    objects={walls.map(w => w)}
                    onValueChange={(x) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyX({
                                type: "Wall",
                                id: data.id,
                                x
                            }))
                        })
                    }
                />
                <NumberPropertyInput
                    propertyName={"y"}
                    label="Y"
                    objects={walls.map(w => w)}
                    onValueChange={(y) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyY({
                                type: "Wall",
                                id: data.id,
                                y
                            }))
                        })
                    }
                />
                <Accordion
                    elevation={0}
                    expanded={wallsCornersExpanded}
                    onChange={() => dispatch(toggleWallsCorners())}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <span>Corners Position</span>
                    </AccordionSummary>
                    <AccordionDetails> */}
                <NumberPropertyInput
                    propertyName={"x"}
                    label="X1"
                    objects={walls.map(w => w.origin)}
                    onValueChange={(x) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyX({
                                type: "WallOrigin",
                                id: data.id,
                                x
                            }))
                        })
                    }
                />
                <NumberPropertyInput
                    propertyName={"y"}
                    label="Y1"
                    objects={walls.map(w => w.origin)}
                    onValueChange={(y) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyY({
                                type: "WallOrigin",
                                id: data.id,
                                y
                            }))
                        })
                    }
                />
                <NumberPropertyInput
                    propertyName={"x"}
                    label="X2"
                    objects={walls.map(w => w.destiny)}
                    onValueChange={(x) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyX({
                                type: "WallDestiny",
                                id: data.id,
                                x
                            }))
                        })
                    }
                />
                <NumberPropertyInput
                    propertyName={"y"}
                    label="Y2"
                    objects={walls.map(w => w.destiny)}
                    onValueChange={(y) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyY({
                                type: "WallDestiny",
                                id: data.id,
                                y
                            }))
                        })
                    }
                />{/* 
                    </AccordionDetails>
                </Accordion> */}
                <NumberPropertyInput
                    propertyName={"angle"}
                    label="Angle"
                    objects={walls}
                    onValueChange={(angle) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyAngle({
                                type: "Wall",
                                id: data.id,
                                angle: angle % 360
                            }))
                        })
                    }
                    floatDecimals={0}
                    endAdornment="º"
                />
                <NumberPropertyInput
                    propertyName={"width"}
                    label="Lenght"
                    objects={walls}
                    onValueChange={(length) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyWidth({
                                type: "Wall",
                                id: data.id,
                                width: length
                            }))
                        })
                    }
                    endAdornment="cm"
                />

                <NumberPropertyInput
                    propertyName={"thickness"}
                    label="Thickness"
                    objects={walls}
                    onValueChange={(thickness) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyThickness({
                                type: "Wall",
                                id: data.id,
                                thickness
                            }))
                        })
                    }
                    endAdornment="cm"
                />

                <ColorPropertyInput
                    propertyName="color"
                    objects={walls}
                    onValueChange={(color: string) => {
                        selectedData.forEach(data => {
                            dispatch(setPropertyColor({
                                type: "Wall",
                                color,
                                id: data.id
                            }))
                        })
                    }}
                />
                <BooleanPropertyInput
                    propertyName="hasColorBack"
                    label="Has Color Back"
                    objects={walls}
                    onValueChange={(hasColorBack: boolean) => {
                        selectedData.forEach(data => {
                            dispatch(setPropertyHasColorBack({
                                type: "Wall",
                                hasColorBack,
                                id: data.id
                            }))
                        })
                    }}
                />
                {allHaveColorBack && walls.length > 0 &&
                    <ColorPropertyInput
                        label="Color Back"
                        propertyName="colorBack"
                        objects={walls}
                        onValueChange={(color: string) => {
                            selectedData.forEach(data => {
                                dispatch(setPropertyColorBack({
                                    type: "Wall",
                                    color,
                                    id: data.id
                                }))
                            })
                        }}
                    />}

            </InspectorBody>
            <InspectorFooter>
                <ButtonPropertyInput
                    onClick={() => {
                        dispatch(confirmDeleteModalOpen())
                    }}
                    color={"error"}
                    variant={"contained"}
                    disabled={!canDelete}
                >
                    <span>Delete</span>
                </ButtonPropertyInput>

                <DefaultInspectorOptions />
            </InspectorFooter>
        </Fragment>
    )
} 