import { Fragment } from "react"
import { InspectorHeader } from "./Layout/inspectorHeader"
import { EntranceSelectedInspector } from "./SelectedInspector/entranceSelectedInspector"


export function EntranceToolInspector() {

    return (
        <Fragment>
            <InspectorHeader>Entrance</InspectorHeader>
            <EntranceSelectedInspector />
        </Fragment >
    )
}