import * as PIXI from 'pixi.js'
import { OrientableFloorPlanElement } from "./orientableFloorPlanElement";
import Icon from "../../../../../assets/img/icon-lights.svg";
import IconWarning from "../../../../../assets/img/icon-lights-warn.svg";
import { Light } from '../../../../../types/FloorPlan/Light.types';
import { ComponentOrientations, orientationToRad } from '../../../../../types/FloorPlan/OrientableComponent.types';
import { MouseText } from '../mouseText';

const lightPreviewColor = 0xcfcfcf
const lightIntensityMultiplier = 700

class LightPreview extends PIXI.Graphics {
    light: LightElement
    blurFilter = new PIXI.filters.BlurFilter()
    maskGraphic: PIXI.Graphics

    constructor(light: LightElement) {
        super()
        this.light = light

        this.alpha = 0.75
        this.filters = [this.blurFilter]

        this.beginFill(lightPreviewColor, .3)
            .drawCircle(0, 0, lightIntensityMultiplier)
            .beginFill(lightPreviewColor, .3)
            .drawCircle(0, 0, lightIntensityMultiplier * .8)
            .beginFill(lightPreviewColor, .4)
            .drawCircle(0, 0, lightIntensityMultiplier * .6)
            .beginFill(lightPreviewColor, .4)
            .drawCircle(0, 0, lightIntensityMultiplier * .4)
            .endFill()

        this.maskGraphic = new PIXI.Graphics()
        this.addChild(this.maskGraphic)
        this.mask = this.maskGraphic
        this.blurFilter.blur = 0

        this.setBlur(light._blurryHalo)
        this.setColor(light._lightColor)
        this.reDraw()
    }

    setBlur(blur: number) {
        this.blurFilter.blur = 15 * blur / 100
    }

    setColor(color: string) {
        // Remove the '0x' prefix and convert the color to decimal
        const decimalColor = parseInt(color.slice(1), 16);

        // // Extract the RGB components
        const red = (decimalColor >> 16) & 255;
        const green = (decimalColor >> 8) & 255;
        const blue = decimalColor & 255;

        // reduce the ammount of color 
        // 0 => 200
        // 255 => 255

        const newRed = Math.min(255, ((red * 55) / 255) + 200)
        const newGreen = Math.min(255, ((green * 55) / 255) + 200)
        const newBlue = Math.min(255, ((blue * 55) / 255) + 200)
        // console.log("decimalColor", red, green, blue, "-", newRed, newGreen, newBlue)

        // Convert RGB back to hexadecimal
        const newColor = ((newRed << 16) + (newGreen << 8) + newBlue).toString(16).padStart(6, '0');

        this.tint = PIXI.utils.string2hex(newColor)
    }

    reDraw() {


        const radOrientation = orientationToRad(this.light.orientation) - Math.PI / 2
        const radius = (this.light._openingAngle / 360) * Math.PI

        const lightIntensity = this.light._lightIntensity


        this.maskGraphic.clear()
            .beginFill(0xffffff)
            .moveTo(0, 0)
            .arc(0, 0, lightIntensityMultiplier, radOrientation - radius, radOrientation + radius)

        this.width = lightIntensity * lightIntensityMultiplier * 2
        this.height = lightIntensity * lightIntensityMultiplier * 2

    }
}

export class LightElement extends OrientableFloorPlanElement {
    _showWarning: boolean
    warningTexture: PIXI.Texture | undefined
    lightTexture: PIXI.Texture | undefined
    _lightIntensity: number
    _openingAngle: number
    _blurryHalo: number
    _lightColor: string
    _warningText: string
    lightPreview: LightPreview | undefined

    constructor(
        light: Light
    ) {
        super("Light", light.x, light.y, light.mapRefUUID, light.orientation, Icon)

        this._lightIntensity = light.intensity
        this._openingAngle = light.openingAngle
        this._blurryHalo = light.blurryHaloPr
        this._lightColor = light.color
        this._showWarning = false

        // console.log("light warning:", light.warning)
        this._warningText = light.warningText

        this.warningTexture = this.app.resourcesLoader.getTexture(IconWarning, { resourceOptions: { height: 100, width: 100 } })
        // this.warningTexture = this.app.resourcesLoader.getTexture(Icon, { resourceOptions: { height: 100, width: 100 } })
        this.lightTexture = this.sprite instanceof PIXI.Sprite ? this.sprite?.texture : undefined
        
        this.showWarning = light.warning
    }

    handlePointerOver() {
        super.handlePointerOver()
        if (this.showWarning) {
            MouseText.showTextWarning(this._warningText, 1000)
        }
    }
    handlePointerOut() {
        super.handlePointerOut()
        MouseText.active = false
    }

    set showWarning(warning: boolean) {
        if (this._showWarning === warning)
            return

        this._showWarning = warning

        if (warning) {

            if (!(this.sprite instanceof PIXI.Sprite) || !this.warningTexture)
                return

            this.sprite.texture = this.warningTexture
        }
        else {
            if (!(this.sprite instanceof PIXI.Sprite) || !this.lightTexture)
                return

            this.sprite.texture = this.lightTexture
        }
    }
    get showWarning() { return this._showWarning }

    select(showArc?: boolean): void {
        super.select(showArc)
        this.addChildAt(
            this.lightPreview = new LightPreview(this),
            0
        )
    }
    unselect(): void {
        super.unselect()
        this.lightPreview?.destroy()
    }

    set lightIntensity(li: number) {
        if (li !== this._lightIntensity) {
            this._lightIntensity = li
            this.lightPreview?.reDraw()
        }
    }
    set openingAngle(oa: number) {
        if (oa !== this._openingAngle) {
            this._openingAngle = oa
            this.lightPreview?.reDraw()
        }
    }
    set blurryHalo(bh: number) {
        if (bh !== this._blurryHalo) {
            this._blurryHalo = bh
            if (!this.lightPreview) return

            this.lightPreview.setBlur(bh)
        }
    }
    set lightColor(lc: string) {

        if (lc !== this._lightColor) {
            this._lightColor = lc
            if (!this.lightPreview) return

            this.lightPreview.setColor(lc)
        }
    }


    setOrientation(newOrientation: ComponentOrientations): void {
        this.lightPreview?.reDraw()
        super.setOrientation(newOrientation)
    }

    checkIfLightIssues() {
        // console.log("walls", this.app.floorPlanCanvas.mainLayer.walls)

    }
}