import { useEffect, useState } from "react";
import { Select, MenuItem } from "@mui/material";
import { BasePropertyInput } from "./BasePropertyInput";
import { PropertyInputLabel } from "./PropertyInputLabel";
import clsx from "clsx";


export function SelectPropertyInput<T extends string, K extends string>(
    {
        propertyName,
        label,
        objects,
        onValueChange,
        keyValue,
        disabled,
        error,
        info,
        large
    }: {
        propertyName: K,
        label: string,
        objects: { [key in typeof propertyName]: T }[],
        onValueChange: (a: T) => void,
        keyValue: { [key in T]: string }
        disabled?: boolean
        error?: boolean
        info?: React.ReactNode,
        large?: boolean
    }) {

    const [val, setVal] = useState<T | "-">("-")

    useEffect(() => {
        const newVal = objects.reduce<T | "-">((pr, cr) => pr === cr[propertyName] ? pr : "-", objects[0] ? objects[0][propertyName] : "-")
        setVal(newVal)
    }, [objects, propertyName])

    return (
        <BasePropertyInput className={clsx(label + "-Selector", { "large-input-box": large || false })} disabled={disabled} error={error}>
            <PropertyInputLabel content={info}>{label}</PropertyInputLabel>
            <Select
                variant="standard"
                disableUnderline

                id={propertyName + "-select"}
                value={val}
                // placeholder={"-"}
                // error={formErrors.width}
                onChange={(e: any) => {
                    const value = e.target.value
                    onValueChange(value)
                    setVal(value)
                }}

                style={{ overflow: "hidden" }}
                disabled={disabled}
            >
                {
                    Object.entries<string>(keyValue)
                        .map(([id, value], i) => (
                            <MenuItem key={i} value={id}>{value}</MenuItem>
                        ))
                }

                <MenuItem style={{ display: "none" }} value={'-'}>-</MenuItem>
            </Select>
        </BasePropertyInput>
    )
}