import { all, fork } from 'redux-saga/effects';
import { watchChangeSculptureFileFormat, watchCheckArtworkStatus, watchGetArtworks, watchUploadSculpture } from './Artworks.sagas';
import { watchAuthRequest, watchSetAuthToken } from './Auth.sagas';
import { watchCreateAndAddNewFloorPlanData, watchDuplicateSelection, watchSetSelectData, watchSetToolEffect } from './Editor.sagas';
import { watchGetExhibitionMap, watchOpen3dEditor, watchOpen3dPreview } from './Exhibition.sagas';
import { watchCleanUpUnplacedData, watchGetFloorPlan, watchSaveFloorPlan, watchStartSaveFloorPlanCycle, watchTryDeleteData } from './FloorPlan.sagas';


function* watchers() {
  yield all([
    // exhibition
    watchGetExhibitionMap(),
    watchGetArtworks(),
    watchOpen3dPreview(),
    watchOpen3dEditor(),

    // auth
    watchAuthRequest(),
    watchSetAuthToken(),

    // floorplan
    watchCleanUpUnplacedData(),
    watchGetFloorPlan(),
    watchStartSaveFloorPlanCycle(),
    watchSaveFloorPlan(),
    watchUploadSculpture(),
    watchTryDeleteData(),

    // artwork
    watchCheckArtworkStatus(),
    watchChangeSculptureFileFormat(),

    // editor
    watchSetToolEffect(),
    watchDuplicateSelection(),
    watchCreateAndAddNewFloorPlanData(),
    watchSetSelectData(),
  ]);
}

export default function* rootSaga() {
  yield all([fork(watchers)]);
  // yield put(getUserProfileRequest());
}
