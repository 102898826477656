import * as PIXI from 'pixi.js'
import { InteractionEvent } from "pixi.js";
import { SelectedData } from '../../../../types/Editor.types';
import { WallSide } from '../../../../types/FloorPlan/Wall.types';
import { ArtworkDndGraphic } from '../artworkDnd/artworkDndGraphic';
import { ArtworkElement } from '../components/artworkElement';
import { FloorPlanElement } from "../components/floorPlanElement";
import { VisiblePoint } from "../components/visiblePoint";
import { WallElement } from "../components/wallElement";
import FloorPlanConnector from '../floorPlanConnector';
import { Tool } from "./tool"

export class WallSideSelector extends PIXI.Container {
    right: PIXI.Graphics
    left: PIXI.Graphics
    wall?: WallElement
    selectWallDndTool: PlaceArtworksTool

    constructor(selectWallDndTool: PlaceArtworksTool) {
        super()
        this.selectWallDndTool = selectWallDndTool

        this.left = this.createSide("left")
        this.left.angle = 180

        this.right = this.createSide("right")



        this.addChild(this.left)
        this.addChild(this.right)
    }

    private createSide(side: WallSide) {
        const newSide = new PIXI.Graphics()

        newSide.beginFill(0xffffff, 0.01)
        newSide.drawCircle(0, 20, 15)
        newSide.endFill()

        newSide.lineStyle(3, 0x000000, 1);
        newSide.moveTo(-10, 25);
        newSide.lineTo(0, 15);
        newSide.lineTo(10, 25);

        const defaultAlpha = 0.5
        newSide.alpha = defaultAlpha

        newSide.interactive = true
        newSide.on('pointerover', () => { newSide.alpha = 1 })
        newSide.on('pointerout', () => { newSide.alpha = defaultAlpha })
        newSide.on('pointerdown', () => {
            if (this.wall) {
                this.selectWallDndTool.onSelectWallSide(this.wall, side)
            }
        })

        return newSide
    }
}

export class PlaceArtworksTool extends Tool {
    selectedElementType?: "none" | "wall" | "artwork"
    wallSideSelector?: WallSideSelector
    wallSelected: string | false

    constructor() {
        super()
        this.toolType = "PlaceArtworks"
        this.wallSelected = false
    }

    // interaction    
    onClick(e: InteractionEvent, element?: FloorPlanElement | VisiblePoint): void {
        super.onClick(e, element)
        if (this._draggingMode !== 'tool')
            return

        // when user clicks no element, unselect
        if (!element) {
            // console.log("unselect", this.app.selectedElements)
            this.selectedElementType = 'none'
            this.app.selectedElements.forEach(element => {
                this.unselectElement(element)
            })
            this.app.selectedElements = []
            FloorPlanConnector.setSelectedElements(this.app.selectedElements)
        }
        else if (element instanceof WallElement) {
            this.selectedElementType = 'wall'

            this.app.selectedElements.forEach(element => {
                this.unselectElement(element)
            })
            this.selectElement(element)
            FloorPlanConnector.setSelectedElements(this.app.selectedElements)
        }
        else if (element instanceof ArtworkDndGraphic) {
            this.app.selectedElements.forEach(element => {
                this.unselectElement(element)
            })
            this.selectElement(element)
            FloorPlanConnector.setSelectedElements(this.app.selectedElements)
        }
    }

    onDragMove(e: InteractionEvent) {
        if (this._draggingMode === 'tool' && this.selectedElementType === "none")
            this.changeDraggingMode('canvas')

        // console.log(this.selectedElementType, this.app.selectedElements)
        if (this.selectedElementType === 'artwork') {
            const interactionPoint = e.data.getLocalPosition(this.app.currentCanvas)

            this.app.selectedElements.forEach((element) => {
                // element.move(dx, dy)
                FloorPlanConnector.moveElementTo(
                    interactionPoint.x - element.width / 2, interactionPoint.y - element.height / 2,
                    element.mapRefUUID, element.type)
            })

        }

        super.onDragMove(e)
    }

    onDragEnd(e: InteractionEvent) {
        super.onDragEnd(e)
        this.selectedElementType = "none"
    }

    enable() {
        Object.values(this.app.floorPlanElements).forEach(element => {
            if (element instanceof WallElement) {
                element.setInteractive(true)
            }

            this.wallSideSelector = new WallSideSelector(this)
        });
    }

    dissable() {
        Object.values(this.app.floorPlanElements).forEach(element => {
            if (element) {
                element.setInteractive(false)
                if (element.selected)
                    this.unselectElement(element)

                this.wallSideSelector?.destroy()
            }
        });
        this.app.selectedElements = []

        this.app.hideArtworkDndCanvas()
    }

    onSelectWallSide(wall: WallElement, side: WallSide) {
        FloorPlanConnector.selectWallSide(wall, side)
        this.app.showArtworkDndWall(wall, side)
        this.wallSelected = wall.mapRefUUID
    }

    // Element Actions
    selectElement(element: FloorPlanElement) {
        if (element instanceof WallElement) {
            if (!this.wallSideSelector)
                return

            element.select(false)
            this.app.floorPlanCanvas.mainLayer.selectedElements.addChild(element)


            element.setTextVisible(false)

            element.wallContainer.addChild(this.wallSideSelector)
            this.wallSideSelector.wall = element

            this.app.selectedElements = [element]
            // FloorPlanApp.notify('set-selected-elements', { elements: [element.wall] })
        }
        else if (element instanceof ArtworkDndGraphic) {
            this.selectedElementType = 'artwork'
            this.app.selectedElements.forEach(element => {
                element.unselect()
            })

            this.app.selectedElements = [element]

            element.select()
        }
        if (element instanceof ArtworkElement) {
            const artworkGraphic = this.app.artworkDndCanvas.getArtworkGraphic(element.mapRefUUID)
            if (artworkGraphic)
                this.app.selectedElements = [artworkGraphic]
            this.selectedElementType = "artwork"
        }
    }

    unselectElement(element: FloorPlanElement) {
        if (element instanceof WallElement) {
            if (!this.wallSideSelector)
                return

            element.unselect()
            this.app.floorPlanCanvas.mainLayer.addElement(element)

            element.setTextVisible(true)

            element.wallContainer.removeChild(this.wallSideSelector)
        }
        else if (element instanceof ArtworkDndGraphic) {

        }
    }

    unselectWall() {
        if (this.wallSelected) {
            const wallElement = this.app.floorPlanElements[this.wallSelected] as WallElement
            if (wallElement)
                wallElement.reDraw()

            this.app.hideArtworkDndCanvas()
            this.wallSelected = false
        }
    }

    onSelectChange(selectedData: SelectedData[]): void {
        if (this.wallSelected && selectedData.length === 0) {
            this.app.artworkDndCanvas.hideGuidelines()
        }
    }
}