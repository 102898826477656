export function doLinesIntersect(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number): boolean {
    // Define the coordinates of the second line

    const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
    if (denominator === 0) {
        // Lines are parallel
        return false;
    }

    const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
    const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

    return ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1;
}

export function CircleLineCollision(circleX: number, circleY: number, radius: number, lineX1: number, lineY1: number, lineX2: number, lineY2: number): boolean {
    // Calculate the closest point on the line segment to the circle center
    const vecLineX = lineX2 - lineX1;
    const vecLineY = lineY2 - lineY1;
    const vecPointX = circleX - lineX1;
    const vecPointY = circleY - lineY1;
    const dotProduct = vecLineX * vecPointX + vecLineY * vecPointY;
    const lengthSq = vecLineX * vecLineX + vecLineY * vecLineY;
    const t = dotProduct / lengthSq;

    let closestX, closestY;

    if (t < 0) {
        closestX = lineX1;
        closestY = lineY1;
    } else if (t > 1) {
        closestX = lineX2;
        closestY = lineY2;
    } else {
        closestX = lineX1 + t * vecLineX;
        closestY = lineY1 + t * vecLineY;
    }

    // Calculate the distance between the closest point and the circle center
    const distance = Math.sqrt((closestX - circleX) ** 2 + (closestY - circleY) ** 2);

    // Check collision
    return distance <= radius;
}
