import { FloorPlanArtwork } from "./Artwork.types";
import { Entrance } from "./Entrance.types";
import { Light } from "./Light.types";
import { Sculpture } from "./Sculpture.types";
import { Spot } from "./Spot.types";
import { Wall } from "./Wall.types";

export type FloorPlanEditorSetting =
  'lightsVisible'
  | 'spotsVisible'
  | 'artworksVisible'
  | 'dimensionsVisible'
  | 'showRuler'
  | 'snapToGrid'
  | 'lockDegrees'
  | 'floorPreviewVisible'
  | 'inspectorExpanded'
  | 'wallName'

export type CanvasEvent =
  | 'set-selected-elements'
  // | 'move-element'
  | 'move-element-to'
  | 'update-property-orientation'
  | 'select-wall-side'
  | 'element-placed'
  | 'set-artwork-position'
  | 'set-tool'
  | 'set-eye-line-height'

export const EYE_LINE_HEIGHT_CM_MAX = 170
export const EYE_LINE_HEIGHT_CM_MIN = 150

export type FloorPlanSettings = {
  hasCeil: boolean;
  wallsHaveDefaultTexture: boolean,
  wallsDefaultColor: string;
  wallsDefaultTextureURL: string;
  floorColor: string;
  ceilColor: string;
  tileSize: number;
  wallHeight: number;
  hasFloor: boolean;
  floorHasTexture: boolean;
  floorTextureId: string;
  skyColor: string;
  groundColor: string;
  ambientLightColor: string;

  wallsBaseboard: boolean

  eyeLineHeight: number;
  // wallIncrement: number;
  lockDegrees: boolean;
}

export type SaveState = "UP_TO_DATE" | "CHANGES_TO_SAVE" | "SAVING" | "SAVE_ERROR"

export type FloorPlanState = {
  floorPlan: FloorPlanMap | null;
  floorPlanSettings: FloorPlanSettings | null;
  saveState: SaveState
  autoSave: boolean,
};

type FloorPlanWallDataType = "WallOrigin" | "WallDestiny"
export type FloorPlanDataType = "Entrance" | "Wall" | "Light" | "Spot" | "Sculpture" | "Artwork" | "Other" | FloorPlanWallDataType

export const MAX_GROUND_REGIONS = 10


export type FloorPlanMap = {
  id: string,
  entrance: Entrance
  walls: { [id: string]: Wall },
  artworks: { [id: string]: FloorPlanArtwork },
  lights: { [id: string]: Light },
  spots: { [id: string]: Spot },
  sculptures: { [id: string]: Sculpture },
}