import { useAppSelector } from "../../../hooks";
import { selectSculputreArtworks } from "../../../store/reducers/Artworks";
import { SculptureFileFormat } from "../../../types/FloorPlan/Sculpture.types";
import { FileButtonInput } from "./fileButtonInput";

const sculptureTypeToHeader: { [key in SculptureFileFormat]: string } = {
  BoxImage: "File (Image)",
  FlatImage: "File (Image)",
  CrossImage: "File (Image)",
  Sculpture: "File (3D)",
}

export function SculptureButtonInput() {
  const allSculptures = useAppSelector(selectSculputreArtworks)
  const selectedData = useAppSelector(state => state.editor.selectedData)

  return (
    <FileButtonInput
      header={sculptureTypeToHeader[Object.values(allSculptures).filter((s) => selectedData.map(sd => sd.id).includes(s.uuid))[0]?.sculptureFormat || "Sculpture"]}
      allArtworks={allSculptures}
      fileTypesAccept=".fbx,.png,.jpeg"
    />
  )
}