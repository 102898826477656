import { CaseReducer, PayloadAction } from "@reduxjs/toolkit"
import cookie from "js-cookie"
import { FloorPlanState } from "../../../types/FloorPlan/FloorPlan.types"

export const caseSetFloorPlanSetting: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        wallHeight?: number,
        ceilColor?: string,
        hasCeil?: boolean,
        floorColor?: string,
        floorTextureId?: string,
        hasFloor?: boolean,
        floorHasTexture?: boolean
        eyeLineHeight?: number,
        lockDegrees?: boolean,
        ambientLightColor?: string,
        wallsBaseboard?: boolean
    }>
> = (state, { payload }) => {
    state.saveState = "CHANGES_TO_SAVE"
    const {
        wallHeight,
        ceilColor,
        hasCeil,
        floorColor,
        floorTextureId,
        hasFloor,
        floorHasTexture,
        eyeLineHeight,
        lockDegrees,
        ambientLightColor,
        wallsBaseboard } = payload

    if (!state.floorPlanSettings)
        return

    if (wallHeight !== undefined)
        state.floorPlanSettings = { ...state.floorPlanSettings, wallHeight: Math.max(Math.min(wallHeight, 500), 0) }

    if (ceilColor !== undefined)
        state.floorPlanSettings = { ...state.floorPlanSettings, ceilColor }

    if (hasCeil !== undefined)
        state.floorPlanSettings = { ...state.floorPlanSettings, hasCeil }

    if (floorColor !== undefined)
        state.floorPlanSettings = { ...state.floorPlanSettings, floorColor }

    if (floorTextureId !== undefined)
        state.floorPlanSettings = { ...state.floorPlanSettings, floorTextureId }

    if (hasFloor !== undefined)
        state.floorPlanSettings = { ...state.floorPlanSettings, hasFloor }

    if (floorHasTexture !== undefined)
        state.floorPlanSettings = { ...state.floorPlanSettings, floorHasTexture }

    if (ambientLightColor !== undefined)
        state.floorPlanSettings = { ...state.floorPlanSettings, ambientLightColor }

    if (wallsBaseboard !== undefined)
        state.floorPlanSettings = { ...state.floorPlanSettings, wallsBaseboard }

    if (eyeLineHeight !== undefined) {
        // console.log("CHANGE EYE LINE TO", eyeLineHeight)
        state.floorPlanSettings = { ...state.floorPlanSettings, eyeLineHeight }
        cookie.set("eyeLineHeight", eyeLineHeight.toString())
    }

    if (lockDegrees !== undefined) {
        // console.log("CHANGE EYE LINE TO", eyeLineHeight)
        state.floorPlanSettings = { ...state.floorPlanSettings, lockDegrees }
        cookie.set("lockDegrees", lockDegrees.toString())
    }
}

