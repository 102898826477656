import { faker } from '@faker-js/faker';

export class FloorPlanData {
    readonly mapRefUUID: string
    placed: boolean // true if the element is on the map, flase if it hasn't been placed yet
    deleted: boolean

    x: number
    y: number

    constructor(mapRefUUID?: string) {
        this.mapRefUUID = mapRefUUID || faker.datatype.uuid()
        this.placed = true
        this.deleted = false

        this.x = 0
        this.y = 0
    }
}