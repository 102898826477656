import { Input } from "@mui/material";
import { useEffect, useState } from "react";
import { BasePropertyInput } from "./BasePropertyInput";
import { PropertyInputLabel } from "./PropertyInputLabel";

export function PropertyInput<T, K extends string>(
    {
        propertyName,
        label,
        objects,
        onValueChange,
        disabled,
        error,
        info,
    }: {
        propertyName: K,
        label: string,
        objects: { [key in typeof propertyName]: T }[],
        onValueChange?: (a: T) => void
        disabled?: boolean
        error?: boolean
        info?: React.ReactNode
    }) {

    const [val, setVal] = useState<T | "">("")
    useEffect(() => {
        setVal(objects.reduce<T | "">((pr, cr) => pr === cr[propertyName] ? pr : "", objects[0] ? objects[0][propertyName] : ""))
    }, [objects, propertyName])

    return (
        <BasePropertyInput disabled={disabled} error={error}>
            <PropertyInputLabel content={info}>{label}</PropertyInputLabel>
            <Input
                id={propertyName + "-input"}
                type="text"
                value={val}
                // error={formErrors.width}
                onChange={(event: any) => {
                    const { value } = event.target;
                    onValueChange && onValueChange(value)
                    setVal(value)
                }}
                disableUnderline
                placeholder={"-"}
                disabled={disabled}
            />
        </BasePropertyInput>
    )
}