import { Input, InputAdornment } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { BasePropertyInput } from "./BasePropertyInput";
import { PropertyInputLabel } from "./PropertyInputLabel";

const DEFAULT_FLOAT_DECIMALS = 2
const DEFAULT_STEP = 5

export function NumberPropertyInput<K extends string>(
    {
        propertyName,
        label,
        objects,
        onValueChange,
        transformOperation,
        inverseTransformOperation,
        floatDecimals,
        max,
        min,
        step,
        endAdornment,
        startAdornment,
        disabled,
        info,
        className
    }: {
        propertyName: K,
        label: string,
        objects: { [key in typeof propertyName]: number }[],
        onValueChange: (a: number) => void,
        transformOperation?: (a: number) => number,
        inverseTransformOperation?: (a: number) => number,
        floatDecimals?: number,
        max?: number,
        min?: number,
        step?: number,
        endAdornment?: string,
        startAdornment?: string,
        disabled?: boolean
        className?: string
        error?: boolean
        info?: React.ReactNode
    }) {

    const [val, setVal] = useState<number | "">("")

    const fix = useCallback((num: number | "") => {
        return num === "" ? "" : Number(Number(num).toFixed(floatDecimals !== undefined ? floatDecimals : DEFAULT_FLOAT_DECIMALS))
    }, [floatDecimals])

    const [allowFixing, setAllowFixing] = useState(true)

    const [error, setError] = useState(false)

    useEffect(() => {
        if (error)
            return

        let num = objects.reduce<number | "">((pr, cr) => pr === cr[propertyName] ? pr : "", objects[0] ? objects[0][propertyName] : "")
        if (transformOperation && num !== "")
            num = transformOperation(num)

        setVal(allowFixing ? fix(num) : num)
    }, [objects, propertyName, allowFixing, error, fix, transformOperation])


    const inputFieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const handleWheel = (e: any) => e.currentTarget.blur();
        const current = inputFieldRef.current
        if (current) {
            current.addEventListener("wheel", handleWheel);

            return () => {
                current.removeEventListener("wheel", handleWheel);
            };
        }
    }, []);

    const isValid = (value: number) => {
        const minValue = min !== undefined ? min : Number.MIN_SAFE_INTEGER
        const maxValue = max !== undefined ? max : Number.MAX_SAFE_INTEGER

        // console.log("is valid", value, minValue, maxValue)

        return value <= maxValue && value >= minValue
    }

    return (
        <BasePropertyInput error={error} className={className} disabled={disabled}>
            <PropertyInputLabel content={info}>{label}</PropertyInputLabel>
            <Input
                id={propertyName + "-input"}
                type="number"
                ref={inputFieldRef}
                disabled={disabled}
                value={
                    allowFixing ?
                        (val === "") ?
                            "" : val.toFixed(floatDecimals !== undefined ? floatDecimals : DEFAULT_FLOAT_DECIMALS)
                        : val
                }
                onInput={() => {
                    setAllowFixing(false)
                }}
                onBlur={() => {
                    setAllowFixing(true)
                    setVal((num) => fix(num))
                }}
                placeholder={"-"}
                error={error}
                onChange={(e: any) => {
                    let value = Number(e.target.value)

                    setVal(value)

                    if (isValid(value)) {
                        if (inverseTransformOperation)
                            value = inverseTransformOperation(value)

                        onValueChange(value)
                        setError(false)
                    }
                    else
                        setError(true)
                }}
                inputProps={{
                    type: 'number',
                    step: step || DEFAULT_STEP,
                    min,
                    max,
                }}

                disableUnderline
                endAdornment={endAdornment && <InputAdornment position='end'>{endAdornment}</InputAdornment>}
                startAdornment={startAdornment && <InputAdornment position='end'>{startAdornment}</InputAdornment>}
            />
        </BasePropertyInput>
    )
}