//import { TypePoint } from '../../../types/FloorPlan.types';
import * as PIXI from 'pixi.js'
import { InteractionEvent } from 'pixi.js'

export class VisiblePoint extends PIXI.Container {
  pointGraphic: PIXI.Graphics
  hitGraphic: PIXI.Graphics

  constructor(x: number, y: number, color: number = 0xc0c0c0, radius: number = 3) {
    super()

    this.pointGraphic = new PIXI.Graphics()
    this.pointGraphic.beginFill(color)
    this.pointGraphic.drawCircle(0, 0, radius)
    this.pointGraphic.endFill()

    this.hitGraphic = new PIXI.Graphics()
    this.hitGraphic.beginFill(0x000000, 0.001)
    this.hitGraphic.drawCircle(0, 0, radius * 10)
    this.hitGraphic.endFill()

    this.on('pointerover', (event) => {
      this.pointGraphic.tint = 0xffffff
      this.pointGraphic.scale.set(1.25)
    })
    this.on('pointerout', (event) => {
      this.pointGraphic.tint = 0xffffff
      this.pointGraphic.scale.set(1)
    })
    this.on('pointerdown', (e) => this.onClick(e))

    this.x = x
    this.y = y

    this.cursor = 'draw'
  }

  onClick(e: InteractionEvent) {

  }

  move(dx: number, dy: number) {
    this.x += dx
    this.y += dy
  }

  moveTo(x: number, y: number) {
    this.x = x
    this.y = y
  }

  setInteractive(interactive: boolean) {
    if (interactive) {
      this.interactive = true
      this.addChild(this.hitGraphic)
    } else {
      this.interactive = true
      this.removeChild(this.hitGraphic)
    }
  }

  show(interactive: boolean = false) {
    this.addChild(this.pointGraphic)
    if (interactive)
      this.setInteractive(true)

    return this
  }

  hide() {
    this.removeChild(this.pointGraphic)
    this.removeChild(this.hitGraphic)
    return this
  }

  export() {
    return {
      x: this.x,
      y: this.y
    }
  }
}
