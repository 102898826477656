import { SelectedData } from "../../../types/Editor.types";
import { ToolType } from "../../../types/Editor/Tool.types";
import { CanvasEvent, FloorPlanDataType } from "../../../types/FloorPlan/FloorPlan.types";
import { ComponentOrientations } from "../../../types/FloorPlan/OrientableComponent.types";
import { WallSide } from "../../../types/FloorPlan/Wall.types";
import { FloorPlanElement } from "./components/floorPlanElement";
import { WallElement } from "./components/wallElement";

export default class FloorPlanConnector {
    static { }
    private static dispatch(eventName: CanvasEvent, params: any) {
        document.dispatchEvent(
            new CustomEvent(eventName, { detail: params })
        )
    }
    
    static setSelectedElements(elements: FloorPlanElement[]) {
        const selectedData: SelectedData[] = elements.map((element) => {
            return { id: element.mapRefUUID, type: element.type }
        })

        this.dispatch("set-selected-elements", selectedData)
    }
    /* 
        static moveElement(dx: number, dy: number, id: string, type: FloorPlanDataType) {
            this.dispatch("move-element", { dx, dy, id, type })
        } */
    static moveElementTo(x: number, y: number, id: string, type: FloorPlanDataType) {
        this.dispatch("move-element-to", { x, y, id, type })
    }

    static setElementOrientation(orientation: ComponentOrientations, id: string, type: FloorPlanDataType) {
        // console.log("setElementOrientation", orientation, id, type)
        this.dispatch("update-property-orientation", { orientation, id, type })
    }

    static selectWallSide(wall: WallElement, side: WallSide) {
        // console.log("select wall side", wall.mapRefUUID, side)
        this.dispatch("select-wall-side", { wallId: wall.mapRefUUID, side })
    }

    static elementPlaced(type: FloorPlanDataType, id: string) {
        // console.log("element placed", type, id)
        this.dispatch("element-placed", { type, id })
    }

    static setTool(tool: ToolType) {
        this.dispatch("set-tool", { tool })
    }

    static setEyeLineHeight(eyeLineHeight: number) {
        this.dispatch("set-eye-line-height", { eyeLineHeight })
    }
}