import * as PIXI from 'pixi.js'
import { FloorPlanApp } from '../floorPlanApp'
import { DashedLineGraphics } from '../utils/dashedLineGraphics'
import { WallElement } from '../components/wallElement';
import { MAX_GROUND_REGIONS } from '../../../../types/FloorPlan/FloorPlan.types';

// points defined by = [{x, y}, {x, y}, ... ]
function GetConvexHull(points: { x: number, y: number, region: number, angleStart?: number }[]) {
    // there is no inside points
    if (points.length <= 3) return points

    // Start point: lowest Y coordinate
    var start = points[0];
    for (var i = 0; i < points.length; i++) {
        if (
            points[i].y < start.y || // lowest Y coordinate
            (points[i].y === start.y && points[i].x < start.x) // if same Y, lowest x
        )
            start = points[i];
    }
    /* console.log(start) */

    // Sort them by lowest angle
    for (i = 0; i < points.length; i++) {
        points[i].angleStart = Math.atan2(points[i].y - start.y, points[i].x - start.x)
    }
    points.sort((a, b) => {
        // sort them by the angle with the start point, or by lowest x
        return a.angleStart === b.angleStart
            ? a.x - b.x
            : a.angleStart! - b.angleStart!
    })

    // Adding points to the result if they "turn left"
    var result = [points[0]],
        len = 1

    for (i = 1; i < points.length; i++) {
        var a = result[len - 2],
            b = result[len - 1],
            point = points[i]

        while (
            (len === 1 && b.x === point.x && b.y === point.y) || // same point case
            (len > 1 && (b.x - a.x) * (point.y - a.y) <= (b.y - a.y) * (point.x - a.x)) // if it turns right
        ) {
            len--;
            b = a;
            a = result[len - 2]
        }

        result[len] = point
        len++
    }

    // to close the polygon
    result.length = len

    return result
}

class GroundRegion extends PIXI.Graphics {
    color: number
    app = FloorPlanApp.getInstance()
    region: number

    constructor(color: number, region: number) {
        super()
        this.color = color
        this.region = region
    }
    updateRegion() {

        const points: { x: number, y: number, region: number }[] = []
        const fpe = Object.values(this.app.floorPlanElements)
        const walls = fpe.filter(e => e.type === "Wall") as WallElement[]
        const regionWalls = walls.filter(w => w.region === this.region)

        for (let i = 0; i < regionWalls.length; i++) {
            const w = regionWalls[i]
            points.push({ x: w.start.x, y: w.start.y, region: w.region })
            points.push({ x: w.end.x, y: w.end.y, region: w.region })
        }

        this.drawRegion(GetConvexHull(points))
    }

    drawRegion(points: { x: number, y: number }[]) {
        if (points.length <= 3)
            this.clear()

        //console.log(corners)
        const path: number[] = []

        points.forEach(corner => {
            path.push(corner.x)
            path.push(corner.y)
        });

        this.clear()
            .beginFill(this.color)
            .drawPolygon(path)
            .endFill()
    }
}

export class BgLayer extends PIXI.Container {
    points: PIXI.Graphics
    bg: DashedLineGraphics
    app: FloorPlanApp
    groundRegions: PIXI.Container<GroundRegion>

    constructor() {
        super()
        this.app = FloorPlanApp.getInstance()

        this.bg = new DashedLineGraphics()
        this.updateM2()
        this.addChild(this.bg)

        this.bg.interactive = true
        this.bg.on('pointerdown', (e) => {
            this.app.onClick(e)
        })

        this.groundRegions = new PIXI.Container()
        this.addChild(this.groundRegions)

        for (let i = 0; i <= MAX_GROUND_REGIONS; i++) {
            const groundRegion = new GroundRegion(0xeeeeee, i)
            this.groundRegions.addChild(groundRegion)
        }

        this.points = new PIXI.Graphics()
        this.addChild(this.points)

        this.app.events.on('import-finish', () => this.updateGround())
    }

    onWindowResize() {
        this.createBgPoints()
        this.handleMove()
    }

    handleMove() {
        const tileSize = this.app.getTileSize()

        this.points.x = -Math.floor(this.app.floorPlanCanvas.position.x / (tileSize * this.app.floorPlanCanvas.scale.x)) * tileSize
        this.points.y = -Math.floor(this.app.floorPlanCanvas.position.y / (tileSize * this.app.floorPlanCanvas.scale.y)) * tileSize
    }

    createBgPoints() {
        this.points.clear()
        this.points.beginFill(0xc0c0c0)

        const tileSize = this.app.getTileSize()

        for (let i = 0; i < this.app.view.width / this.app.floorPlanCanvas.scale.x; i += tileSize) {
            for (let j = 0; j < this.app.view.height / this.app.floorPlanCanvas.scale.y; j += tileSize) {
                this.points.drawCircle(i, j, 2 / this.app.floorPlanCanvas.scale.x)
            }
        }
        this.points.endFill()
    }

    updateM2() {
        if (this.app.floorPlanCanvas?.m2 === undefined)
            return

        const m2 = this.app.floorPlanCanvas.m2
        // console.log("update m2", m2)

        const m2_2 = m2 / 2

        var dash = 25
        if (m2 > 10000)
            dash = 100
        if (m2 > 100000)
            dash = 0

        const points: PIXI.Point[] = []
        points.push(new PIXI.Point(-m2_2, -m2_2))
        points.push(new PIXI.Point(-m2_2, m2_2))
        points.push(new PIXI.Point(m2_2, m2_2))
        points.push(new PIXI.Point(m2_2, -m2_2))
        this.bg
            .beginFill(this.app.backgroundColor)
            .lineStyle(0)
            .drawRect(-m2_2, -m2_2, m2, m2)

        if (dash !== 0)
            this.bg.lineStyle(3, 0xff5050)
                .drawDashedPolygon(points, undefined, undefined, undefined, dash, dash)

        this.bg.endFill()
    }

    setGroundVisible(visible: boolean) {
        this.groundRegions.visible = visible;
        if (visible)
            this.updateGround()
    }

    updateGround(regionNum?: number) {
        if (!this.groundRegions.visible) return
        if (!this.app.importFinished) return

        // console.log("updateGround", region, Math.random())
        if (!regionNum)
            this.groundRegions.children.forEach((r) => r.updateRegion())
        else {
            const region = this.groundRegions.getChildAt(regionNum)
            if (region)
                region.updateRegion()
        }
    }
}