import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import initializeStore from './store/initializeStore';
import axios from 'axios';
import { getApiUrl } from './store/api';
import { ThemeProvider } from '@mui/material';
import theme from './styles/theme';

const container = document.getElementById('root')!;
const root = createRoot(container);
const store = initializeStore();

axios.defaults.baseURL = getApiUrl();

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

