import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, AuthStatus } from "../../../types/Auth.types";

const initialState: AuthState = {
    status: "pending",
    token: undefined,
    accountUuid: undefined,
    username: undefined,
    licenceM2: undefined,
}

export const toolSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        authRequest: (state, action: PayloadAction<string | null>) => { },
        setAuthToken: (state, { payload: token }: PayloadAction<string>) => {
            // console.log("setting auth", token)
            state.token = token
        },
        setAccountUuid: (state, { payload: accountUuid }: PayloadAction<string>) => {
            // console.log("set accountUuid", accountUuid)
            state.accountUuid = accountUuid
        },
        setUsername: (state, { payload: username }: PayloadAction<string>) => {
            // console.log("setUsername", username)
            state.username = username
        },
        setAuthSatus: (state, { payload }: PayloadAction<AuthStatus>) => {
            state.status = payload
        },
        setLicenceM2: (state, { payload: licenceM2 }: PayloadAction<number>) => {
            state.licenceM2 = licenceM2
            // console.log("set lm2", licenceM2)
        },
    }
})

export const { authRequest, setAuthToken, setAuthSatus, setAccountUuid, setUsername, setLicenceM2 } = toolSlice.actions
export default toolSlice.reducer