import { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectImageArtworks } from "../../../store/reducers/Artworks";
import { setPorpertySpotType, setPropertyName, setPropertyOrientation, setPropertySpotArtworkUuid, setPropertyX, setPropertyY } from "../../../store/reducers/FloorPlan/FloorPlan";
import { confirmDeleteModalOpen } from "../../../store/reducers/UI";
import { ComponentOrientations, ComponentOrientationsToEngText } from "../../../types/FloorPlan/OrientableComponent.types";
import { Spot, SpotTypesEnum } from "../../../types/FloorPlan/Spot.types";
import DataPreviewGrid from "../Components/dataPreviewGrid";
import { InspectorBody } from "../Layout/inspectorBody";
import { InspectorFooter } from "../Layout/inspectorFooter";
import { ButtonPropertyInput } from "../Properties/ButtonPropertyInput";
import { NumberPropertyInput } from "../Properties/NumberPropertyInput";
import { PropertyInput } from "../Properties/PropertyInput";
import { SelectPropertyInput } from "../Properties/SelectPropertyInput";
import { DefaultInspectorOptions } from "../Components/defaultInspectorOptions";


export function SpotsSelectedInspector({ canDelete = true }: { canDelete?: boolean }) {

    const allSpots = useAppSelector(state => state.floorPlan.floorPlan?.spots)
    const selectedData = useAppSelector(state => state.editor.selectedData)

    const [spots, setSpots] = useState<Spot[]>([])

    const artworks = useAppSelector(selectImageArtworks)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (allSpots) {
            const newSpots: Spot[] = []
            selectedData.filter((e) => e.type === "Spot").forEach(element => {
                const spot = allSpots[element.id]
                if (spot)
                    newSpots.push(spot)
                else
                    console.warn(element.id + " not found as a spot")
            })
            setSpots(newSpots)
        }
    }, [allSpots, selectedData])

    const [generalType, setGeneralType] = useState<SpotTypesEnum | false>(false)
    const [artworkUuid, setArtworkUuid] = useState<string>("")
    useEffect(() => {
        if (spots.length > 0) {
            setGeneralType(spots.reduce<SpotTypesEnum | false>((pr, cr) => cr.type === pr && cr.type, spots[0].type))
            setArtworkUuid(spots.reduce<string>((pr, cr) => cr.artworkUUID === pr ? cr.artworkUUID : "", spots[0].artworkUUID || ""))
        }
    }, [spots])


    return (
        <Fragment>
            <InspectorBody>
                <PropertyInput
                    propertyName="name"
                    label="Name"
                    objects={spots}
                    onValueChange={(name: string) => {
                        selectedData.forEach(data => {
                            dispatch(setPropertyName({
                                type: data.type,
                                id: data.id,
                                name
                            }))
                        })
                    }}
                />
                <NumberPropertyInput
                    propertyName={"x"}
                    label="X"
                    objects={spots}
                    onValueChange={(newX) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyX({
                                type: data.type,
                                id: data.id,
                                x: newX
                            }))
                        })
                    }
                />
                <NumberPropertyInput
                    propertyName={"y"}
                    label="Y"
                    objects={spots}
                    onValueChange={(newY) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyY({
                                type: data.type,
                                id: data.id,
                                y: newY
                            }))
                        })
                    }
                />

                <SelectPropertyInput<SpotTypesEnum, "type">
                    keyValue={{
                        ARTWORK: "Artwork",
                        MOVEMENT: "Movement"
                    }}
                    propertyName="type"
                    label="Type"
                    objects={spots}
                    onValueChange={(spotType) =>
                        selectedData.forEach(data => {
                            dispatch(setPorpertySpotType({
                                type: data.type,
                                id: data.id,
                                spotType
                            }))
                        })
                    }
                />


                {generalType === SpotTypesEnum.MOVEMENT &&
                    <SelectPropertyInput<ComponentOrientations, "orientation">
                        keyValue={ComponentOrientationsToEngText}
                        propertyName="orientation"
                        label="Orientation"
                        objects={spots}
                        onValueChange={(newOrientation) =>
                            selectedData.forEach(data => {
                                dispatch(setPropertyOrientation({
                                    type: data.type,
                                    id: data.id,
                                    orientation: newOrientation
                                }))
                            })
                        }
                    />
                }



                {generalType === SpotTypesEnum.ARTWORK &&
                    <DataPreviewGrid
                        data={Object.values(artworks).map((a) => {
                            return {
                                id: a.uuid,
                                previewImageURL: a.photo.original,
                                altText: a.title?.en_EN || a.photo.fileName
                            }
                        })}
                        selectedData={{ [artworkUuid]: true }}
                        onClickData={(a) => selectedData.forEach(data => {
                            dispatch(setPropertySpotArtworkUuid({
                                type: data.type,
                                id: data.id,
                                artworkUuid: a.id
                            }))
                        })}
                    />
                }

            </InspectorBody>
            <InspectorFooter>

                <ButtonPropertyInput
                    onClick={() => {
                        dispatch(confirmDeleteModalOpen())
                    }}
                    color={"error"}
                    variant={"contained"}
                    disabled={!canDelete}
                >
                    <span>Delete</span>
                </ButtonPropertyInput>

                <DefaultInspectorOptions />
            </InspectorFooter>
        </Fragment>
    )
} 