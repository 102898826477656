import { Fragment } from "react"
import { InspectorHeader } from "./Layout/inspectorHeader"
import { WallsSelectedInspector } from "./SelectedInspector/wallsSelectedInspector"



export function DrawWallsToolInspector() {

    return (
        <Fragment>
            <InspectorHeader>
                New wall
            </InspectorHeader>
            <WallsSelectedInspector canDelete={false} />
        </Fragment >
    )
}