import * as PIXI from 'pixi.js'
import { EYE_LINE_HEIGHT_CM_MIN, FloorPlanDataType, FloorPlanMap } from '../../../types/FloorPlan/FloorPlan.types';
import { FloorPlanEditorSetting } from '../../../types/FloorPlan/FloorPlan.types';
import { FloorPlanElement } from './components/floorPlanElement';
import { EntranceElement } from './components/orientableFloorPlanElements/entranceElement';
import { SpotElement } from './components/orientableFloorPlanElements/spotElement';
import { LightElement } from './components/orientableFloorPlanElements/lightElement';

import { WallElement } from './components/wallElement';
import { InteractionEvent } from 'pixi.js';
import { FloorPlanCanvas } from './canvas/floorPlanCanvas'
import { Tool } from './tools/tool';
import { SelectTool } from './tools/selectTool';
import { DrawWallsTool } from './tools/drawWallsTool';
import { VisiblePoint } from './components/visiblePoint';
import { ArtworkElement } from './components/artworkElement';
import { PlaceArtworksTool } from './tools/placeArtworksTool';
import { PlaceEntranceTool } from './tools/placeEntranceTool';
import { PlaceLightTool } from './tools/placeLightTool';
import { PlaceSpotTool } from './tools/placeSpotTool';
import { SculptureElement } from './components/orientableFloorPlanElements/sculptureElement';
import { PlaceSculptureTool } from './tools/placeSculptureTool';

import drawCursor from '../../../assets/cursors/draw.png';
import { ToolType } from '../../../types/Editor/Tool.types';
import { Spot } from '../../../types/FloorPlan/Spot.types';
import { Light } from '../../../types/FloorPlan/Light.types';
import { Sculpture } from '../../../types/FloorPlan/Sculpture.types';
import { SelectedData } from '../../../types/Editor.types';
import { ArtworkDndCanvas } from './canvas/artworkDndCanvas';
import { CanvasBase } from './canvas/canvasBase';
import { Wall, WallSide } from '../../../types/FloorPlan/Wall.types';
import { FloorPlanArtwork } from '../../../types/FloorPlan/Artwork.types';
import { ChangeToolToTool } from './tools/changeToolToTool';
import { Ruler } from './components/ruler';
import { Emitter } from '../../../utils/EventEmmiter';
import { Exporter } from './utils/exporter';
import { ResourcesLoader } from './utils/resourcesLoader';
import { MouseText } from './components/mouseText';
// import { extensions } from '@pixi/extensions';
// import { Extract } from '@pixi/extract';

// extensions.add(Extract);

export class FloorPlanApp extends PIXI.Application {
    // singleton
    private static _instance: FloorPlanApp;

    // DomElement parent
    private _domElement: HTMLElement

    // id of canvas
    id: string = 'canvas-'

    // floor plan canvas, in charge of the elements of the scene
    floorPlanCanvas: FloorPlanCanvas // TODO: private
    artworkDndCanvas: ArtworkDndCanvas // TODO: private
    currentCanvas: CanvasBase

    // app setting 
    readonly backgroundColor = 0xfafafa
    wallsHeight: number
    eyeLineHeight: number
    snapToGrid: boolean

    // All Elements
    floorPlanElements: { [key: string]: FloorPlanElement } = {}
    importFinished: boolean

    // select elements
    selectedElements: FloorPlanElement[] = []

    extract: Exporter
    resourcesLoader: ResourcesLoader

    // tools
    tools: {
        None: ChangeToolToTool,
        Select: SelectTool,
        DrawWalls: DrawWallsTool,
        Entrance: PlaceEntranceTool,
        PlaceArtworks: PlaceArtworksTool,
        Sculptures: PlaceSculptureTool,
        Lights: PlaceLightTool,
        Spots: PlaceSpotTool,
        Settings: ChangeToolToTool,
        Help: ChangeToolToTool
    }
    currentTool?: Tool // tool

    ruler: Ruler
    mouseText: MouseText

    // events
    events: Emitter<{ "import-finish": [] }>

    constructor(domElement: HTMLElement) {
        super({
            width: domElement.offsetWidth,
            height: domElement.offsetHeight,
            backgroundColor: 0xe0e0e0,
            antialias: true,
        })

        FloorPlanApp._instance = this

        this._domElement = domElement

        this.importFinished = false
        this.events = new Emitter()

        // setup floor plan canvas
        this.floorPlanCanvas = new FloorPlanCanvas()
        this.stage.addChild(this.floorPlanCanvas)

        // setup artwork dnd canvas
        this.artworkDndCanvas = new ArtworkDndCanvas()

        this.currentCanvas = this.floorPlanCanvas

        this.ruler = new Ruler()
        this.stage.addChild(this.ruler)

        //mouse hover
        this.stage.addChild(this.mouseText = new MouseText("test"))

        // setup tools
        this.tools = {
            None: new ChangeToolToTool("Select"),
            Select: new SelectTool(),
            DrawWalls: new DrawWallsTool(),
            Entrance: new PlaceEntranceTool(),
            PlaceArtworks: new PlaceArtworksTool(),
            Sculptures: new PlaceSculptureTool(),
            Lights: new PlaceLightTool(),
            Spots: new PlaceSpotTool(),
            Settings: new ChangeToolToTool("Select"),
            Help: new ChangeToolToTool("Select")
        }

        // pointer interaction
        this.stage.interactive = true
        this.stage
            .on('pointerup', this.onDragEnd)
            .on('pointerupoutside', this.onDragEnd)
            .on('pointermove', this.onDragMove)

        // setup app
        document.body.onresize = () => this.resizeRenderer()
        // this.renderer.view.style.position = 'absolute'
        this._domElement.appendChild(this.view) // add to dom

        this._domElement.addEventListener('wheel', this.onWheelEvent)
        this._domElement.addEventListener('contextmenu', (e) => { e.preventDefault() }) // dissable right click panel
        this._domElement.onselectstart = () => false // disable select text

        // cursors
        this.renderer.plugins.interaction.cursorStyles.draw = `url(${drawCursor}) 8 24, auto`;

        // settings
        this.wallsHeight = 300
        this.eyeLineHeight = EYE_LINE_HEIGHT_CM_MIN
        this.snapToGrid = false

        this.extract = new Exporter(this.renderer)
        this.resourcesLoader = new ResourcesLoader()
    }

    public static getInstance() {
        return FloorPlanApp._instance
    }


    destroy() {
        this.stage
            .off('pointerup')
            .off('pointerupoutside')
            .off('pointermove')
        this.stage.destroy()
        super.destroy(true)
    }

    getAppHeight() {
        return this._domElement?.offsetHeight || this.stage.height
    }

    getAppWidth() {
        return this._domElement?.offsetWidth || this.stage.width
    }

    // DATA

    import(data: FloorPlanMap) {
        // console.log("IMPORT DATA:", data)
        if (!data) {
            return;
        }

        if (typeof data === 'string') {
            data = JSON.parse(data);
        }

        Object.values(data.walls).forEach((wall) => {
            const wallElement = new WallElement(wall)
            this.addElement(wallElement)
        })

        Object.values(data.artworks).forEach((artwork) => {
            this.createNewArtwork(artwork)
        })

        // Sculptures
        Object.values(data.sculptures).forEach((sculpture) => {
            this.addElement(new SculptureElement(sculpture))
        })

        // Lights
        Object.values(data.lights).forEach((light) => {
            this.addElement(new LightElement(light))
        })

        // Floor Plan Spots
        Object.values(data.spots).forEach((spot) => {
            this.addElement(new SpotElement(spot));
        })

        // Entrance
        const { entrance } = data;
        if (entrance) {
            const entranceElement = new EntranceElement(entrance)
            this.addElement(entranceElement)
            this.tools.Entrance.entrance = entranceElement
            this.floorPlanElements["entrance"] = entranceElement
        }

        this.floorPlanCanvas.centerFloorPlan()

        this.importFinished = true
        this.events.emit('import-finish')
    }

    // Elements
    addElement(element: FloorPlanElement) {
        this.floorPlanCanvas.addElement(element)
        this.floorPlanElements[element.mapRefUUID] = element
    }

    createNewElement(type: FloorPlanDataType, id: string): FloorPlanElement | undefined {
        switch (type) {
            case "Spot":
                const spotElement = new SpotElement(new Spot(id))
                this.addNewPreviewElement(spotElement)
                this.tools.Spots.spot = spotElement
                return spotElement
            case "Light":
                const lightElement = new LightElement(new Light(id))
                this.addNewPreviewElement(lightElement)
                this.tools.Lights.light = lightElement
                return lightElement
            case "Sculpture":
                const sculptureElement = new SculptureElement(new Sculpture(id))
                this.addNewPreviewElement(sculptureElement)
                this.tools.Sculptures.sculptureElement = sculptureElement
                return sculptureElement
            case "Wall":
                const wallElement = new WallElement(new Wall(id))
                this.addNewPreviewElement(wallElement)
                this.tools.DrawWalls._drawingWall = wallElement
                return wallElement
        }
    }

    createNewArtwork(artwork: FloorPlanArtwork) {
        // console.log("create new artwork", artwork)
        if (!artwork.wallId)
            throw new Error("Artwork without wall")

        const wallElement = this.floorPlanElements[artwork.wallId]

        if (!(wallElement instanceof WallElement))
            return

        const artworkElement = new ArtworkElement(
            wallElement,
            artwork
        )
        wallElement.addArtwork(artworkElement)
        this.addElement(artworkElement)

        return artworkElement
    }

    addNewPreviewElement(element: FloorPlanElement) {
        this.floorPlanCanvas.topLayer.transparentLayer.addChild(element)
        this.floorPlanElements[element.mapRefUUID] = element
    }


    removeElement(element: FloorPlanElement) {
        element.parent.removeChild(element)
        element.destroy({ children: true })

        if (element instanceof EntranceElement)
            this.tools.Entrance.entrance = undefined

        if (element instanceof ArtworkElement)
            element.wall.removeArtwork(element)

        delete this.floorPlanElements[element.mapRefUUID]
        this.selectedElements = []
    }

    clear() {
        Object.values(this.floorPlanElements).forEach(element => {
            if (element)
                this.removeElement(element)
        })
        this.selectedElements = []
    }

    hideArtworkDndCanvas() {
        this.currentCanvas = this.floorPlanCanvas
        this.stage.removeChild(this.artworkDndCanvas)
        this.onWindowResize()
    }

    showArtworkDndWall(wall: WallElement, side: WallSide) {
        this.currentCanvas = this.artworkDndCanvas
        this.stage.addChildAt(this.artworkDndCanvas, 1)
        this.artworkDndCanvas.showWall(wall, side)
        this.onWindowResize()
    }

    updateDndCanvas(artworkId: string) {
        this.artworkDndCanvas.update(artworkId)
    }


    // Resize screen
    onWheelEvent(e: WheelEvent) {
        const app = FloorPlanApp.getInstance()

        app.zoomTo(app.currentCanvas.scale.x - e.deltaY * 0.001, e.offsetX, e.offsetY)
    }

    resizeRenderer() {
        if (!this || !this.renderer)
            return

        this.renderer.resize(this._domElement.offsetWidth, this._domElement.offsetHeight)
        this.onWindowResize()
    }

    onWindowResize() {
        this.currentCanvas.onWindowResize()

        this.ruler.onWindowResize()
    }

    // recalculate tile size, to not paint so many point when the scale is low
    getTileSize(): number {
        const scale = this.currentCanvas ? 100 / this.currentCanvas.scale.x : 100

        if (scale >= 600)
            return 400
        if (scale >= 400)
            return 200
        if (scale >= 200)
            return 100
        if (scale >= 100)
            return 50
        if (scale >= 50)
            return 25
        return 12.5
    }

    zoomTo(zoom: number, x?: number, y?: number) {
        if (zoom < 0.1 || zoom > 10)
            return

        // if no zoom coordinates, zoom to the center of the view
        this.currentCanvas.zoomTo(
            zoom,
            x ? x : this.view.width / 2,
            y ? y : this.view.width / 2
        )

        this.onWindowResize()
    }

    zoomIn() {
        this.zoomTo(this.currentCanvas.scale.x * 1.1)
    }

    zoomOut() {
        this.zoomTo(this.currentCanvas.scale.x * 0.9)
    }


    // Interaction
    setTool(tool: ToolType, data?: any, force?: boolean) {
        if (this.currentTool?.toolType === tool && !force)
            return


        // console.log("SELECT TOOL:", tool, data, this.currentTool)

        this.currentTool?.dissable()
        this.currentTool = this.tools[tool]
        this.currentTool.enable(data)
    }

    // Pointer Interactions
    onClick(e: InteractionEvent, element?: FloorPlanElement | VisiblePoint) {
        // console.log("click buttons", e.data.button, e.data.buttons)
        FloorPlanApp.getInstance().currentTool?.onClick(e, element)
    }

    onDragMove(e: InteractionEvent) {
        const target = e.data?.originalEvent?.target
        if (target instanceof Element && target.tagName === "CANVAS") {
            FloorPlanApp.getInstance().currentCanvas.onDragMove(e)
        }
    }

    onDragEnd(e: InteractionEvent) {
        FloorPlanApp.getInstance().currentTool?.onDragEnd(e)
    }

    setVisible(setting: FloorPlanEditorSetting, visible: boolean = true) {
        this.floorPlanCanvas.setVisible(setting, visible)

        if (setting === "showRuler") this.ruler.setVisible(visible)
        if (setting === "floorPreviewVisible") this.floorPlanCanvas.bgLayer.setGroundVisible(visible)
    }

    onSelectChange(selectedData: SelectedData[]) {
        this.floorPlanCanvas.mainLayer.onSelectChange()
        this.currentTool?.onSelectChange(selectedData)
    }

    onCanvasMove() {
        this.ruler.handleMove()
    }

    setM2(m2: number) {
        this.floorPlanCanvas.m2 = m2
        this.floorPlanCanvas.bgLayer.updateM2()
    }

    updateGround(region?: number) {
        this.floorPlanCanvas.bgLayer.updateGround(region)
    }
}