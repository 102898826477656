import * as PIXI from 'pixi.js'
import { FloorPlanApp } from '../floorPlanApp'
import { DndWall } from '../artworkDnd/dndWall'
import { WallElement } from '../components/wallElement'
import { WallSide } from '../../../../types/FloorPlan/Wall.types'
import { FloorPlanArtwork } from '../../../../types/FloorPlan/Artwork.types'

export class Exporter {
    app: FloorPlanApp = FloorPlanApp.getInstance()
    extract!: PIXI.Extract

    constructor(renderer: PIXI.Renderer | PIXI.AbstractRenderer) {
        // this.app.zoomTo(2, 2)
        if (renderer instanceof PIXI.Renderer) {
            this.extract = new PIXI.Extract(renderer)
        }
    }

    exportFloorPlan() {
        // console.log("exportFloorPlan")

        const container = new PIXI.Container()
        // this.app.floorPlanCanvas.bgLayer.addChild(this.app.floorPlanCanvas.mainLayer)
        this.app.floorPlanCanvas.scale.set(2, 2)
        this.app.floorPlanCanvas.onWindowResize()

        container.addChild(this.app.floorPlanCanvas.bgLayer.groundRegions)
        container.addChild(this.app.floorPlanCanvas.mainLayer)

        const bounds = container.getBounds()

        const backgroundGraphic = new PIXI.Graphics()
            .beginFill(0xffffff)
            .drawRect(bounds.x - 10, bounds.y - 10, bounds.width + 20, bounds.height + 20)
        backgroundGraphic.alpha = 0.01
        container.addChildAt(backgroundGraphic, 0)

        const wall = new DndWall(bounds.width, bounds.height)
        container.addChildAt(wall, 0)
        wall.position.x = bounds.x
        wall.position.y = bounds.y


        const image = this.extract.image(container)
        image.width = container.width
        image.height = container.height

        return image
    }

    exportWall(wallId: string, side: WallSide, selectedArtworkId?: string) {
        // console.log("export wall", wallId, selectedArtworkId)

        const wallElement = this.app.floorPlanElements[wallId]
        if (!(wallElement instanceof WallElement))
            return false
        const wall = new DndWall(wallElement.wallWidth, this.app.wallsHeight, this.app.eyeLineHeight)

        var selectedArtworkData: FloorPlanArtwork | undefined
        wallElement.artworks.forEach(artwork => {
            if (artwork.artworkData.side === side)
                wall.addArtwork(artwork.artworkData)
            if (selectedArtworkId === artwork.mapRefUUID)
                selectedArtworkData = artwork.artworkData
        })
        if (!!selectedArtworkData)
            wall.updateArtwork(selectedArtworkData)

        // console.log("Exporting wall", wallId, Object.keys(wall.artworks).length)

        const wallBounds = wall.getBounds()
        const backgroundGraphic = new PIXI.Graphics()
            .beginFill(0xffffff)
            .drawRect(wallBounds.x * 2 - 10, wallBounds.y * 2 - 10, wall.width * 2 + 20, wall.height * 2 + 20)
        backgroundGraphic.alpha = 0.01
        wall.addChildAt(backgroundGraphic, 0)
        wall.scale.set(2, 2)

        const image = this.extract.image(wall)
        image.width = wall.width
        image.height = wall.height
        return image
    }

}