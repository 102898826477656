
import { Box, Slide } from "@mui/material";
import { SxProps } from "@mui/material/styles";

export function Subinspector({ children, in: isIn, sx }: { children: React.ReactNode, in: boolean, sx?: SxProps }) {
    return (
        <Box sx={{ position: "absolute", right: 0, top: 0, bottom: 0, zIndex: 10, height: "100%" }}>
            <Slide direction="left" in={isIn}>
                <Box className='inspector' sx={sx}>
                    {children}
                </Box>
            </Slide>
        </Box>
    )
}