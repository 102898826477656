import React from "react"
import { BasePropertyInput } from "./BasePropertyInput"
import { PropertyInputLabel } from "./PropertyInputLabel"

export function InfoProperty(
    {
        label,
        children,
        error,
        info
    }: {
        label: string, children?: React.ReactNode,
        error?: boolean
        info?: React.ReactNode
    }) {

    return (
        <BasePropertyInput error={error}>
            <PropertyInputLabel content={info}>{label}</PropertyInputLabel>
            {children}
        </BasePropertyInput>)
}