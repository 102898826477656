import clsx from "clsx";
import { Fragment } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { toggleEditorSetting } from "../../../store/reducers/Editor";
import { ButtonPropertyInput } from "../Properties/ButtonPropertyInput";
import { InspectorFooter } from "../Layout/inspectorFooter";
import { InspectorBody } from "../Layout/inspectorBody";
import { open3dEditor, open3dPreview } from "../../../store/reducers/Exhibition";
import View3dIcon from '../../../assets/img/icon-3d-view.svg';
import { IconButton } from "@mui/material";
import { DefaultInspectorOptions } from "../Components/defaultInspectorOptions";
// import { Navigate } from "react-router-dom";

export function NoDataSelectedInspector() {
    const dispatch = useAppDispatch()

    const editorSettings = useAppSelector(state => state.editor.editorSettings)
    const exhibitionUuid = useAppSelector(state => state.exhibition.uuid)

    return ((
        <Fragment>
            <InspectorBody>
                <div className={clsx('input-box', { error: false })}>
                    <label>Floor preview</label>

                    <IconButton className='end-icon' onClick={() => {
                        dispatch(toggleEditorSetting("floorPreviewVisible"))
                    }}>
                        {editorSettings.floorPreviewVisible ?
                            <VisibilityIcon /> :
                            <VisibilityOffIcon />
                        }
                    </IconButton>
                </div>
                <div className={clsx('input-box', { error: false })}>
                    <label>Lights</label>

                    <IconButton className='end-icon' onClick={() => {
                        dispatch(toggleEditorSetting("lightsVisible"))
                    }}>
                        {editorSettings.lightsVisible ?
                            <VisibilityIcon /> :
                            <VisibilityOffIcon />
                        }
                    </IconButton>
                </div>
                <div className={clsx('input-box', { error: false })}>
                    <label>Artworks</label>

                    <IconButton className='end-icon' onClick={() => {
                        dispatch(toggleEditorSetting("artworksVisible"))
                    }}>
                        {editorSettings.artworksVisible ?
                            <VisibilityIcon /> :
                            <VisibilityOffIcon />
                        }
                    </IconButton>
                </div>
                <div className={clsx('input-box', { error: false })}>
                    <label>Dimensions</label>

                    <IconButton className='end-icon' onClick={() => {
                        dispatch(toggleEditorSetting("dimensionsVisible"))
                    }}>
                        {editorSettings.dimensionsVisible ?
                            <VisibilityIcon /> :
                            <VisibilityOffIcon />
                        }
                    </IconButton>
                </div>
                <div className={clsx('input-box', { error: false })}>
                    <label>Spots</label>

                    <IconButton className='end-icon' onClick={() => {
                        dispatch(toggleEditorSetting("spotsVisible"))
                    }}>
                        {editorSettings.spotsVisible ?
                            <VisibilityIcon /> :
                            <VisibilityOffIcon />
                        }
                    </IconButton>
                </div>

            </InspectorBody>
            <InspectorFooter>

                <ButtonPropertyInput
                    onClick={() => {
                        dispatch(open3dPreview())
                    }}
                    color="primary"
                    variant={"contained"}
                >
                    <img src={View3dIcon} width='24' alt='3d-view-icon' style={{ paddingRight: 5 }} />
                    <span>3D Preview</span>
                </ButtonPropertyInput>

                <ButtonPropertyInput
                    onClick={() => {
                        dispatch(open3dEditor())
                    }}
                    color="primary"
                    variant={"contained"}
                >
                    <span>3D Editor</span>
                </ButtonPropertyInput>
                <ButtonPropertyInput
                    onClick={() => {
                        window.open('/print?uuid=' + exhibitionUuid, '_blank')
                    }}
                    color="primary"
                    variant={"contained"}
                >
                    <span>Download PDF</span>
                </ButtonPropertyInput>
                <DefaultInspectorOptions />
            </InspectorFooter>
        </Fragment >
    )
    )
}