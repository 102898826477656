import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '..';
import { Exhibition } from '../../types/Exhibition.types';
import { callApi } from '../api';
// import { callApi } from '../api';
import { getExhibitionRequest, open3dEditor, open3dPreview, setExhibition, setExhibitionStatus } from '../reducers/Exhibition';
import { isSuccessResponse } from '../utils';
import { saveFloorPlanRequest } from '../reducers/FloorPlan/FloorPlan';


// import { isSuccessResponse } from '../utils';

function* doGetExhibitionMap({ payload: uuid }: PayloadAction<string>) {
    // console.log("doGetExhibitionMap", uuid)
    try {
        yield put(setExhibitionStatus("Loading"))
        const exhibitionResponse: AxiosResponse = yield call(callApi, 'GET', `/exhibitions/${uuid}`)
        if (!isSuccessResponse(exhibitionResponse, true)) {
            console.error(exhibitionResponse)
            yield put(setExhibitionStatus("Error_Uuid"))
            return
        }

        // console.log("exhibitionData", JSON.stringify(exhibitionResponse.data))

        var mapUuid = exhibitionResponse.data.data.map

        if (mapUuid === null) {
            // console.log("Creating new floor plan map")


            const createMapRes: AxiosResponse = yield call(callApi, 'POST', '/maps', {
                exhibition: uuid,
                walls: [],
                lights: [],

                has_ceil: false,
                ceil_color: '#cccccc',

                ground_color: "#252525",
                floor_color: "#999999",
                has_floor: true,
                floor_has_texture: true,
                floor_texture_id: "2502",

                wall_height: 300,

            });

            if (createMapRes?.data?.data?.uuid)
                mapUuid = createMapRes.data.data.uuid
            else
                throw new Error('Unable to create new map.')

        }

        const exhibition: Exhibition = {
            mapUuid,
            title: exhibitionResponse.data.data.title.en_EN // TODO: Translate
        }

        yield put(setExhibition(exhibition))
        yield put(setExhibitionStatus("Success_ExhibitionData"))
    } catch (error) {
        if (error instanceof AxiosError) {
            console.error(error)
            if (error.code === AxiosError.ERR_BAD_REQUEST)
                yield put(setExhibitionStatus("Error_Uuid"))
            else
                yield put(setExhibitionStatus("Error_Unknown"))
        } else {
            console.error('Unexpected error', error);
            yield put(setExhibitionStatus("Error_Unknown"))
        }
        /* 
        yield put(openNotification('An error occurred when getting exhibition map.', 'error'));
        yield put(errorAction(type, error)); */
    }
}

export function* watchGetExhibitionMap() {
    yield takeLatest(getExhibitionRequest, doGetExhibitionMap);
}


function* doOpen3dPreview() {
    yield put(saveFloorPlanRequest())

    const defaultLanguage = "en_EN"
    const exhibitionUuid: string = yield select((state: RootState) => state.exhibition.uuid)

    const url = `${process.env.REACT_APP_VISOR_3D_URL_BASE}?uuid=${exhibitionUuid}&l=${defaultLanguage}`;
    window.open(url, '_blank')
}

export function* watchOpen3dPreview() {
    yield takeLatest(open3dPreview, doOpen3dPreview);
}

function* doOpen3dEditor() {
    const defaultLanguage = "en_EN"
    const exhibitionUuid: string = yield select((state: RootState) => state.exhibition.uuid)
    const accountUuid: string = yield select((state: RootState) => state.auth.accountUuid)

    try {
        const tokenResponse: AxiosResponse = yield call(callApi, 'POST', `/auth/temp_login_tokens`, {
            "account": accountUuid
        })
        if (!isSuccessResponse(tokenResponse, true)) {
            console.error(tokenResponse)
            throw new Error('Unable to get exhibition.')
        }
        // console.log("Token response", tokenResponse)
        const temporalToken: string = tokenResponse.data.data.token.token

        const url = `${process.env.REACT_APP_EDITOR_3D_URL_BASE}?uuid=${exhibitionUuid}&tt=${temporalToken}&l=${defaultLanguage}`;
        window.open(url, '_blank')
    } catch (error) {
        if (error instanceof Error) {
            console.error(error.message)
        } else {
            console.error('Unexpected error', error);
        }
    }
}

export function* watchOpen3dEditor() {
    yield takeLatest(open3dEditor, doOpen3dEditor);
}


