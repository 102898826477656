import * as PIXI from 'pixi.js'
import { MouseText } from '../components/mouseText'

export class TopLayer extends PIXI.Container {
    transparentLayer: PIXI.Container

    constructor() {
        super()
        this.transparentLayer = new PIXI.Container()
        this.transparentLayer.alpha = 0.5

        this.addChild(this.transparentLayer)
    }
}