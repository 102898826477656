import { Fragment } from "react"
import { InspectorHeader } from "./Layout/inspectorHeader"
import { LightsSelectedInspector } from "./SelectedInspector/lightsSelectedInspector"



export function LightsToolInspector() {

    return (
        <Fragment>
            <InspectorHeader>New light</InspectorHeader >
            <LightsSelectedInspector canDelete={false} />
        </Fragment >
    )
}