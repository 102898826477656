import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {

  interface SimplePaletteColorOptions {
    hoverBg?: string;
  }
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#ebebeb',
      contrastText: '#000',
      hoverBg: '#ddd'
    },
    secondary: {
      main: '#222'
    },
    info: {
      main: '#004561'
    },
    error: {
      main: "#ffebeb",
      contrastText: "#f55858",
      hoverBg: '#ffdddd'
    },
    background: {
      default: '#ffffff'
    }
  }
});

export default theme;
