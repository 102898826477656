import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Exhibition, ExhibitionState, ExhibitionStatus } from "../../types/Exhibition.types";


const initialState: ExhibitionState = {
    exhibition: null,
    uuid: "",
    exhibitionStatus: "None"
}

export const exhibitionSlice = createSlice({
    name: "exhibition",
    initialState,
    reducers: {
        getExhibitionRequest: (state, { payload }: PayloadAction<string>) => {
            state.uuid = payload
        },
        setExhibition: (state, { payload }: PayloadAction<Exhibition>) => {
            // console.log("setting exhibition", payload)
            state.exhibition = payload;
        },
        setExhibitionStatus: (state, { payload }: PayloadAction<ExhibitionStatus>) => {
            state.exhibitionStatus = payload
        },
        open3dPreview: () => { },
        open3dEditor: () => { },

    }
})

export const { setExhibition, getExhibitionRequest, open3dPreview, open3dEditor, setExhibitionStatus } = exhibitionSlice.actions

export default exhibitionSlice.reducer