import { InteractionEvent } from "pixi.js";
import { EntranceElement } from "../components/orientableFloorPlanElements/entranceElement";
import FloorPlanConnector from "../floorPlanConnector";
import { PlaceOrientableComponentTool } from "./placeOrientableComponentTool";

export class PlaceEntranceTool extends PlaceOrientableComponentTool<EntranceElement> {

    constructor() {
        super("Entrance", "Entrance")
        this.toolType = "Entrance"
    }/* 
    onDragMove(e: InteractionEvent): void {
        if (!this.entrance)
            return

        const interactionPoint = e.data.getLocalPosition(this.app.floorPlanCanvas)
        FloorPlanConnector.moveElementTo(interactionPoint.x, interactionPoint.y, this.entrance.mapRefUUID, "Entrance")
    }
 */
    set entrance(entrance: EntranceElement | undefined) {
        this.floorPlanElement = entrance
    }

    enable() {
        if (!this.floorPlanElement) return
        this.app.selectedElements = [this.floorPlanElement]
        this.floorPlanElement.setInteractive(true)
        this.floorPlanElement.select()
    }

    // interaction    
    onDragEnd(e: InteractionEvent): void {
        super.onDragEnd(e, false)
        if (this._draggingMode !== 'tool')
            return

        FloorPlanConnector.setTool("Select")

        if (this.floorPlanElement)
            FloorPlanConnector.setSelectedElements([this.floorPlanElement])
    }

    dissable() {

    }

}