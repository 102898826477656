import * as PIXI from 'pixi.js'
import { FloorPlanApp } from '../floorPlanApp';

export class CanvasBase extends PIXI.Container {
    app: FloorPlanApp


    constructor() {
        super()

        this.app = FloorPlanApp.getInstance()
    }

    // Resize Screen
    onWindowResize() {

    }

    zoomTo(newScale: number, x: number, y: number) {
        this.scale.set(newScale, newScale)

        // console.log("SCALING INTO:" + newScale, x, y)
        var beforeTransform = PIXI.InteractionData.prototype.getLocalPosition.call({ global: { x: x, y: y } }, this);
        this.updateTransform();
        var afterTransform = PIXI.InteractionData.prototype.getLocalPosition.call({ global: { x: x, y: y } }, this);

        this.moveTo(
            this.position.x + (afterTransform.x - beforeTransform.x) * this.scale.x,
            this.position.y + (afterTransform.y - beforeTransform.y) * this.scale.y
        )

        this.updateTransform();
    }

    move(dx: number, dy: number) {
        this.moveTo(this.x + dx, this.y + dy)
    }

    moveTo(x: number, y: number) {
        this.x = x
        this.y = y
        this.app.onCanvasMove()
    }

    onDragMove(e: PIXI.InteractionEvent) {
        this.app.currentTool?.onDragMove(e)
    }
}