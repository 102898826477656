import { InteractionEvent, Point } from "pixi.js";
import { SelectedData } from "../../../../types/Editor.types";
import { ToolType } from "../../../../types/Editor/Tool.types";
import { DraggingMode } from "../../../../types/FloorPlan/Tool.types";
import { FloorPlanElement } from "../components/floorPlanElement";
import { VisiblePoint } from "../components/visiblePoint";
import { FloorPlanApp } from "../floorPlanApp";

export const roundGapSize = 50

export class Tool {
    app: FloorPlanApp = FloorPlanApp.getInstance()
    _draggingMode?: DraggingMode
    interactionStartPoint: Point
    interactionStartPointGlobal: Point
    toolType?: ToolType
    _cursorOnDragEnd: string | undefined

    constructor() {
        this.interactionStartPoint = new Point()
        this.interactionStartPointGlobal = new Point()
    }

    onClick(e: InteractionEvent, element?: FloorPlanElement | VisiblePoint) {
        // console.log("tool onClick", e, element)
        if (e.data.button === 0 && e.data.buttons === 1) { // Left Mouse, Touch Contact, Pen contact
            this.changeDraggingMode('tool')
        } else if (e.data.button === 1 && e.data.buttons === 4) { // Middle Mouse
            this.changeDraggingMode('canvas')
        } else if (e.data.button === 2 && e.data.buttons === 2) { // Right Mouse, Pen barrel button
            this.changeDraggingMode('option')
        }
        this.interactionStartPointGlobal = e.data.global.clone()
        this.interactionStartPoint = e.data.getLocalPosition(this.app.currentCanvas)
    }

    onDragMove(e: InteractionEvent) {
        if (this._draggingMode === 'canvas') {
            const interactionPoint = e.data.global.clone()
            const dx = interactionPoint.x - this.interactionStartPointGlobal.x
            const dy = interactionPoint.y - this.interactionStartPointGlobal.y

            this.app.currentCanvas.move(dx, dy)

            this.interactionStartPointGlobal = interactionPoint
        }
    }

    onDragEnd(e: InteractionEvent) {
        this._draggingMode = undefined
        if (this._cursorOnDragEnd)
            this.app.stage.cursor = this._cursorOnDragEnd
    }

    changeDraggingMode(draggingMode: DraggingMode) {
        this._draggingMode = draggingMode

        switch (draggingMode) {
            case 'canvas':
                this._cursorOnDragEnd = this.app.stage.cursor || 'default'
                this.app.stage.cursor = 'move'
                break;
        }
    }

    dissable() {
        // clear
    }

    enable(data?: any) {
        // clear
    }

    handleKeyDown(e: KeyboardEvent) {
        // clear
    }

    onSelectChange(selectedData: SelectedData[]) {

    }
}