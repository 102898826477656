import { Box, Grid } from "@mui/material"
import { DataPreviewType } from "../../../types/Editor/dataPreview.types"
import { Fragment, useMemo } from 'react'
import DataPreview from "./dataPreview"


function SingleDataPreviewGrid(
    {
        data,
        selectedData,
        dissabledData,
        onClickData,
        onPointerDownData,
        canZoom
    }: {
        data: DataPreviewType[],
        selectedData?: { [key: string]: boolean },
        dissabledData?: { [key: string]: boolean },
        onClickData?: (a: DataPreviewType) => void
        onPointerDownData?: (a: DataPreviewType) => void,
        canZoom?: boolean
    }) {

    return (
        <Grid container className="preview-image-list">
            {data.map((d, i) => {
                return (
                    <DataPreview
                        key={i}

                        data={d}
                        selected={selectedData && selectedData[d.id]}
                        dissabled={dissabledData && dissabledData[d.id]}

                        onClick={onClickData && (() => onClickData(d))}
                        onPointerDown={onPointerDownData && (() => onPointerDownData(d))}

                        canZoom={canZoom}
                    />
                )
            })}
        </Grid>
    )
}

export default function DataPreviewGrid(
    {
        data,
        selectedData,
        dissabledData,
        onClickData,
        onPointerDownData,
        canZoom
    }: {
        data: DataPreviewType[],
        selectedData?: { [key: string]: boolean },
        dissabledData?: { [key: string]: boolean },
        onClickData?: (a: DataPreviewType) => void
        onPointerDownData?: (a: DataPreviewType) => void,
        canZoom?: boolean
    }) {

    const groupedTypes = useMemo(() => {
        if (data)
            return (data.reduce((group, product) => {
                const type = product.type || ""
                group[type] = group[type] ?? []
                group[type].push(product)
                return group
            }, {} as { [k: string]: DataPreviewType[] }))
        return {}
    }, [data])

    return (
        <Fragment>
            <Box mt={2}></Box>
            {
                Object.entries(groupedTypes).map(([k, v]) => (
                    <Fragment key={k}>
                        <h2>{k}</h2>{
                            <SingleDataPreviewGrid
                                key={k}
                                data={v}
                                selectedData={selectedData}
                                dissabledData={dissabledData}
                                onClickData={onClickData}
                                onPointerDownData={onPointerDownData}
                                canZoom={canZoom}
                            />}
                    </Fragment>
                ))
            }
        </Fragment>
    )
}