import { Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setPropertyOrientation, setPropertyX, setPropertyY } from "../../../store/reducers/FloorPlan/FloorPlan";
import { ComponentOrientations, ComponentOrientationsToEngText } from "../../../types/FloorPlan/OrientableComponent.types";
import { InspectorBody } from "../Layout/inspectorBody";
import { NumberPropertyInput } from "../Properties/NumberPropertyInput";
import { SelectPropertyInput } from "../Properties/SelectPropertyInput";
import { InspectorFooter } from "../Layout/inspectorFooter";
import { DefaultInspectorOptions } from "../Components/defaultInspectorOptions";


export function EntranceSelectedInspector() {

    const entrance = useAppSelector(state => state.floorPlan.floorPlan?.entrance)

    const dispatch = useAppDispatch()

    if (!entrance)
        return <div></div>
    return (
        <Fragment>
            <InspectorBody>
                <NumberPropertyInput
                    propertyName={"x"}
                    label="X"
                    objects={[entrance]}
                    onValueChange={(x) =>
                        dispatch(setPropertyX({
                            type: "Entrance",
                            id: "entrance",
                            x
                        }))
                    }
                />
                <NumberPropertyInput
                    propertyName={"y"}
                    label="Y"
                    objects={[entrance]}
                    onValueChange={(y) =>
                        dispatch(setPropertyY({
                            type: "Entrance",
                            id: "entrance",
                            y
                        }))
                    }
                />
                <SelectPropertyInput<ComponentOrientations, "orientation">
                    keyValue={ComponentOrientationsToEngText}
                    propertyName="orientation"
                    label="Orientation"
                    objects={[entrance]}
                    onValueChange={(orientation) =>
                        dispatch(setPropertyOrientation({
                            type: "Entrance",
                            id: "entrance",
                            orientation
                        }))
                    }
                />
            </InspectorBody>
            <InspectorFooter>
                <DefaultInspectorOptions />
            </InspectorFooter>
        </Fragment >
    )
} 