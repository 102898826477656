import { SpotElement } from "../components/orientableFloorPlanElements/spotElement";
import { PlaceOrientableComponentTool } from "./placeOrientableComponentTool";

export class PlaceSpotTool extends PlaceOrientableComponentTool<SpotElement> {
    constructor() {
        super("Spots", "Spot")
    }

    set spot(spot: SpotElement) {
        this.floorPlanElement = spot
    }
}