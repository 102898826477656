import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import cookie from 'js-cookie';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '..';
import { AuthStatus } from '../../types/Auth.types';
import { callApi, callApiWithoutAuth } from '../api';
import { authRequest, setAccountUuid, setAuthSatus, setAuthToken, setLicenceM2, setUsername } from '../reducers/Auth';
import { isSuccessResponse } from '../utils';


export function* getAuthToken() {
    while (true) {
        const status: AuthStatus = yield select((state: RootState) => state.auth.status)
        // console.log("Getting auth token loop: ", status)
        switch (status) {
            case 'invalid_token':
                return "error"
            case 'pending':
                yield delay(250)
                break
            case 'valid_token':
                const token: string = yield select((state: RootState) => state.auth.token)
                if (token)
                    return token
                else
                    return "error"
        }
    }
}


export function* doAuthRequest({ payload: temporalToken }: PayloadAction<string | null>) {

    function* getTokenFromCookies() {
        const cookieToken = cookie.get("exhibify_token")
        // console.log("get auth cookie", cookieToken)

        if (cookieToken) {
            // console.log(" there's cookie token")
            yield put(setAuthToken(cookieToken))
            yield put(setAuthSatus('valid_token'))
            axios.defaults.headers.common['Authorization'] = "Bearer " + cookieToken
        }
        else {
            // console.log(" there's no cookie token")
            yield put(setAuthSatus('invalid_token'))
        }
    }

    if (temporalToken) {

        try {
            const res: AxiosResponse = yield call(callApiWithoutAuth,
                'POST',
                '/auth/temp_login',
                {
                    "temp_token": temporalToken
                }
            )

            if (!isSuccessResponse(res)) {
                // take token from cookies
                yield getTokenFromCookies()
                return
            }

            // console.log("SUCCESS!", res, res.data)

            const token = res.data.data.access_token
            cookie.set("exhibify_token", token)
            // console.log("Set auth cookie", token)
            yield put(setAuthToken(token))
            yield put(setAuthSatus('valid_token'))
            axios.defaults.headers.common['Authorization'] = "Bearer " + token
        }
        catch {
            // take token from cookiens
            yield getTokenFromCookies()
        }
    }
    else
        yield getTokenFromCookies()
}


export function* watchAuthRequest() {
    yield takeLatest(authRequest, doAuthRequest);
}

function* doSetAuthTokenEffect({ payload: authToken }: PayloadAction<string>) {

    try {
        const authResponse: AxiosResponse = yield call(callApi, 'GET', `/me`)
        if (!isSuccessResponse(authResponse, true)) {
            console.error(authResponse)
            throw new Error('Unable to get exhibition.')
        }
        // console.log("authResponse", authResponse)
        if (authResponse.data?.data?.active_account?.uuid)
            yield put(setAccountUuid(authResponse.data?.data?.active_account?.uuid))

        if (authResponse.data?.data?.name)
            yield put(setUsername(authResponse.data?.data?.name))

        if (authResponse.data?.data?.name)
            yield put(setLicenceM2(authResponse.data?.data?.active_account?.licence_type?.max_m2))

    } catch (error) {
        if (error instanceof Error) {
            console.error(error.message)
        } else {
            console.error('Unexpected error', error);
        }
    }
}

export function* watchSetAuthToken() {
    yield takeLatest(setAuthToken, doSetAuthTokenEffect)
}