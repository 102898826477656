import { combineReducers } from 'redux';
import Artworks from './Artworks';
import Auth from './Auth';
import Editor from './Editor';
import Exhibition from './Exhibition';
import FloorPlan from './FloorPlan/FloorPlan';
import UI from './UI';


const rootReducer = combineReducers({
    auth: Auth,
    exhibition: Exhibition,
    floorPlan: FloorPlan,
    artworks: Artworks,
    editor: Editor,
    ui: UI
});

export default rootReducer;
