import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { useKeyPress } from "../../hooks/useKeyPress"
import { setFloorPlanSetting, startSaveFloorPlanCycle } from "../../store/reducers/FloorPlan/FloorPlan"
import FloorPlan from "./FloorPlan"
import { setEditorSetting } from "../../store/reducers/Editor"
import { Box } from '@mui/material';
import clsx from "clsx";


export default function Content() {
    const dispatch = useAppDispatch()

    const shiftKeyPress = useKeyPress("Shift")
    const editorLockDegrees = useAppSelector(s => s.editor.editorSettings.lockDegrees)
    const [changeLock, setChangeLock] = useState(false)
    const inspectorExpanded = useAppSelector(s => s.editor.editorSettings.inspectorExpanded)

    useEffect(() => {
        dispatch(setFloorPlanSetting({ lockDegrees: editorLockDegrees }))
    }, [editorLockDegrees, dispatch])

    useEffect(() => {
        dispatch(setEditorSetting({ setting: "lockDegrees", value: changeLock }))
    }, [changeLock, dispatch])

    useEffect(() => {
        // if lock degrees is active, dont do anything
        if (!!!editorLockDegrees && shiftKeyPress)
            setChangeLock(true)

        if (!shiftKeyPress)
            setChangeLock(false)
    }, [shiftKeyPress, editorLockDegrees])

    useEffect(() => {
        dispatch(startSaveFloorPlanCycle())
    }, [dispatch])

    return (
        <Box className={clsx('app-canvas', { "expanded": !inspectorExpanded })} >
            <FloorPlan />
        </ Box>
    )

}