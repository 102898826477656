import { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setPropertyOrientation, setPropertyX, setPropertyY } from "../../../store/reducers/FloorPlan/FloorPlan";
import { ComponentOrientations, ComponentOrientationsToEngText, OrientableComponent } from "../../../types/FloorPlan/OrientableComponent.types";
import { NumberPropertyInput } from "../Properties/NumberPropertyInput";
import { SelectPropertyInput } from "../Properties/SelectPropertyInput";
import { FloorPlanData } from "../../../types/FloorPlan/FloorPlanElement.types";
import { Entrance } from "../../../types/FloorPlan/Entrance.types";
import { ButtonPropertyInput } from "../Properties/ButtonPropertyInput";
import { InspectorBody } from "../Layout/inspectorBody";
import { InspectorFooter } from "../Layout/inspectorFooter";
import { confirmDeleteModalOpen } from "../../../store/reducers/UI";
import { DefaultInspectorOptions } from "../Components/defaultInspectorOptions";


export function MultipleSelectedInspector() {

    const floorPlanData = useAppSelector(state => state.floorPlan.floorPlan)
    const selectedData = useAppSelector(state => state.editor.selectedData)

    const [elements, setElements] = useState<FloorPlanData[]>([])

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (floorPlanData) {
            const newData: FloorPlanData[] = []
            selectedData.forEach(element => {
                switch (element.type) {
                    case "Light":
                        const light = floorPlanData.lights[element.id]
                        if (light)
                            newData.push(light)
                        break
                    case "Spot":
                        const spot = floorPlanData.spots[element.id]
                        if (spot)
                            newData.push(spot)
                        break
                    case "Wall":
                        const wall = floorPlanData.walls[element.id]
                        if (wall)
                            newData.push(wall)
                        break
                    case "Entrance":
                        const entrance = floorPlanData.entrance
                        if (entrance)
                            newData.push(entrance as Entrance)
                        break
                    case "Sculpture":
                        const sculpture = floorPlanData.sculptures[element.id]
                        if (sculpture)
                            newData.push(sculpture)
                        break
                }
            })
            setElements(newData)
        }

    }, [floorPlanData, selectedData])


    return (
        <Fragment>
            <InspectorBody>
                {/* 
            <PropertyInput
                propertyName="name"
                label="Name"
                objects={elements}
                onValueChange={(name: string) => {
                    selectedData.forEach(data => {
                        dispatch(setPropertyName({
                            type: data.type,
                            id: data.id,
                            name
                        }))
                    })
                }}
            /> */}
                <NumberPropertyInput
                    propertyName={"x"}
                    label="X"
                    objects={elements}
                    onValueChange={(x) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyX({
                                type: data.type,
                                id: data.id,
                                x
                            }))
                        })
                    }
                />
                <NumberPropertyInput
                    propertyName={"y"}
                    label="Y"
                    objects={elements}
                    onValueChange={(y) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyY({
                                type: data.type,
                                id: data.id,
                                y
                            }))
                        })
                    }
                />
                <SelectPropertyInput<ComponentOrientations, "orientation">
                    keyValue={ComponentOrientationsToEngText}
                    propertyName="orientation"
                    label="Orientation"
                    objects={elements.filter(e => {
                        return e instanceof OrientableComponent
                    }) as OrientableComponent[]}
                    onValueChange={(orientation) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyOrientation({
                                type: data.type,
                                id: data.id,
                                orientation
                            }))
                        })
                    }
                />
            </InspectorBody>

            <InspectorFooter>

                <ButtonPropertyInput
                    onClick={() => {
                        dispatch(confirmDeleteModalOpen())
                    }}
                    color={"error"}
                    variant={"contained"}
                >
                    <span>Delete</span>
                </ButtonPropertyInput>
                <DefaultInspectorOptions />
            </InspectorFooter>
        </Fragment>

    )
} 