import { Fragment, useState, useEffect } from "react"
import { InspectorBody } from "./Layout/inspectorBody";
import { InspectorFooter } from "./Layout/inspectorFooter";
import { ColorPropertyInput } from "./Properties/ColorPropertyInput";
import axios from "axios";
import { DataPreviewType } from "../../types/Editor/dataPreview.types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFloorPlanSetting } from "../../store/reducers/FloorPlan/FloorPlan";
import { IconButton } from "@mui/material"
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { PropertyInput } from "./Properties/PropertyInput";
import DataPreviewGrid from "./Components/dataPreviewGrid";

type FloorDataPreviewType = DataPreviewType & { defaultTint: string }

function floorTextureRawToData(floorTextureRaw: any) {
    const data: FloorDataPreviewType = {
        id: floorTextureRaw.id,
        previewImageURL: floorTextureRaw.preview_url || floorTextureRaw.color_url,
        altText: floorTextureRaw.name,
        type: floorTextureRaw.type,
        tint: floorTextureRaw.default_color || "#ffffff",
        defaultTint: floorTextureRaw.default_color
    }
    return data
}

export function SelectFloorInspector() {
    const [texturesAvaliable, setTexturesAvaliable] = useState<FloorDataPreviewType[]>([])
    const floorPlanSettings = useAppSelector((state) => state.floorPlan.floorPlanSettings)
    const dispatch = useAppDispatch()
    const [lockColor, setLockColor] = useState(false)

    useEffect(() => {
        setTexturesAvaliable((t) => t.map((v) => {
            if (v.id === floorPlanSettings?.floorTextureId)
                v.tint = floorPlanSettings?.floorColor
            else
                v.tint = v.defaultTint
            return v
        }))
    }, [floorPlanSettings?.floorColor, floorPlanSettings?.floorTextureId])

    useEffect(() => {
        axios.get('/floor-textures')
            .then((response) => {
                const texturesAvaliable = response.data?.data.map(
                    (a: any) => floorTextureRawToData(a)
                )
                if (texturesAvaliable)
                    setTexturesAvaliable(texturesAvaliable)
            })
    }, [])

    if (!floorPlanSettings)
        return <div></div>
    return (
        <Fragment>
            <InspectorBody>
                <DataPreviewGrid
                    data={texturesAvaliable}
                    selectedData={{ [floorPlanSettings.floorTextureId]: true }}
                    onClickData={(data) => {
                        dispatch(setFloorPlanSetting({ "floorTextureId": data.id }))
                        if (!lockColor) {
                            const floorData = data as FloorDataPreviewType
                            dispatch(setFloorPlanSetting({ floorColor: floorData.defaultTint }))
                        }
                    }}
                    canZoom={true}
                />
            </InspectorBody>
            <InspectorFooter>
                <PropertyInput
                    propertyName="altText"
                    label="Name"
                    //@ts-ignore
                    objects={texturesAvaliable.filter(t => t.id === floorPlanSettings.floorTextureId)}
                    disabled
                />
                <ColorPropertyInput
                    objects={[floorPlanSettings]}
                    onValueChange={(floorColor) => {
                        dispatch(setFloorPlanSetting({ floorColor }))
                    }}
                    propertyName="floorColor"
                    endAdornment={(
                        <IconButton size="small" onClick={() => setLockColor(!lockColor)} sx={{ p: 0 }}>
                            {lockColor ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small" />}
                        </IconButton>
                    )}
                />
            </InspectorFooter>
        </Fragment>
    )
}