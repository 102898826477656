// import * as PIXI from 'pixi.js'
import { FloorPlanEditorSetting } from '../../../../types/FloorPlan/FloorPlan.types';
import { FloorPlanElement } from '../components/floorPlanElement';
import { BgLayer } from '../layers/bgLayer';
import { MainLayer } from '../layers/mainLayer';
import { TopLayer } from '../layers/topLayer';

import { FloorPlanApp } from '../floorPlanApp';
import { CanvasBase } from './canvasBase';
import { InteractionEvent } from 'pixi.js';

export class FloorPlanCanvas extends CanvasBase {
    app: FloorPlanApp

    // basic layers
    bgLayer: BgLayer = new BgLayer()
    mainLayer: MainLayer = new MainLayer()
    topLayer: TopLayer = new TopLayer()

    m2: number

    constructor() {
        super()

        this.m2 = 10000

        // add basic containers to stage
        this.addChild(this.bgLayer)
        this.addChild(this.mainLayer)
        this.addChild(this.topLayer)

        this.app = FloorPlanApp.getInstance()
        this.app.floorPlanCanvas = this

        this.bgLayer.createBgPoints()
    }

    destroy() {
        super.destroy(true)
    }

    addElement(element: FloorPlanElement) {
        this.mainLayer.addElement(element)
    }

    // Resize Screen
    onWindowResize() {
        this.mainLayer.onWindowResize()
        this.bgLayer.onWindowResize()
    }

    move(dx: number, dy: number) {
        super.move(dx, dy)
        this.bgLayer.handleMove()
    }

    moveTo(x: number, y: number) {
        super.moveTo(x, y)
        this.bgLayer.handleMove()
    }

    centerFloorPlan() {
        const bounds = this.mainLayer.getBounds()


        const { width: viewWidth, height: viewHeight } = this.app.view

        var newScale: number
        if (bounds.width > bounds.height)
            newScale = 0.9 * viewWidth / bounds.width
        else
            newScale = 0.9 * viewHeight / bounds.height

        if (newScale > 1)
            newScale = 1

        this.scale.set(newScale, newScale)

        const newBounds = this.mainLayer.getBounds()

        const heightOffset = (viewHeight - newBounds.height) / 2
        const widthOffset = (viewWidth - newBounds.width) / 2

        this.moveTo(-newBounds.left + widthOffset, -newBounds.top + heightOffset)

        this.app.onWindowResize()
    }

    // elements visibility
    setVisible(setting: FloorPlanEditorSetting, visible: boolean) {
        this.mainLayer.setVisible(setting, visible)
    }

    onDragMove(e: InteractionEvent): void {
        // if inside
        const m2_2 = this.m2 / 2
        const localPos = e.data.getLocalPosition(this.app.floorPlanCanvas)
        // console.log(e, localPos.x, localPos.y, this.m2, m2_2)
        if (localPos.x > -m2_2 && localPos.x < m2_2 &&
            localPos.y > -m2_2 && localPos.y < m2_2)
            super.onDragMove(e)
        else {
            // TODO
            // console.log("Licence limit")
        }

    }
} 