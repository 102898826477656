import clsx from "clsx";
import { useEffect, useState } from "react";
import FullScreenPage from "./FullScreenPage";

export function LoadingPage({ loadingPr, text, displayNone }: { loadingPr: number, text?: string, displayNone?: boolean }) {
    const [hide, setHide] = useState(false)
    useEffect(() => {
        const timer = setTimeout(() => {
            displayNone && setHide(true)
        }, 1000);
        return () => clearTimeout(timer);
    }, [displayNone]);

    return <div className={clsx({ "full-screen-invisible": displayNone, "displayNone": hide })}>
        <FullScreenPage title="Loading..." >
            {text || <br />}
            <div className="progress-bar">
                <div className="progress-bar-full" style={{ width: loadingPr + "%" }}>{loadingPr}%</div>
            </div>
        </FullScreenPage>
    </div >
}