import { Box } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { changeSculptureFileFormat, selectSculputreArtworks } from "../../store/reducers/Artworks";
import { ArtworkData } from "../../types/FloorPlan/Artwork.types";
import { SculptureFileFormat, SculptureFileFormatToEngText } from "../../types/FloorPlan/Sculpture.types";
import { SculptureButtonInput } from "./Components/sculptureButtonInput";
import { InspectorBody } from "./Layout/inspectorBody";
import { InspectorFooter } from "./Layout/inspectorFooter";
import { SelectPropertyInput } from "./Properties/SelectPropertyInput";

const imageExamplePreview: { [key in SculptureFileFormat]: string } = {
    BoxImage: "https://d22j8f47u6lqy6.cloudfront.net/gifs/e260991e11e28cfd1242e31fe837cf85.gif",
    CrossImage: "https://d22j8f47u6lqy6.cloudfront.net/gifs/1fae88bcebf52f1c3ae4a4edd8ee4275.gif",
    FlatImage: "https://d22j8f47u6lqy6.cloudfront.net/gifs/61a4f783d779418c49689564c3920e97.gif",
    Sculpture: "https://d22j8f47u6lqy6.cloudfront.net/gifs/0f240be2283ca3c4042afea82319e3f8.gif"
}

const transparentBgRecomendation = "Image with transparent background is recomended"

const infoText: { [key in SculptureFileFormat]: string } = {
    BoxImage: transparentBgRecomendation,
    CrossImage: transparentBgRecomendation,
    FlatImage: transparentBgRecomendation,
    Sculpture: "Sculpture must be .fbx format"
}

export function AdvancedSculptureSettingsInspector() {
    const allSculptures = useAppSelector(selectSculputreArtworks)
    const dispatch = useAppDispatch()
    const selectedData = useAppSelector(state => state.editor.selectedData)

    const [selectedSculptures, setSelectedSculpturesculptures] = useState<ArtworkData[]>([])


    useEffect(() => {
        if (allSculptures) {
            const newSculptures: ArtworkData[] = []
            // console.log("Selected data: ", selectedData, newSculptures)
            selectedData.filter((e) => e.type === "Sculpture").forEach(element => {
                const sculpture = allSculptures[element.id]
                if (sculpture)
                    newSculptures.push(sculpture)
                else
                    console.warn(element.id + " not found as a sculpure")
            })
            setSelectedSculpturesculptures(newSculptures)
        }
    }, [allSculptures, selectedData])

    // console.log("selectedSculptures", allSculptures, selectedSculptures)

    return (
        <Fragment>
            <InspectorBody>
                <SelectPropertyInput<SculptureFileFormat, "sculptureFormat">
                    keyValue={SculptureFileFormatToEngText}
                    propertyName="sculptureFormat"
                    label="Select file format"
                    objects={Object.values(selectedSculptures)}
                    onValueChange={(sculptureFormat) => {
                        for (let i = 0; i < selectedSculptures.length; i++) {
                            const element = selectedSculptures[i];
                            if (element?.uuid) {
                                dispatch(changeSculptureFileFormat({ sculptureFormat, artworkUuid: element?.uuid }))
                            }
                        }
                    }}
                    large={true}
                />
                <Box mt={1}></Box>
                <p className="warn-text">Changes will effect the sculpture in all your exhibitions</p>
                <Box mt={1}></Box>
                <h2>Preview:</h2>

                <Box mt={1}></Box>
                <img className="full-image" src={imageExamplePreview[selectedSculptures[0]?.sculptureFormat || "Sculpture"]} alt="Sculpture preview" />
                <p className="info-text">{selectedSculptures[0]?.sculptureFormat && infoText[selectedSculptures[0]?.sculptureFormat]}</p>

            </InspectorBody>
            <InspectorFooter>
                <SculptureButtonInput />
            </InspectorFooter>
        </Fragment>
    )
}