import { useAppDispatch, useAppSelector } from "../../../hooks"
import { InspectorOption, InspectorOptions } from "./inpectorOptions"
import RightAngleIcon from './../../../assets/img/right-angle-icon.svg'
import RulerIcon from './../../../assets/img/icon-ruler.svg'
import SnapToGroidIcon from './../../../assets/img/icon-snap-to-grid.svg'
import ZoomInIcon from './../../../assets/img/icon-zoom-in.svg'
import ZoomOutIcon from './../../../assets/img/icon-zoom-out.svg'
import { FloorPlanApp } from "../../Content/pixi/floorPlanApp";
import { toggleEditorSetting } from "../../../store/reducers/Editor"

export function DefaultInspectorOptions() {
    const editorSettings = useAppSelector(state => state.editor.editorSettings)
    const dispatch = useAppDispatch()

    return (
        <InspectorOptions options={
            [
                {
                    selected: editorSettings.lockDegrees,
                    title: "Lock rotation (Shift)",
                    iconSrc: RightAngleIcon,
                    onClick: () => {
                        dispatch(toggleEditorSetting('lockDegrees'))
                    }
                }, {
                    selected: editorSettings.snapToGrid,
                    title: "Snap to grid",
                    iconSrc: SnapToGroidIcon,
                    onClick: () => {
                        dispatch(toggleEditorSetting('snapToGrid'))
                    }
                }, {
                    selected: editorSettings.showRuler,
                    title: "Show Ruler",
                    iconSrc: RulerIcon,
                    onClick: () => {
                        dispatch(toggleEditorSetting('showRuler'))
                    }
                }, {
                    selected: false,
                    title: "Zoom Out",
                    iconSrc: ZoomOutIcon,
                    onClick: () => {
                        FloorPlanApp.getInstance()?.zoomOut()
                    }
                }, {
                    selected: false,
                    title: "Zoom In",
                    iconSrc: ZoomInIcon,
                    onClick: () => {
                        FloorPlanApp.getInstance()?.zoomIn()
                    }
                }
            ]
        } />
    )
}

export function BaseInspectorOptions({ options }: { options: InspectorOption[] }) {
    const editorSettings = useAppSelector(state => state.editor.editorSettings)
    const dispatch = useAppDispatch()

    return (
        <InspectorOptions options={
            [
                ...options,
                {
                    selected: editorSettings.showRuler,
                    title: "Show Ruler",
                    iconSrc: RulerIcon,
                    onClick: () => {
                        dispatch(toggleEditorSetting('showRuler'))
                    }
                }, {
                    selected: false,
                    title: "Zoom Out",
                    iconSrc: ZoomOutIcon,
                    onClick: () => {
                        FloorPlanApp.getInstance()?.zoomOut()
                    }
                }, {
                    selected: false,
                    title: "Zoom In",
                    iconSrc: ZoomInIcon,
                    onClick: () => {
                        FloorPlanApp.getInstance()?.zoomIn()
                    }
                }
            ]
        } />
    )
}