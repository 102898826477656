import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers'
import rootSaga from './sagas'
import { configureStore } from '@reduxjs/toolkit'

const sagaMiddleware = createSagaMiddleware()

const initializeStore = () => {
  // Creates middleware (with logger)
  let middleware = [sagaMiddleware]
  if (process.env.LOGGER === 'true') {
    // Logger
    const reduxLogger = require('redux-logger')
    const logger = reduxLogger.createLogger({
      collapsed: true,
      diff: false
    });
    middleware = [...middleware, logger]
  }

  const store = configureStore({
    reducer: rootReducer,
    middleware: middleware
  })

  sagaMiddleware.run(rootSaga)

  return store
};

export default initializeStore