import { doLinesIntersect } from "../../utils/MathCollisions"
import { OrientableComponent, orientationToRad } from "./OrientableComponent.types"

export enum LightTypesEnum {
  SPOTLIGHT = 'SPOTLIGHT',
  AMBIENT = 'AMBIENT'
}
/* 
export class LightData {
  name: string
  type: LightTypesEnum
  intensity: number
  color: string
  openingAngle: number

  constructor() {
    this.name = ''
    this.type = LightTypesEnum.SPOTLIGHT
    this.intensity = 1
    this.color = '#ffffff'
    this.openingAngle = 90
  }
} */

const timeToCheckLightMs = 100
const intensityDistanceMultiplier = 200

export class Light extends OrientableComponent {
  uuid: string | null
  type: LightTypesEnum
  intensity: number
  color: string
  name: string
  openingAngle: number
  blurryHaloPr: number // de 0 a 100: 0 muy marcado 100 muy difuminado
  verticalAngle: number //  de 0 a 90: 0 mirando a la pared 90 mirando al suelo
  warning: boolean
  warningText: string
  private _lightChecked: number = Date.now()

  constructor(mapRefUUID?: string) {
    super(mapRefUUID)

    this.name = "Light "
    this.type = LightTypesEnum.SPOTLIGHT

    this.intensity = 0.6
    this.color = '#ffffff'
    this.openingAngle = 70
    this.blurryHaloPr = 50
    this.verticalAngle = 45
    this.warning = false
    this.warningText = "This light is too close to a wall"

    this.uuid = null
  }

  checkIfItsTooCloseToWallsAndUpdateWarning(wallsPositions: { x1: number, y1: number, x2: number, y2: number }[]) {
    if (this._lightChecked > Date.now() - timeToCheckLightMs)
      return
    this._lightChecked = Date.now()

    this.warning = false

    for (let i = 0; i < wallsPositions.length; i++) {
      const wall = wallsPositions[i];
      if (this.checkIfItsTooCloseToWall(wall))
        this.warning = true
    }

    // console.log("checkIfItsTooCloseToWallsAndUpdateWarning", this.warning)
  }

  checkIfItsTooCloseToWall(wallPositions: { x1: number, y1: number, x2: number, y2: number }) {
    // Calculate the closest point on the line segment to the circle center
    const { x1: wallX1, y1: wallY1, x2: wallX2, y2: wallY2 } = wallPositions
    const distance = this.intensity * intensityDistanceMultiplier

    // if (!CircleLineCollision(this.x, this.y, distance, wallX1, wallY1, wallX2, wallY2))
    // return false

    const angle = orientationToRad(this.orientation)
    const lineX = this.x + Math.sin(angle) * distance
    const lineY = this.y - Math.cos(angle) * distance

    return doLinesIntersect(wallX1, wallY1, wallX2, wallY2, this.x, this.y, lineX, lineY)

    // uncomment this code for more presision but less performance 
    // it checks with the sides as well

    // const angle1 = angle + (this.openingAngle / 360) * Math.PI
    // const angle2 = angle - (this.openingAngle / 360) * Math.PI

    // const line2X = this.x + Math.sin(angle1) * distance
    // const line2Y = this.y - Math.cos(angle1) * distance

    // const line3X = this.x + Math.sin(angle2) * distance
    // const line3Y = this.y - Math.cos(angle2) * distance

    // return doLinesIntersect(wallX1, wallY1, wallX2, wallY2, this.x, this.y, lineX, lineY) ||
    //   doLinesIntersect(wallX1, wallY1, wallX2, wallY2, this.x, this.y, line2X, line2Y) ||
    //   doLinesIntersect(wallX1, wallY1, wallX2, wallY2, this.x, this.y, line3X, line3Y)
  }


}
