// import * as PIXI from 'pixi.js'
import { InteractionEvent } from "pixi.js";
// import { wallZoomTo } from '../../../exhibify-dnd/libraries/wall';
import { FloorPlanElement } from "../components/floorPlanElement";
import { VisiblePoint } from '../components/visiblePoint';
import { WallElement } from "../components/wallElement";
import FloorPlanConnector from '../floorPlanConnector';
import { Tool, roundGapSize } from "./tool"

type WallDrawState = 'Wall' | 'WallOrigin' | 'WallDestiny'

export class DrawWallsTool extends Tool {
    // _drawingContainer: PIXI.Container
    _state: WallDrawState
    _drawingWall?: WallElement

    constructor() {
        super()
        this.toolType = 'DrawWalls'
        this._state = 'Wall'
        // this._drawingContainer = this.app.floorPlanCanvas.topLayer
    }

    // interaction    
    onClick(e: InteractionEvent, element?: FloorPlanElement | VisiblePoint): void {
        super.onClick(e, element)
        // console.log("Clicka", this._state, this._draggingMode, !!this._drawingWall)

        if (this._draggingMode === 'option' && this._drawingWall) {
            // this.stopDrawing()
            this._state = 'Wall'
            return
        }

        if (!this._drawingWall)
            return

        switch (this._state) {
            case "Wall":
                this._state = "WallDestiny"
                break
            case "WallDestiny":
                this.app.addElement(this._drawingWall)
                this._drawingWall.unselect()
                FloorPlanConnector.elementPlaced("Wall", this._drawingWall.mapRefUUID)
                // this._state = "WallOrigin"
                break/* 
            case "WallOrigin":
                this.app.addElement(this._drawingWall)
                FloorPlanConnector.elementPlaced("Wall", this._drawingWall.mapRefUUID)
                this._state = "WallDestiny"
                break */
        }

        // console.log("this.app.selectedElements", this.app.selectedElements)

        /* 
        if (this._draggingMode === 'option' && this._drawingWall) {
            this.stopDrawing()
            /* // cancel line, but keep it
            this.app.addElement(this._drawingWall) 
            this._drawingWall = undefined
            this._state = 'ready' 
        }

        if (this._draggingMode !== 'tool')
            return

        const interactionPosition = e.data.getLocalPosition(this.app.floorPlanCanvas)
        switch (this._state) {
            case 'ready':
                this.startDrawing(interactionPosition.x, interactionPosition.y)
                break
            case 'drawing':
                if (this._state === 'drawing' && this._drawingWall) {
                    if (this._drawingWall.wallWidth > WallElement.MINIMUM_WIDTH) {
                        this.app.addElement(this._drawingWall)
                        this.startDrawing(
                            this._drawingWall.end.x,
                            this._drawingWall.end.y,
                            interactionPosition.x,
                            interactionPosition.y
                        )
                    } else {
                        this.stopDrawing()
                    }
                }
                break
        } */
    }


    onDragMove(e: InteractionEvent) {
        if (!this._drawingWall)
            return

        // super.onDragMove(e)
        /*    if (this._draggingMode === 'canvas')
               return */

        const interactionPoint = e.data.getLocalPosition(this.app.floorPlanCanvas)

        if (this.app.snapToGrid) {
            interactionPoint.x = Math.round((interactionPoint.x) / roundGapSize) * roundGapSize
            interactionPoint.y = Math.round((interactionPoint.y) / roundGapSize) * roundGapSize
        }
        FloorPlanConnector.moveElementTo(
            interactionPoint.x, interactionPoint.y, this._drawingWall.mapRefUUID, "WallDestiny")

        if (this._state === "Wall")
            FloorPlanConnector.moveElementTo(
                interactionPoint.x, interactionPoint.y, this._drawingWall.mapRefUUID, "WallOrigin")

        // this._drawingWall.moveTo(interactionPoint.x, interactionPoint.y)
        /* 
                if (this._drawingWall && this._state === 'drawing') {
                    const interactionPoint = e.data.getLocalPosition(this.app.floorPlanCanvas)
        
                    this._drawingWall.end.moveTo(interactionPoint.x, interactionPoint.y)
        
                    this.interactionStartPoint = interactionPoint
                } */

    }

    onDragEnd(e: InteractionEvent) {
        super.onDragEnd(e)
    }

    startDrawing(newWallElement: WallElement) {/* 
        const newWall = new Wall()
        newWall.origin.x = startX
        newWall.origin.y = startY
        newWall.destiny.x = endX || startX
        newWall.destiny.y = endY || startY

        newWall.height = this.app.floorPlanSettings.wallHeight
        newWall.color = this.app.floorPlanSettings.wallsDefaultColor
        newWall.textureURL = this.app.floorPlanSettings.wallsDefaultTextureURL
        newWall.hasTexture = this.app.floorPlanSettings.wallsHaveDefaultTexture
        newWall.thickness = 10 */

        // const newWallElement = new WallElement(newWall)
        this._drawingWall = newWallElement
        // this._drawingContainer.addChild(newWallElement)
        this._state = 'Wall'
    }

    stopDrawing() {
        if (this._drawingWall) {
            this.app.removeElement(this._drawingWall)
            this._state = 'Wall'

            // FloorPlanApp.notify('canvas-change')
        }
    }
    /* 
        handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape')
                this.stopDrawing()
        } */
    onWindowResize() {
        this._drawingWall?.onWindowResize()
    }

    enable() {
        this._state = 'Wall'
        this.app.floorPlanCanvas.cursor = 'draw'
    }

    dissable() {
        this.stopDrawing()
        this.app.floorPlanCanvas.cursor = 'default'
    }
}