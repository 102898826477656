import { useEffect, useState } from "react";
import CheckboxUncheckedIcon from "../../../assets/img/icon-checkbox-unchecked.svg";
import CheckboxCheckedIcon from "../../../assets/img/icon-checkbox-checked.svg";
import CheckboxMultipleIcon from "../../../assets/img/icon-checkbox-multiple.svg";
import { Checkbox } from "@mui/material";
import { BasePropertyInput } from "./BasePropertyInput";
import { PropertyInputLabel } from "./PropertyInputLabel";


export function BooleanPropertyInput<K extends string>(
    {
        propertyName,
        label,
        objects,
        onValueChange,
        disabled,
        error,
        info
    }: {
        propertyName: K,
        label: string,
        objects: { [key in typeof propertyName]: boolean }[],
        onValueChange: (a: boolean) => void,
        disabled?: boolean,
        error?: boolean,
        info?: React.ReactNode
    }) {

    const [val, setVal] = useState<boolean | "">("")

    useEffect(() => {
        setVal(objects.reduce<boolean | "">((pr, cr) => pr === cr[propertyName] ? pr : "", objects[0] ? objects[0][propertyName] : ""))
    }, [objects, propertyName])

    return (
        <BasePropertyInput disabled={disabled} error={error}>
            <PropertyInputLabel content={info}>{label}</PropertyInputLabel>
            <Checkbox
                checked={val === true}
                onChange={() => {
                    setVal(!val)
                    onValueChange(!val)
                }}
                indeterminate={val === ""}
                name="apply-to-all-walls"
                color="primary"
                indeterminateIcon={<img src={CheckboxMultipleIcon} alt='icon-unchecked' />}
                icon={<img src={CheckboxUncheckedIcon} alt='icon-unchecked' />}
                checkedIcon={<img src={CheckboxCheckedIcon} alt='icon-checked' />}
                disabled={disabled}
            />
        </BasePropertyInput>
    )
}