// import * as PIXI from 'pixi.js'
import { useEffect, useRef, useState, Dispatch, SetStateAction } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { EntranceElement } from "./pixi/components/orientableFloorPlanElements/entranceElement";
import { LightElement } from "./pixi/components/orientableFloorPlanElements/lightElement";
import { SpotElement } from "./pixi/components/orientableFloorPlanElements/spotElement";
import { WallElement } from "./pixi/components/wallElement";
import { FloorPlanApp } from "./pixi/floorPlanApp";
import { KeyboardManager } from "./pixi/managers/keyboardManager";
import { duplicateSelection, setSelectData, setTool, setToolDetails } from "../../store/reducers/Editor";
import { setFloorPlanSetting, setPropertyOrientation, setPropertyPlaced, setPropertySculptureError, setPropertyX, setPropertyY, tryDeleteData } from "../../store/reducers/FloorPlan/FloorPlan";
import { SelectedData } from "../../types/Editor.types";
import { CanvasEvent, FloorPlanDataType, FloorPlanEditorSetting } from "../../types/FloorPlan/FloorPlan.types";
import { ComponentOrientations } from "../../types/FloorPlan/OrientableComponent.types";
import { WallSide } from "../../types/FloorPlan/Wall.types";
import { SpotTypesEnum } from "../../types/FloorPlan/Spot.types";
import { SculptureElement } from "./pixi/components/orientableFloorPlanElements/sculptureElement";
import { ArtworkElement } from "./pixi/components/artworkElement";
import { ToolType } from "../../types/Editor/Tool.types";

function addEvent(eventName: CanvasEvent, eventListener: (e: CustomEvent) => void) {
    document.addEventListener(eventName, eventListener as EventListener)
}
function removeEvent(eventName: CanvasEvent, eventListener: (e: CustomEvent) => void) {
    document.removeEventListener(eventName, eventListener as EventListener)
}

export default function FloorPlan({
    setFloorPlanApp: setFloorPlanAppCallback
}:
    { setFloorPlanApp?: Dispatch<SetStateAction<FloorPlanApp | undefined>> }) {

    const canvasRef = useRef<HTMLDivElement>(null)
    const floorPlanData = useAppSelector((state) => state.floorPlan.floorPlan)
    const artworkData = useAppSelector((state) => state.artworks)
    const floorPlanSettings = useAppSelector((state) => state.floorPlan.floorPlanSettings)
    const [dataImported, setDataImported] = useState(false)
    const selectedData = useAppSelector((state) => state.editor.selectedData)
    const licenceM2 = useAppSelector((state) => state.auth.licenceM2)

    const [floorPlanApp, setFloorPlanApp] = useState<FloorPlanApp>()

    const currentTool = useAppSelector((state) => state.editor.currentTool.type)
    const snapToGrid = useAppSelector((state) => state.editor.editorSettings.snapToGrid)
    const inspectorExpanded = useAppSelector(s => s.editor.editorSettings.inspectorExpanded)

    const selectedWall = useAppSelector(state => state.editor.currentTool.type === "PlaceArtworks" ? state.editor.currentTool.wallSelected : undefined)

    const dispatch = useAppDispatch()

    // Create floor plan
    useEffect(() => {
        if (!canvasRef.current)
            return

        new KeyboardManager()

        const fpm = new FloorPlanApp(canvasRef.current)
        setFloorPlanApp(fpm)
        setFloorPlanAppCallback && setFloorPlanAppCallback(fpm)

        // Events
        function _keyDown(e: KeyboardEvent) {
            if (!e.repeat) {
                if (e.key === "Escape")
                    dispatch(setTool("Select"))

                if (e.target) {
                    const target = e.target as HTMLElement
                    if (target.tagName && target.tagName === "BODY") {
                        if (e.key === "Delete" || e.key === "Backspace") {
                            dispatch(tryDeleteData())
                        }
                    }
                }
            }
        }


        function _selectData(e: CustomEvent<SelectedData[]>) {
            // console.log("_selectData", e.detail, e.detail[0] ? e.detail[0].id : "")
            dispatch(setSelectData(e.detail))
        }
        /* 
                function _moveData(e: CustomEvent<{
                    dx: number;
                    dy: number;
                    id: string;
                    type: FloorPlanDataType
                }>) {
                    switch (e.detail.type) {
                        case "Entrance":
                        case "Light":
                        case "Spot":
                        case "Sculpture":
                        case "Wall":
                        case "WallOrigin":
                        case "WallDestiny":
                        case "Artwork":
                            dispatch(updatePropertyPosition(e.detail))
                            break
                        default:
                            console.warn("moving data ", e.detail.type)
                    }
                } */
        function _moveDataTo(e: CustomEvent<{
            x: number
            y: number
            id: string
            type: FloorPlanDataType
        }>) {
            const { x, y, id, type } = e.detail
            switch (e.detail.type) {
                case "Entrance":
                case "Light":
                case "Spot":
                case "Sculpture":
                case "Wall":
                case "WallOrigin":
                case "WallDestiny":
                case "Artwork":
                    dispatch(setPropertyX({ id, type, x }))
                    dispatch(setPropertyY({ id, type, y }))
                    break
            }
        }

        function _setOrientation(e: CustomEvent<{
            orientation: ComponentOrientations;
            id: string;
            type: FloorPlanDataType
        }>) {
            switch (e.detail.type) {
                case "Entrance":
                case "Light":
                case "Spot":
                case "Sculpture":
                    dispatch(setPropertyOrientation(e.detail))
                    break
            }
        }

        function _selectWallSide(e: CustomEvent<{
            wallId: string, side: WallSide
        }>) {
            const { wallId, side } = e.detail

            dispatch(
                setSelectData([{ id: wallId, type: "Wall" }])
            )
            dispatch(
                setToolDetails({ sideSelected: side, wallSelected: wallId })
            )
        }

        function _onElementPlaced(e: CustomEvent<{
            type: FloorPlanDataType, id: string
        }>) {
            const { id, type } = e.detail
            dispatch(setPropertyPlaced({ id, type, placed: true }))

            dispatch(duplicateSelection())
        }

        function _setTool(e: CustomEvent<{
            tool: ToolType
        }>) {
            // console.log("Set tool", e.detail.tool)
            dispatch(setTool(e.detail.tool))
        }

        function _setEyeHeight(e: CustomEvent<{
            eyeLineHeight: number
        }>) {
            dispatch(setFloorPlanSetting({ eyeLineHeight: e.detail.eyeLineHeight }))
        }

        document.addEventListener("keydown", _keyDown)

        addEvent("set-selected-elements", _selectData)
        // addEvent("move-element", _moveData)
        addEvent("move-element-to", _moveDataTo)
        addEvent("update-property-orientation", _setOrientation)
        addEvent("select-wall-side", _selectWallSide)
        addEvent("element-placed", _onElementPlaced)
        addEvent("set-tool", _setTool)
        addEvent("set-eye-line-height", _setEyeHeight)

        return () => {
            document.removeEventListener("keydown", _keyDown)

            removeEvent("set-selected-elements", _selectData)
            // removeEvent("move-element", _moveData)
            removeEvent("move-element-to", _moveDataTo)
            removeEvent("update-property-orientation", _setOrientation)
            removeEvent("select-wall-side", _selectWallSide)
            removeEvent("element-placed", _onElementPlaced)
            removeEvent("set-tool", _setTool)
            removeEvent("set-eye-line-height", _setEyeHeight)
        }
    }, [dispatch, setFloorPlanAppCallback])

    // Import data
    useEffect(() => {
        if (floorPlanApp && floorPlanData && !dataImported) {
            // console.log("inporting data", floorPlanData)

            floorPlanApp.import(floorPlanData)
            floorPlanApp.setTool('Select', null, true)
            dispatch(setTool("Select"))


            floorPlanApp.setVisible('spotsVisible')
            floorPlanApp.setVisible('lightsVisible')
            floorPlanApp.setVisible('artworksVisible')
            floorPlanApp.setVisible('dimensionsVisible')

            setDataImported(true)
        }


    }, [floorPlanApp, floorPlanData, dataImported, dispatch])

    // on select
    useEffect(() => {
        if (!floorPlanApp)
            return

        // console.log("select change")

        selectedData.forEach(data => {
            switch (data.type) {
                case "Light":
                    const light = floorPlanData?.lights[data.id]
                    if (!light)
                        return

                    // get light element, if doesn't exist, create it
                    var lightElement: LightElement | undefined = floorPlanApp.floorPlanElements[data.id] as LightElement
                    if (!lightElement && light.deleted === false) {
                        lightElement = floorPlanApp.createNewElement("Light", data.id) as LightElement
                        if (!lightElement)
                            throw new Error("could not create new light")
                    }
                    if (light.deleted) {
                        floorPlanApp.removeElement(lightElement)
                        dispatch(setSelectData([]))
                        break
                    }

                    lightElement.openingAngle = light.openingAngle
                    lightElement.lightIntensity = light.intensity
                    lightElement.blurryHalo = light.blurryHaloPr
                    lightElement.lightColor = light.color
                    lightElement.showWarning = light.warning
                    lightElement.moveTo(light.x, light.y)
                    lightElement.setOrientation(light.orientation)
                    break
                case "Spot":
                    const spot = floorPlanData?.spots[data.id]
                    if (!spot)
                        return

                    // get spot element, if doesn't exist, create it
                    var spotElement: SpotElement | undefined = floorPlanApp.floorPlanElements[data.id] as SpotElement
                    if (!spotElement && spot.deleted === false) {
                        spotElement = floorPlanApp.createNewElement("Spot", data.id) as SpotElement
                        if (!spotElement)
                            throw new Error("could not create new spot")
                    }
                    if (spot.deleted) {
                        floorPlanApp.removeElement(spotElement)
                        dispatch(setSelectData([]))
                        break
                    }

                    spotElement.moveTo(spot.x, spot.y)
                    spotElement.setType(spot.type)
                    spotElement.setOrientation(spot.orientation)

                    if (spot.type === SpotTypesEnum.ARTWORK && selectedData.length === 1)
                        floorPlanApp.floorPlanCanvas.mainLayer.setSelectedArtwork(spot.artworkUUID)

                    break

                case "Sculpture":
                    const sculpture = floorPlanData?.sculptures[data.id]
                    if (!sculpture)
                        return

                    // get element, if doesn't exist, create it
                    var sculptureElement: SculptureElement | undefined = floorPlanApp.floorPlanElements[data.id] as SculptureElement
                    if (!sculptureElement && sculpture.deleted === false) {
                        if (artworkData.artworks[data.id].mediaType === "SCULPTURE") {
                            // console.log("floorPlanApp && artworkData", !!artwork.media?.error)
                            dispatch(setPropertySculptureError({ id: data.id, error: !!artworkData.artworks[data.id].media?.error, type: "Sculpture" }))
                        }

                        sculptureElement = floorPlanApp.createNewElement("Sculpture", data.id) as SculptureElement
                        if (!sculptureElement)
                            throw new Error("could not create new sculpture")
                    }

                    if (sculpture.deleted) {
                        floorPlanApp.removeElement(sculptureElement)
                        dispatch(setSelectData([]))
                        break
                    }


                    // console.log("selected sculpture element: ", sculptureElement)
                    sculptureElement.moveTo(sculpture.x, sculpture.y)
                    sculptureElement.setOrientation(sculpture.orientation)
                    sculptureElement.resizeSculpture(sculpture.dimensions.depth, sculpture.dimensions.width)

                    sculptureElement?.setError(sculpture.errorWithFileProvided)
                    // console.log("Error???", sculpture.errorWithFileProvided)

                    break
                case "Entrance":
                    const entrance = floorPlanData?.entrance
                    if (!entrance)
                        return
                    const entrnaceElement = floorPlanApp.floorPlanElements["entrance"] as EntranceElement
                    entrnaceElement?.moveTo(entrance.x, entrance.y)
                    entrnaceElement?.setOrientation(entrance.orientation)
                    break
                case "Wall":
                    const wall = floorPlanData?.walls[data.id]
                    if (!wall)
                        return

                    // get element, if doesn't exist, create it
                    var wallElement: WallElement | undefined = floorPlanApp.floorPlanElements[data.id] as WallElement
                    if (!wallElement && wall.deleted === false) {
                        wallElement = floorPlanApp.createNewElement("Wall", data.id) as WallElement
                        if (!wallElement)
                            throw new Error("could not create new Wall")
                    }

                    if (wall.deleted) {
                        floorPlanApp.removeElement(wallElement)
                        dispatch(setSelectData([]))
                        break
                    }

                    // wall.computePointsFromProperties()
                    wallElement.wallName = wall.name
                    wallElement.wallWidth = wall.width
                    wallElement.wallThickness = wall.thickness
                    wallElement.wallAngle = wall.angle
                    wallElement.region = wall.region
                    wallElement.start.moveTo(wall.origin.x, wall.origin.y)
                    wallElement.end.moveTo(wall.destiny.x, wall.destiny.y)


                    break
                case "Artwork":
                    const artwork = floorPlanData?.artworks[data.id]
                    if (!artwork)
                        return

                    // get element, if doesn't exist, create it
                    var artworkElement: ArtworkElement | undefined = floorPlanApp.floorPlanElements[data.id] as ArtworkElement
                    if (!artworkElement) {
                        artworkElement = floorPlanApp.createNewArtwork(artwork)

                        if (!artworkElement)
                            throw new Error("could not create new artwork")

                        artworkElement.artworkData = artwork
                        floorPlanApp.updateDndCanvas(data.id)
                        floorPlanApp.tools.PlaceArtworks.selectElement(artworkElement)
                    }
                    else {
                        if (artwork.deleted) {
                            // console.log("ARTWORK DELETED")
                            floorPlanApp.removeElement(artworkElement)
                            floorPlanApp.updateDndCanvas(data.id)
                            dispatch(setSelectData([{ id: "", type: "Wall" }]))
                            break
                        }

                        artworkElement.artworkData = artwork

                        floorPlanApp.updateDndCanvas(data.id)
                    }
                    break
                default:
                    console.warn(data.type + " selected but no effect.")
                    break
            }

        })
        floorPlanApp.onSelectChange(selectedData)

    }, [floorPlanApp, floorPlanData, selectedData, dispatch])

    // Change tool
    useEffect(() => {
        if (!floorPlanApp)
            return

        floorPlanApp.setTool(currentTool)

    }, [floorPlanApp, currentTool])

    const editorSettings = useAppSelector(state => state.editor.editorSettings)
    useEffect(() => {
        if (!floorPlanApp)
            return

        Object.entries(editorSettings).forEach(([k, v]) =>
            floorPlanApp.setVisible(k as FloorPlanEditorSetting, v)
        )

    }, [editorSettings, floorPlanApp])

    useEffect(() => {
        if (!floorPlanSettings) return
        if (!floorPlanApp) return
        // console.log("floorPlanSettings", floorPlanSettings)
        floorPlanApp.wallsHeight = floorPlanSettings.wallHeight
        floorPlanApp.eyeLineHeight = floorPlanSettings.eyeLineHeight
    }, [floorPlanSettings, floorPlanApp])

    useEffect(() => {
        if (licenceM2 === undefined) return
        if (!floorPlanApp) return

        if (licenceM2 <= 0)
            floorPlanApp.setM2(1000000)
        else
            floorPlanApp.setM2(licenceM2)
    }, [licenceM2, floorPlanApp])

    useEffect(() => {
        if (floorPlanApp && artworkData && dataImported) {
            Object.entries(artworkData.artworks).forEach(([artworkUid, artwork]) => {
                if (artwork.mediaType === "SCULPTURE") {
                    // console.log("floorPlanApp && artworkData", !!artwork.media?.error)

                    dispatch(setPropertySculptureError({ id: artworkUid, error: !!artwork.media?.error, type: "Sculpture" }))
                    const sc = floorPlanApp.floorPlanElements[artworkUid] as SculptureElement | undefined
                    if (sc?.setError)
                        sc?.setError(!!artwork.media?.error)
                }
            });

        }
    }, [floorPlanApp, artworkData, dispatch, dataImported])

    useEffect(() => {
        if (floorPlanApp)
            floorPlanApp.snapToGrid = snapToGrid
    }, [snapToGrid, floorPlanApp])

    useEffect(() => {
        floorPlanApp?.resizeRenderer();
    }, [inspectorExpanded, floorPlanApp])

    useEffect(() => {
        if (floorPlanApp && !selectedWall)
            floorPlanApp.tools.PlaceArtworks.unselectWall()
    }, [selectedWall, floorPlanApp])

    return (
        <div className="floor-plan" ref={canvasRef} />
    )
}