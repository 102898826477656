import { styled } from '@mui/system';
import { Button as MuiButton } from "@mui/material";

export const Button = styled(MuiButton)(({ theme, color = 'primary', variant = 'text' }) => ({
    boxShadow: "none",
    textTransform: "none",
    ':hover': {
        backgroundColor: theme.palette[color]?.hoverBg,
        boxShadow: "none"
    },
    color: variant !== "contained" && color === "primary" ? "#000" : ""
}));