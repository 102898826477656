import { Fragment } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { setFloorPlanSetting } from "../../store/reducers/FloorPlan/FloorPlan"
import { InspectorBody } from "./Layout/inspectorBody"
import { InspectorHeader } from "./Layout/inspectorHeader"
import { BooleanPropertyInput } from "./Properties/BooleanPropertyInput"
import { ColorPropertyInput } from "./Properties/ColorPropertyInput"
import { HeaderProperty } from "./Properties/HeaderProperty"
import { NumberPropertyInput } from "./Properties/NumberPropertyInput"
// import { SelectPropertyInput } from "./Properties/SelectPropertyInput"
import { ButtonPropertyInput } from "./Properties/ButtonPropertyInput"
import { confirmDeleteModalOpen } from "../../store/reducers/UI"
import { SubinspectorPropertyInput } from "./Properties/subinspectorPropertyInput"
import { SelectFloorInspector } from "./selectFloorInspector"


export function SettingsInspector() {
    const floorPlanSettings = useAppSelector((state) => state.floorPlan.floorPlanSettings)

    const dispatch = useAppDispatch()


    if (!floorPlanSettings)
        return <div></div>
    return (
        <Fragment>
            <InspectorHeader>Settings</InspectorHeader>

            <InspectorBody>
                <HeaderProperty
                    header="Floor"
                />
                <BooleanPropertyInput
                    label="Has Floor"
                    propertyName="hasFloor"
                    objects={[floorPlanSettings]}
                    onValueChange={(hasFloor) => {
                        dispatch(setFloorPlanSetting({ hasFloor }))
                    }}
                />
                {/* 
                <SelectPropertyInput<FloorTextures, "floorTextureID">
                    keyValue={FloorTexturesToEngText}
                    propertyName="floorTextureID"
                    label="Material"
                    objects={[floorPlanSettings]}
                    onValueChange={(floorTexture) => {
                        if (floorTexture === "NONE")
                            dispatch(setFloorPlanSetting({ floorTexture, floorHasTexture: false }))
                        else
                            dispatch(setFloorPlanSetting({ floorTexture, floorHasTexture: true }))
                    }}
                    disabled={!floorPlanSettings.hasFloor}
                /> */}
                <SubinspectorPropertyInput header="Floor texture" sx={{ paddingX: 0 }} headerSx={{paddingX: "24px"}} >
                    <SelectFloorInspector />
                </SubinspectorPropertyInput>

                <HeaderProperty
                    header="Ceil"
                />
                <BooleanPropertyInput
                    label="Has Ceil"
                    propertyName="hasCeil"
                    objects={[floorPlanSettings]}
                    onValueChange={(hasCeil) => {
                        dispatch(setFloorPlanSetting({ hasCeil }))
                    }}
                />
                <ColorPropertyInput
                    label="Ceil Color"
                    propertyName="ceilColor"
                    objects={[floorPlanSettings]}
                    onValueChange={(ceilColor) => {
                        dispatch(setFloorPlanSetting({ ceilColor }))
                    }}
                    disabled={!floorPlanSettings.hasCeil}
                />

                <HeaderProperty
                    header="Walls"
                />
                <NumberPropertyInput
                    label="Wall Height"
                    propertyName="wallHeight"
                    objects={[floorPlanSettings]}
                    onValueChange={(wallHeight) => {
                        dispatch(setFloorPlanSetting({ wallHeight }))
                    }}
                    endAdornment="cm"
                />
                <BooleanPropertyInput
                    label="Baseboard"
                    propertyName="wallsBaseboard"
                    objects={[floorPlanSettings]}
                    onValueChange={(wallsBaseboard) => {
                        dispatch(setFloorPlanSetting({ wallsBaseboard }))
                    }}
                />
                <HeaderProperty
                    header="Light"
                />
                <ColorPropertyInput
                    label="Ambient Light"
                    title="The base color of the scene. Darker color will need more lights."
                    propertyName="ambientLightColor"
                    objects={[floorPlanSettings]}
                    onValueChange={(ambientLightColor) => {
                        dispatch(setFloorPlanSetting({ ambientLightColor }))
                    }}
                />
                <HeaderProperty
                    header="Clear scene"
                />
                <ButtonPropertyInput
                    color="error"
                    variant="contained"
                    onClick={() => {
                        dispatch(confirmDeleteModalOpen("All"))
                    }}
                >
                    Clear scene
                </ButtonPropertyInput>
            </InspectorBody>
        </Fragment >
    )
}