import * as PIXI from 'pixi.js'
import { FloorPlanArtwork } from '../../../../types/FloorPlan/Artwork.types'
import { GuideLine } from './guideline'

class ArtworkGuideline extends GuideLine {
    private helperLine: PIXI.Graphics

    constructor() {
        super()

        this.helperLine = new PIXI.Graphics()
        this.helperLine
            .lineStyle(1, 0xd5d5d5)
            .lineTo(1, 0)
            .endFill()

        this.arrowEnd.addChild(this.helperLine)
    }

    set helperSize(size: number) {
        this.helperLine.width = size
    }
    get helperSize() {
        return this.helperLine.width
    }
}

export class ArtworkGuidelines extends PIXI.Container {
    wallWidth: number
    wallHeight: number

    guidelines

    constructor(wallWidth: number, wallHeight: number) {
        super()

        this.wallWidth = wallWidth
        this.wallHeight = wallHeight

        this.guidelines = {
            right: new ArtworkGuideline(),
            left: new ArtworkGuideline(),
            top: new ArtworkGuideline(),
            bot: new ArtworkGuideline(),
            rightLong: new ArtworkGuideline(),
            leftLong: new ArtworkGuideline(),
            topLong: new ArtworkGuideline(),
            botLong: new ArtworkGuideline(),
        }

        // center texts
        this.guidelines.right.text.pivot.y = -5
        this.guidelines.rightLong.text.pivot.y = 15
        this.guidelines.right.text.x =
            this.guidelines.rightLong.text.x = 0

        this.guidelines.left.text.pivot.y = - 10
        this.guidelines.leftLong.text.pivot.y = 10
        this.guidelines.left.text.angle =
            this.guidelines.leftLong.text.angle = 90

        this.guidelines.top.text.pivot.y = 5
        this.guidelines.top.text.pivot.x = -5
        this.guidelines.topLong.text.pivot.y = 5
        this.guidelines.topLong.text.pivot.x = 5
        this.guidelines.top.text.x =
            this.guidelines.topLong.text.x = 0

        this.guidelines.bot.text.pivot.y = 5
        this.guidelines.bot.text.pivot.x = -5
        this.guidelines.botLong.text.pivot.y = 5
        this.guidelines.botLong.text.pivot.x = 5
        this.guidelines.bot.text.x =
            this.guidelines.botLong.text.x = 0
        this.guidelines.botLong.y = this.wallHeight

        this.guidelines.right.text.angle = this.guidelines.rightLong.text.angle = 270

        this.guidelines.bot.text.angle = this.guidelines.botLong.text.angle = 180


        Object.values(this.guidelines).forEach((g) => {
            this.addChild(g)
        })

        this.guidelines.bot.rotation = this.guidelines.botLong.rotation = Math.PI

        this.guidelines.right.rotation = this.guidelines.rightLong.rotation = Math.PI / 2

        this.guidelines.left.rotation = this.guidelines.leftLong.rotation = 3 * Math.PI / 2

        this.setWallDimensions(wallWidth, wallHeight)
        this.visible = false
    }

    setWallDimensions(wallWidth: number, wallHeight: number) {
        this.wallWidth = wallWidth
        this.wallHeight = wallHeight

        this.guidelines.top.y = 0
        this.guidelines.left.x = 0
        this.guidelines.bot.y = wallHeight
        this.guidelines.right.x = wallWidth
    }

    update(artwork: FloorPlanArtwork,
        top: number,
        bot: number,
        right: number,
        left: number,
        topHelperSize?: number,
        botHelperSize?: number,
        rightHelperSize?: number,
        leftHelperSize?: number,
    ) {

        this.visible = true

        const artworkCenterY = artwork.y + artwork.fullHeight / 2
        const artworkCenterX = artwork.x + artwork.fullWidth / 2

        const leftLenght = artwork.x - right
        const leftVisible = this.guidelines.left.visible = this.guidelines.leftLong.visible = leftLenght > 0
        if (leftVisible) {
            this.guidelines.leftLong.y = artworkCenterY
            this.guidelines.leftLong.x = 0
            this.guidelines.leftLong.height = artwork.x

            this.guidelines.leftLong.text.text = artwork.x.toFixed(2)
            this.guidelines.leftLong.text.y -= this.guidelines.leftLong.text.width / 2

            if (artwork.x === leftLenght)
                this.guidelines.left.visible = false
            else {
                this.guidelines.left.visible = true

                this.guidelines.left.x = right
                this.guidelines.left.height = leftLenght
                this.guidelines.left.y = artworkCenterY + 10
                this.guidelines.left.helperSize = Number(rightHelperSize) - 10

                this.guidelines.left.text.text = leftLenght.toFixed(2)
                this.guidelines.left.text.y -= this.guidelines.left.text.width / 2
            }
        } else
            this.guidelines.left.helperSize = 0

        const rightLenght = left - artwork.x - artwork.fullWidth
        const rightVisible = this.guidelines.rightLong.visible = this.guidelines.right.visible = rightLenght > 0
        if (rightVisible) {

            this.guidelines.rightLong.y = artworkCenterY
            this.guidelines.rightLong.x = this.wallWidth
            this.guidelines.rightLong.height = this.wallWidth - artwork.x - artwork.fullWidth

            this.guidelines.rightLong.text.text = this.guidelines.rightLong.height.toFixed(2)
            this.guidelines.rightLong.text.y += this.guidelines.rightLong.text.width / 2

            if (this.guidelines.rightLong.height === rightLenght)
                this.guidelines.right.visible = false
            else {
                this.guidelines.right.visible = true

                this.guidelines.right.x = left
                this.guidelines.right.height = rightLenght
                this.guidelines.right.y = artworkCenterY + 10
                this.guidelines.right.helperSize = Number(leftHelperSize) + 10

                this.guidelines.right.text.text = rightLenght.toFixed(2)
                this.guidelines.right.text.y += this.guidelines.right.text.width / 2
            }

        } else
            this.guidelines.right.helperSize = 0

        const topLenght = artwork.y - top
        const topVisible = this.guidelines.topLong.visible = this.guidelines.top.visible = topLenght > 0
        if (topVisible) {
            this.guidelines.topLong.x = artworkCenterX
            this.guidelines.topLong.height = artwork.y
            this.guidelines.topLong.text.text = artwork.y.toFixed(2)
            this.guidelines.topLong.text.x = -this.guidelines.topLong.text.width

            if (this.guidelines.topLong.height === topLenght)
                this.guidelines.top.visible = false
            else {
                this.guidelines.top.y = top
                this.guidelines.top.height = topLenght
                this.guidelines.top.x = artworkCenterX + 10
                this.guidelines.top.helperSize = Number(topHelperSize) + 10

                this.guidelines.top.text.text = topLenght.toFixed(2)
            }
        } else
            this.guidelines.top.helperSize = 0


        const botLenght = bot - artwork.y - artwork.fullHeight
        const botVisible = this.guidelines.botLong.visible = this.guidelines.bot.visible = botLenght > 0
        if (botVisible) {


            this.guidelines.botLong.height = this.wallHeight - artwork.y - artwork.fullHeight
            this.guidelines.botLong.x = artworkCenterX

            this.guidelines.botLong.text.text = this.guidelines.botLong.height.toFixed(2)
            this.guidelines.botLong.text.x = this.guidelines.botLong.text.width


            if (this.guidelines.botLong.height === botLenght)
                this.guidelines.bot.visible = false
            else {
                this.guidelines.bot.visible = true

                this.guidelines.bot.y = bot
                this.guidelines.bot.height = botLenght
                this.guidelines.bot.x = artworkCenterX + 10
                this.guidelines.bot.helperSize = Number(botHelperSize) - 10

                this.guidelines.bot.text.text = botLenght.toFixed(2)
            }
        } else
            this.guidelines.bot.helperSize = 0

    }
}