import * as PIXI from 'pixi.js'
import { InteractionEvent } from "pixi.js";
import { FloorPlanElement } from "../components/floorPlanElement";
import { VisiblePoint } from "../components/visiblePoint";
import { WallExteriorPoint } from "../components/wallElement";
import { Tool, roundGapSize } from "./tool"
import FloorPlanConnector from "../floorPlanConnector";
import { KeyboardManager } from "../managers/keyboardManager";
import { SelectedData } from "../../../../types/Editor.types";
import { FloorPlanApp } from '../floorPlanApp';

class SquareSelect extends PIXI.Graphics {
    app: FloorPlanApp = FloorPlanApp.getInstance()
    anchor: { x: number, y: number }

    constructor(x: number, y: number) {
        super()
        this.x = x
        this.y = y
        this.anchor = { x: x, y: y }
    }

    setSquare(newX: number, newY: number) {
        // interactionPoint.x - this.squareSelect.x, interactionPoint.y - this.squareSelect.y
        this.clear()
            .beginFill(0x6666ff, .2)
            // .lineStyle(2, 0x6666ff)
            .drawRect(0, 0, Math.abs(newX - this.anchor.x), Math.abs(newY - this.anchor.y))
            .endFill()
        this.x = Math.min(this.anchor.x, newX)
        this.y = Math.min(this.anchor.y, newY)
    }

    checkElementsInside() {
        const elementsInside: FloorPlanElement[] = []
        const floorPlanElements = Object.values(this.app.floorPlanElements)
        for (let i = 0; i < floorPlanElements.length; i++) {
            const element = floorPlanElements[i]
            if (element.type === 'Artwork')
                continue
            const px = element.x
            const py = element.y
            if (px >= this.x &&         // right of the left edge AND
                px <= this.x + this.width &&    // left of the right edge AND
                py >= this.y &&         // below the top AND
                py <= this.y + this.height) {    // above the bottom
                elementsInside.push(element)
            }
        }
        // console.log(elementsInside)

        this.app.tools.Select.setSelectedElements(elementsInside)
    }
}

export class SelectTool extends Tool {
    selectedElementType?: "none" | "element" | "point"
    selectedPoint?: VisiblePoint
    selectedElementsInitialPosition: {
        [key: string]: {
            x: number,
            y: number
        }
    } = {}

    squareSelect?: SquareSelect

    canvasChanged: boolean = false

    constructor() {
        super()
        this.toolType = "Select"
    }

    // interaction    
    onClick(e: InteractionEvent, element?: FloorPlanElement | VisiblePoint): void {
        super.onClick(e, element)
        if (KeyboardManager.isKeyPressed("Shift") && element === undefined) {
            this.changeDraggingMode('option')
        }
        if (this._draggingMode === 'option') {
            const interactionPoint = e.data.getLocalPosition(this.app.floorPlanCanvas)
            this.app.floorPlanCanvas.topLayer.addChild(this.squareSelect = new SquareSelect(interactionPoint.x, interactionPoint.y))
        }
        if (this._draggingMode !== 'tool')
            return

        this.canvasChanged = false

        // if shift is pressed, multiple selection
        // when user clicks no element, unselect
        if (!element) {
            // console.log("selected element = none")
            this.selectedElementType = 'none'
        }
        else if (element instanceof FloorPlanElement) {
            this.selectedElementType = 'element'
            if (KeyboardManager.isKeyPressed("Shift")) {
                if (!element.selected) {
                    this.selectElement(element)
                } else {
                    this.unselectElement(element)
                }
            }
            else {
                if (this.app.selectedElements.length === 0) {
                    this.selectElement(element)
                }
                else if (this.app.selectedElements.length === 1) {
                    this.unselectAllElements()
                    this.selectElement(element)
                }
            }
        }
        else if (element instanceof WallExteriorPoint) {
            this.app.onClick(e, element.wall)
            this.selectedElementType = 'point'
            this.selectedPoint = element
        }

        this.selectedElementsInitialPosition = {}
        for (let i = 0; i < this.app.selectedElements.length; i++) {
            const element = this.app.selectedElements[i];
            this.selectedElementsInitialPosition[element.mapRefUUID] = {
                x: element.x,
                y: element.y
            }

        }
    }

    onDragMove(e: InteractionEvent) {
        if (this._draggingMode === 'tool' &&
            this.selectedElementType === 'none'
        ) {
            this.changeDraggingMode('canvas')
        }

        super.onDragMove(e)

        const interactionPoint = e.data.getLocalPosition(this.app.floorPlanCanvas)
        if (this._draggingMode === 'option' && this.squareSelect) {
            this.squareSelect.setSquare(interactionPoint.x, interactionPoint.y)
            this.squareSelect.checkElementsInside()
        }

        if (this._draggingMode !== 'tool')
            return

        if (this.app.snapToGrid) {
            interactionPoint.x = Math.round((interactionPoint.x) / roundGapSize) * roundGapSize
            interactionPoint.y = Math.round((interactionPoint.y) / roundGapSize) * roundGapSize
        }

        if (this.selectedElementType === 'element')
            this.app.selectedElements.forEach((element) => {
                const offsetInteractionPoint = {
                    x: interactionPoint.x - this.interactionStartPoint.x + this.selectedElementsInitialPosition[element.mapRefUUID].x || 0,
                    y: interactionPoint.y - this.interactionStartPoint.y + this.selectedElementsInitialPosition[element.mapRefUUID].y || 0
                }
                if (this.app.snapToGrid) {
                    offsetInteractionPoint.x = Math.round((offsetInteractionPoint.x) / roundGapSize) * roundGapSize
                    offsetInteractionPoint.y = Math.round((offsetInteractionPoint.y) / roundGapSize) * roundGapSize
                }

                // element.move(dx, dy)
                FloorPlanConnector.moveElementTo(offsetInteractionPoint.x, offsetInteractionPoint.y, element.mapRefUUID, element.type)
            })
        else if (this.selectedElementType === 'point' && this.selectedPoint) {
            if (this.selectedPoint instanceof WallExteriorPoint)
                FloorPlanConnector.moveElementTo(
                    interactionPoint.x, interactionPoint.y,
                    this.selectedPoint.wall.mapRefUUID,
                    this.selectedPoint.exteriorPointType === "start" ? "WallOrigin" : "WallDestiny"
                )
        }

        this.canvasChanged = true
    }

    onDragEnd(e: InteractionEvent) {
        if (this._draggingMode === 'option') {
            this.squareSelect?.parent.removeChild(this.squareSelect)
            this.squareSelect?.destroy()
        }
        if (this._draggingMode === 'tool' && this.selectedElementType === 'none') {
            this.unselectAllElements()
            FloorPlanConnector.setSelectedElements([])
        }

        super.onDragEnd(e)


        // console.log("select drag end, canvas changed?", this.canvasChanged)
        if (this.canvasChanged) {
            // FloorPlanApp.notify('canvas-change')
            this.canvasChanged = false
        }
    }


    enable() {
        Object.values(this.app.floorPlanElements).forEach(element => {
            if (element)
                element.setInteractive(true)
        });
    }

    dissable() {
        this.unselectAllElements()
        /* Object.values(this.app.floorPlanElements).forEach(element => {
            element.setInteractive(false)
            if (element.selected)
                this.unselectElement(element)
        }); */
    }

    // Element Actions
    selectElement(element: FloorPlanElement) {
        element.select()
        // console.log("selected", element.parent, element.parent.parent)

        this.app.floorPlanCanvas.mainLayer.selectedElements.addChild(element)
        this.app.selectedElements = [...this.app.selectedElements, element]

        FloorPlanConnector.setSelectedElements(this.app.selectedElements)

    }

    setSelectedElements(elements: FloorPlanElement[]) {
        if (elements.map(a => a.mapRefUUID).toString() === this.app.selectedElements.map(a => a.mapRefUUID).toString())
            return
        // console.log("change selected elements")

        this.unselectAllElements()

        elements.forEach(element => {
            element.select()
            this.app.floorPlanCanvas.mainLayer.selectedElements.addChild(element)
        })
        this.app.selectedElements = elements

        FloorPlanConnector.setSelectedElements(this.app.selectedElements)
    }

    unselectElement(element: FloorPlanElement, notify: boolean = true) {
        if (!element || element === null)
            return

        element.unselect()
        this.app.selectedElements = this.app.selectedElements.filter((e) => e !== element)
        this.app.floorPlanCanvas.mainLayer.addElement(element)
        if (notify)
            FloorPlanConnector.setSelectedElements(this.app.selectedElements)

    }

    unselectAllElements() {
        // console.log("unselecting all elements")
        this.app.selectedElements.forEach(element => {
            this.unselectElement(element, false)
        })
        this.app.selectedElements = []
    }

    onSelectChange(selectedData: SelectedData[]): void {
        // console.log("on select change")
        if (selectedData.length === 0) {
            // console.log("on select change unselectAllElements")
            this.unselectAllElements()
        }
    }
}