import { useEffect, useState } from "react";
import CheckboxUncheckedIcon from "../../../assets/img/icon-checkbox-unchecked.svg";
import CheckboxCheckedIcon from "../../../assets/img/icon-checkbox-checked.svg";
import CheckboxMultipleIcon from "../../../assets/img/icon-checkbox-multiple.svg";
import { Checkbox } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { BasePropertyInput } from "./BasePropertyInput";


export function AccordionBooleanPropertyInput<K extends string>(
    {
        propertyName,
        label,
        objects,
        onValueChange,
        children
    }: {
        propertyName: K,
        label: string,
        objects: { [key in typeof propertyName]: boolean }[],
        onValueChange: (a: boolean) => void,
        children?: React.ReactNode | undefined,
    }) {


    const [val, setVal] = useState<boolean | "">("")

    useEffect(() => {
        setVal(objects.reduce<boolean | "">((pr, cr) => pr === cr[propertyName] ? pr : "", objects[0] ? objects[0][propertyName] : ""))
    }, [objects, propertyName])

    return (
        <BasePropertyInput inputBox={false}>
            <Accordion
                expanded={val === true}
                onChange={() => onValueChange(!val)}
                elevation={0}
            >
                <AccordionSummary>
                    <label>{label}</label>
                    <Checkbox
                        checked={val === true}
                        onChange={() => {
                            setVal(!val)
                            onValueChange(!val)
                        }}
                        indeterminate={val === ""}
                        name="apply-to-all-walls"
                        color="primary"
                        indeterminateIcon={<img src={CheckboxMultipleIcon} alt='icon-unchecked' />}
                        icon={<img src={CheckboxUncheckedIcon} alt='icon-unchecked' />}
                        checkedIcon={<img src={CheckboxCheckedIcon} alt='icon-checked' />}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <div className='input-box-border' />
                    {children}
                </AccordionDetails>
            </Accordion>
        </BasePropertyInput >
    )
}