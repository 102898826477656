export function BaseInfoPanel() {
    return <div>

    </div>
}

export function ImageInfoPanel({ imageSrc, description, defaultValue }: { imageSrc: string, description?: string, defaultValue?: string }) {
    return <div style={{ display: "flex", flexDirection: "column" }}>
        {description && <span>{description}</span>}
        <img src={imageSrc} alt="Info panel" width={250} style={{ minHeight: 100 }}></img>
        {defaultValue && <span>The default value is: {defaultValue}</span>}
    </div>
}