import * as PIXI from 'pixi.js'
// import faker from "faker";
import { OrientableFloorPlanElement } from "./orientableFloorPlanElement";
import Icon from "../../../../../assets/img/icon-fp-spot-white.svg";
import { SpotTypesEnum, Spot } from '../../../../../types/FloorPlan/Spot.types';
import { ComponentOrientations } from '../../../../../types/FloorPlan/OrientableComponent.types';

export class SpotElement extends OrientableFloorPlanElement {
    spotType: SpotTypesEnum

    constructor(
        spot: Spot
    ) {
        super("Spot", spot.x, spot.y, spot.mapRefUUID, spot.orientation, Icon)

        this.spotType = spot.type

        this.update()

        this.setOrientation(this.orientation)

        if (this.sprite && this.sprite instanceof PIXI.Sprite) {
            this.sprite.tint = 0x000000
            this.sprite.height = this.radius + 10
            this.sprite.width = this.radius + 10
            this.sprite.alpha = 0.75
        }
    }

    update() {
        if (this.spotType === SpotTypesEnum.ARTWORK)
            this.hideOrientation()

        if (this.sprite && this.sprite instanceof PIXI.Sprite) {
            if (this.selected) {
                if (this.spotType === SpotTypesEnum.ARTWORK) {
                    this.sprite.tint = 0x007bff
                    this.hideArc()
                } else {
                    this.sprite.tint = 0x000000
                    this.showArc()
                }
            }
            else {
                this.sprite.tint = 0x000000
            }
        }
    }

    setOrientation(newOrientation: ComponentOrientations) {
        if (this.spotType === SpotTypesEnum.MOVEMENT)
            super.setOrientation(newOrientation)
    }


    setType(type?: SpotTypesEnum) {
        if (type === this.spotType)
            return

        this.spotType = type || SpotTypesEnum.ARTWORK;
        if (this.spotType === SpotTypesEnum.ARTWORK)
            super.hideOrientation()
        else
            super.showOrientation()

        if (this.selected) {
            this.unselect()
            this.select()
        }
    }

    select() {
        super.select(this.spotType === SpotTypesEnum.MOVEMENT)
        this.update()
    }

    unselect(): void {
        super.unselect()
        this.update()
    }
}