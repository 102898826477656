import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ArtworkData } from "../../../types/FloorPlan/Artwork.types";
import { ChangeEvent, Fragment, useRef } from "react";
import { ButtonPropertyInput } from "../Properties/ButtonPropertyInput";
import { uploadArtwork } from "../../../store/reducers/FloorPlan/FloorPlan";
import { HeaderProperty } from "../Properties/HeaderProperty";


export const FileButtonInput = ({ allArtworks, fileTypesAccept, header }: {
    allArtworks: { [k: string]: ArtworkData }, fileTypesAccept: string, header?: string
}) => {

    const dispatch = useAppDispatch()
    const fileInput = useRef<HTMLInputElement>(null);
    const selectedData = useAppSelector(state => state.editor.selectedData)

    const artwork = Object.values(allArtworks).filter((s) => selectedData.map(sd => sd.id).includes(s.uuid))
    const hasArtwork = artwork[0] && artwork[0].media !== null

    const InfoText = () => {
        if (selectedData.length !== 1)
            return <label >Multiple files</label>

        if (!hasArtwork || artwork[0].media === null)
            return <label className="error">Missing File {artwork[0].media?.name} </label>

        if (artwork[0].media.status === "upload_failed")
            return <label className="error">{artwork[0].media.error ? artwork[0].media.error : "Upload Failed!"}</label>

        if (artwork[0].media.error !== undefined)
            return <label className="error">{artwork[0].media.error ? artwork[0].media.error : "Upload Failed!"}</label>

        if (artwork[0].media.status === "upload_pending" || artwork[0].media.status === "uploading")
            return <label >Uploading...</label>

        return <label style={{ display: "block", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{artwork[0]?.media?.name}</label>
    }

    return (
        <Fragment>
            <HeaderProperty header={header ? header : "File"} />
            <InfoText />
            <ButtonPropertyInput
                onClick={() => {
                    fileInput.current?.click()
                }}
                color="primary"
                variant={"contained"}
                disabled={selectedData.length !== 1}
            >
                <span>{hasArtwork ? "Change file" : "Upload file"}</span>
            </ButtonPropertyInput>
            <input
                type="file"
                ref={fileInput}
                accept={fileTypesAccept}
                style={{ display: 'none' }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const { files } = event.target
                    if (!files || files[0] === undefined) {
                        console.error("No file provided")
                        return
                    }
                    if (!selectedData[0])
                        return

                    const file = files[0]
                    dispatch(uploadArtwork({ file: file, artworkUuid: selectedData[0].id }))
                }}
            />
        </Fragment >
    )
}