import { Fragment } from "react"
import { useAppDispatch } from "../../hooks"
import { setIsHelpRunning } from "../../store/reducers/Editor"
import { InspectorBody } from "./Layout/inspectorBody"
import { InspectorFooter } from "./Layout/inspectorFooter"
import { InspectorHeader } from "./Layout/inspectorHeader"
import { ButtonPropertyInput } from "./Properties/ButtonPropertyInput"
import { InfoProperty } from "./Properties/InfoProporty"
import { HeaderProperty } from "./Properties/HeaderProperty"

import EntranceIcon from './../../assets/img/icon-entrance.svg';
import WallIcon from './../../assets/img/icon-wall.svg';
import ArtworkIcon from './../../assets/img/icon-artwork.svg';
import OrientationIcon from './../../assets/img/orientation-slice.svg';
import LightsIcon from './../../assets/img/icon-lights.svg';
import SpotsIcon from './../../assets/img/icon-fp-spot.svg';

export function HelpToolInspector() {
    const dispatch = useAppDispatch()
    return (
        <Fragment>
            <InspectorHeader>Help</InspectorHeader>
            <InspectorBody>
                <HeaderProperty header="Keys" />
                <InfoProperty label="Entrance">
                    <img src={EntranceIcon} className='end-icon' alt='icon-edit' />
                </InfoProperty>
                <InfoProperty label="Wall">
                    <img src={WallIcon} className='end-icon' alt='icon-edit' />
                </InfoProperty>
                <InfoProperty label="Artwork">
                    <img src={ArtworkIcon} className='end-icon' alt='icon-edit' />
                </InfoProperty>
                <InfoProperty label="Orientation">
                    <img src={OrientationIcon} className='end-icon' alt='icon-edit' />
                </InfoProperty>
                <InfoProperty label="Light">
                    <img src={LightsIcon} className='end-icon' alt='icon-edit' />
                </InfoProperty>
                <InfoProperty label="Spot">
                    <img src={SpotsIcon} className='end-icon' alt='icon-edit' />
                </InfoProperty>

            </InspectorBody>
            <InspectorFooter>

                <ButtonPropertyInput
                    onClick={() => {
                        dispatch(setIsHelpRunning(true))
                    }}
                    color="primary"
                    variant={"contained"}
                >
                    <span>Step by Step Tutorial</span>
                </ButtonPropertyInput>

            </InspectorFooter>
        </Fragment >
    )
}