import clsx from "clsx";
import { DataPreviewType } from "../../../types/Editor/dataPreview.types";

export default function DataPreview(
    {
        data,
        selected,
        dissabled,
        onClick,
        onPointerDown,
        canZoom
    }: {
        data: DataPreviewType,
        selected?: boolean
        dissabled?: boolean,
        onClick?: (data: DataPreviewType) => void
        onPointerDown?: (data: DataPreviewType) => void,
        canZoom?: boolean
    }) {

    const clickable = onClick !== undefined && !dissabled
    const draggable = onPointerDown !== undefined && !dissabled

    return (
        <div
            className={
                clsx(
                    "preview-image",
                    { "dissabled": dissabled },
                    { "clickable": clickable },
                    { "draggable": draggable },
                    { "selected": selected }
                )
            }
            onClick={() => clickable && onClick(data)}
            onPointerDown={(e) => {
                if (e.button !== 0 || e.buttons !== 1)
                    return

                if (draggable) {
                    e.preventDefault()
                    onPointerDown(data)
                }

            }}
            onSelect={() => false}
        >
            {data.tint &&
                <div
                    style={{ "background": data.tint }}
                    className="preview-image-tint">
                </div>
            }
            <img
                className={canZoom ? "zoom-img" : ''}
                src={data.previewImageURL}
                alt={data.altText || data.previewImageURL}
                draggable={false}
            />
        </div>
    )
}