import { Fragment } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { FloorPlanDataType } from "../../types/FloorPlan/FloorPlan.types"
import { EntranceSelectedInspector } from "./SelectedInspector/entranceSelectedInspector"
import { LightsSelectedInspector } from "./SelectedInspector/lightsSelectedInspector"
import { MultipleSelectedInspector } from "./SelectedInspector/multipleSelectedInspector"
import { NoDataSelectedInspector } from "./SelectedInspector/noDataSelectedInspector"
import { SculpturesSelectedInspector } from "./SelectedInspector/sculpturesSelectedInspector"
import { SpotsSelectedInspector } from "./SelectedInspector/spotsSelectedInspector"
import { WallsSelectedInspector } from "./SelectedInspector/wallsSelectedInspector"
import { setSelectData } from "../../store/reducers/Editor"
import { InspectorHeader } from "./Layout/inspectorHeader"


export function SelectToolInspector() {

    const dispatch = useAppDispatch()

    const selected = useAppSelector(state => state.editor.selectedData)

    const type = selected.reduce<FloorPlanDataType | "Variou">((acc, cr) => acc === cr.type ? cr.type : "Variou", selected[0] ? selected[0].type : "Variou")

    const expositionData = useAppSelector(state => state.exhibition.exhibition?.title)


    function SelectionInspector() {
        if (selected.length === 0)
            return <NoDataSelectedInspector />
        else
            switch (type) {
                case "Wall":
                    return <WallsSelectedInspector />
                case "Light":
                    return <LightsSelectedInspector />
                case "Spot":
                    return <SpotsSelectedInspector />
                case "Entrance":
                    return <EntranceSelectedInspector />
                case "Sculpture":
                    return <SculpturesSelectedInspector />
                case "Variou":
                    return <MultipleSelectedInspector />
                default:
                    return (
                        <Fragment>
                            {"*" + type + "s Inspector...*"}
                        </Fragment>
                    )
            }
    }
    return (
        <Fragment>
            {selected.length === 0 ? (
                <InspectorHeader>{expositionData || "Loading..."}</InspectorHeader>
            ) : (
                <InspectorHeader
                    onClick={() => {
                        dispatch(setSelectData([]))
                    }}
                >
                    {type + (selected.length <= 1 ? "" : ("s (" + selected.length + ")"))}
                </InspectorHeader>
            )}
            <SelectionInspector />
        </Fragment >
    )
}