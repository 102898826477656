import * as PIXI from 'pixi.js'
import { FloorPlanArtwork } from '../../../../types/FloorPlan/Artwork.types'
import { FloorPlanElement } from '../components/floorPlanElement'
import { FloorPlanApp } from '../floorPlanApp'
import { utils } from 'pixi.js';

export class ArtworkDndGraphic extends FloorPlanElement {
    sprite: PIXI.Sprite
    frame: PIXI.Graphics
    passepartout: PIXI.Graphics

    constructor(artworkData: FloorPlanArtwork) {
        super("Artwork", artworkData.mapRefUUID)

        this.x = artworkData.x
        this.y = artworkData.y


        this.frame = new PIXI.Graphics()
            .beginFill(0xffffff)
            .drawRect(0, 0, 1, 1)
            .endFill()

        this.addChild(this.frame)

        this.passepartout = new PIXI.Graphics()
            .beginFill(0xffffff)
            .drawRect(0, 0, 1, 1)
            .endFill()

        this.addChild(this.passepartout)

        var texture = this.app.resourcesLoader.getTexture(artworkData.imageURL)

        this.sprite = new PIXI.Sprite(texture)
        this.addChild(this.sprite)

        this.update(artworkData)
        this.interactive = true
        this.on("pointerdown", (e) => FloorPlanApp.getInstance().onClick(e, this))
    }


    select(): void {
        super.select()

        // this.sprite.tint = 0xffff00
    }

    update(artworkData: FloorPlanArtwork) {
        this.x = artworkData.x
        this.y = artworkData.y

        this.passepartout.width = artworkData.passepartoutWidth * 2 + artworkData.width
        this.passepartout.height = artworkData.passepartoutWidth * 2 + artworkData.height
        this.passepartout.tint = utils.string2hex(artworkData.passepartoutColor)
        this.passepartout.x = artworkData.frameWidth
        this.passepartout.y = artworkData.frameWidth

        this.frame.width = artworkData.passepartoutWidth * 2 + artworkData.frameWidth * 2 + artworkData.width
        this.frame.height = artworkData.passepartoutWidth * 2 + artworkData.frameWidth * 2 + artworkData.height
        this.frame.tint = utils.string2hex(artworkData.frameColor)

        this.sprite.x = artworkData.passepartoutWidth + artworkData.frameWidth
        this.sprite.y = artworkData.passepartoutWidth + artworkData.frameWidth
        this.sprite.width = artworkData.width
        this.sprite.height = artworkData.height
    }
}