import { ToolType } from '../../../../types/Editor/Tool.types';
import FloorPlanConnector from '../floorPlanConnector';
import { Tool } from "./tool"

export class ChangeToolToTool extends Tool {
    tool: ToolType

    constructor(toolToChangeTo: ToolType) {
        super()
        this.tool = toolToChangeTo
    }

    // interaction    
    onClick(): void {
        FloorPlanConnector.setTool(this.tool)
    }

    onDragMove() {
    }

    onDragEnd() {
    }
}