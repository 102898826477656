import { useState, Fragment } from "react";
import Floater from "react-floater";
import clsx from "clsx";

const timeToShowInfoInMs = 1000

export function PropertyInputLabel({ content, children }: { content?: React.ReactNode, children: React.ReactNode }) {
    const [showInfo, setShowInfo] = useState(false)
    const [timeout, setTi] = useState<NodeJS.Timeout>()

    return <Fragment>
        <label 
            className={clsx({ "floater-show": showInfo })}
            onPointerEnter={() => content && setTi(setTimeout(() => setShowInfo(true), timeToShowInfoInMs))}
            onPointerLeave={() => { if (content) { setShowInfo(false); clearTimeout(timeout) } }}
            onPointerDown={() => { if (content) { setShowInfo(true); clearTimeout(timeout) } }}
            style={content ? { cursor: "help" } : {}}
        >{children}</label>

        <Floater title={children} open={showInfo} content={content} placement={"left"} target=".floater-show" />
    </Fragment>
}