import { Box, Modal, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { FloorPlanDataType } from '../../types/FloorPlan/FloorPlan.types';
import { confirmDeleteModalClose } from '../../store/reducers/UI';
import { setPropertyDeleted } from '../../store/reducers/FloorPlan/FloorPlan';
import { Button } from '../../styles/MUI/Button';
import { setSelectData } from '../../store/reducers/Editor';
import { SelectedData } from '../../types/Editor.types';

const style: SxProps<Theme> = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: 'translate(-50%, -50%)',
    width: "500",
    display: "flex",
    flexDirection: "column",
    lineHeight: 1.5
}

export function ConfirmDeleteModal() {
    const open = useAppSelector(s => s.ui.deleteModalOpen)
    const option = useAppSelector(s => s.ui.deleteModalOption)
    const dispatch = useAppDispatch()

    const handleCancel = () => {
        dispatch(confirmDeleteModalClose())
    }

    function Content() {
        const selected = useAppSelector(s => s.editor.selectedData)
        const data = useAppSelector(s => s.floorPlan.floorPlan)

        const type = selected.reduce<FloorPlanDataType | "element">((acc, cr) => acc === cr.type ? cr.type : "element", selected[0] ? selected[0].type : "element")

        const handleDelete = () => {
            var selectedData = selected
            if (option === "All" && data) {
                const newSelectedData: SelectedData[] = []
                Object.keys(data.walls).forEach((k) => newSelectedData.push({ id: k, type: "Wall" }))
                Object.keys(data.lights).forEach((k) => newSelectedData.push({ id: k, type: "Light" }))
                Object.keys(data.sculptures).forEach((k) => newSelectedData.push({ id: k, type: "Sculpture" }))
                Object.keys(data.spots).forEach((k) => newSelectedData.push({ id: k, type: "Spot" }))

                // console.log("Delete all data", newSelectedData, selected)

                dispatch(setSelectData(newSelectedData))
                selectedData = newSelectedData
            }
            selectedData.forEach(data => {
                dispatch(setPropertyDeleted({
                    type: data.type,
                    id: data.id,
                    deleted: true
                }))
            })
            dispatch(confirmDeleteModalClose())
        }

        return (
            <Box
                p={3}
                sx={style}
                bgcolor="white"
                borderRadius={1}
                textAlign={"center"}
                position="absolute"
                color="theme.palette.error"
            >
                {
                    option === "All" ?
                        <span>Are you sure you want to delete everything?</span>
                        :
                        <span>Are you sure you want to delete {selected.length === 1 ? "this " + type : "these " + type + "s"}?</span>
                }
                <span>This action cannot be undone.</span>
                <Box display={"flex"} justifyContent={"space-between"} marginTop={3}>
                    <Button fullWidth variant='contained' color="primary" onClick={handleCancel}>
                        <span>Cancel</span>
                    </Button>
                    <Box p={1}></Box>
                    <Button fullWidth variant='contained' color="error" onClick={handleDelete}>
                        <span>Delete</span>
                    </Button>
                </Box>
            </Box>
        )
    }

    return (
        <Modal open={open} onClose={handleCancel}>
            <div>
                <Content />
            </div>
        </Modal>
    )
}
