import { ButtonBase, Box } from "@mui/material";
import ArrowLeftIcon from "../../../assets/img/icon-arrow-left.svg"
import { SxProps } from "@mui/material/styles";

export function InspectorHeader(
    {
        onClick,
        children: header,
        sx
    }: {
        onClick?: () => void
        children: string
        sx?: SxProps,
    }) {

    if (onClick)
        return (
            <Box className='inspector-body' sx={sx}>
                < ButtonBase
                    className='inspector-header'
                    onClick={onClick}
                >
                    <img src={ArrowLeftIcon} className='start-icon' alt='icon-settings' />
                    <h2>{header}</h2>
                </ButtonBase>
            </Box>
        )
    else
        return (
            <Box className='inspector-body' sx={sx}>
                < div className='inspector-header'>
                    <h2>{header}</h2>
                </div>
            </Box>
        )
}