import { MultiLanguageString } from "../MultiLanguate.types"
import { FloorPlanData } from "./FloorPlanElement.types"
import { SculptureFileFormat } from "./Sculpture.types"
import { WallSide } from "./Wall.types"

export const validSculptureFileFormats = ['fbx'] as string[];
export const validSculptureImageFileFormats = ['png', 'jpg'] as string[];
export const validVideoFileFormats = ['mp4'] as string[];
export const defaultSculptureImageFileFormat: SculptureFileFormat = "FlatImage"

export type ArtworkFrameMatterial = '0001' | '0002' | '0003'

export const ArtworkFrameMatterialToEngText = {
    '0001': "Wooden",
    '0002': "Metalic",
    '0003': "Flat"
}

export type ArtworkUploadStatus = "upload_pending" | "uploading" | "uploaded" | "upload_failed"
export type ArtworkMediaType = "IMAGE" | "SCULPTURE" | "VIDEO"

// artworks in Artwork reducer
export type ArtworkData = {
    uuid: string,

    title: MultiLanguageString,
    width: number,
    height: number,
    depth: number,
    unit: string,

    year: number,

    price: number | undefined,
    currency: string,

    description: MultiLanguageString,
    mediaType: ArtworkMediaType,
    sculptureFormat: SculptureFileFormat,

    artist: {
        firstName: string,
        lastName: string,
        web: string | undefined,
    }
    photo: {
        fileName: string,
        original: string,
        large: string,
        medium: string,
        small: string,
    }

    media: null | {
        uuid: string,
        name: string,
        status: ArtworkUploadStatus,
        error?: string
    }
}

export function checkIfCorrectSculptureMedia(artworkData: ArtworkData) {
    if (artworkData.mediaType !== "SCULPTURE") return
    
    if (artworkData.media) {
        const split = artworkData.media.name.split('.');
        const fileFormat = split[split.length - 1].toLowerCase()

        // console.log("validSculptureFileFormats.includes(fileFormat)", validSculptureFileFormats.includes(fileFormat), fileFormat, split)
        if (artworkData.sculptureFormat === "Sculpture")
            if (!validSculptureFileFormats.includes(fileFormat)) {
                artworkData.media = {
                    ...artworkData.media,
                    error: "Missing sculpture file"
                }
            } else {
                artworkData.media = {
                    ...artworkData.media,
                    error: undefined
                }
            }

        if (artworkData.sculptureFormat !== "Sculpture") {
            if (!validSculptureImageFileFormats.includes(fileFormat))
                artworkData.media = {
                    ...artworkData.media,
                    error: "Missing image file"
                }
            else {
                artworkData.media = {
                    ...artworkData.media,
                    error: undefined
                }
            }
        }

    }

}

// artwork in floorplan
export class FloorPlanArtwork extends FloorPlanData {
    title: string

    width: number
    height: number
    side: WallSide

    depth: number
    edgeColor: string

    hasFrame: boolean
    frameWidth: number
    frameColor: string
    // frameDepth: number
    frameTextureId: string
    frameMaterial: ArtworkFrameMatterial
    mediaType: ArtworkMediaType

    passepartoutWidth: number
    passepartoutColor: string

    imageURL: string

    wallId?: string

    constructor(mapRefUUID?: string) {
        super(mapRefUUID)

        this.title = ""

        this.width = 100
        this.height = 100
        this.side = "right"

        this.depth = 10
        this.edgeColor = "#bcbcbc"

        this.hasFrame = false
        this.frameWidth = 0
        this.frameColor = "#121212"
        // this.frameDepth = 0
        this.frameTextureId = ""
        this.frameMaterial = '0001'

        this.passepartoutWidth = 0
        this.passepartoutColor = "#ffffff"
        this.mediaType = "IMAGE"

        this.imageURL = "https://api.exhibify.net/image_process/resize.php?src=https://exb-images.s3.eu-west-1.amazonaws.com/3lZ1xC74qI7UXYCoTTqJZ1hcW.png&w=400"
    }

    get fullHeight() {
        if (!this.hasFrame)
            return this.height

        return this.height + this.frameWidth * 2 + this.passepartoutWidth * 2
    }

    get fullWidth() {
        if (!this.hasFrame)
            return this.width

        return this.width + this.frameWidth * 2 + this.passepartoutWidth * 2
    }


}