import * as PIXI from 'pixi.js'
import { InteractionEvent } from 'pixi.js'
import { FloorPlanArtwork } from '../../../../types/FloorPlan/Artwork.types'
import { EYE_LINE_HEIGHT_CM_MAX, EYE_LINE_HEIGHT_CM_MIN } from '../../../../types/FloorPlan/FloorPlan.types'
import { FloorPlanApp } from '../floorPlanApp'
import FloorPlanConnector from '../floorPlanConnector'
import { ArtworkDndGraphic } from './artworkDndGraphic'
import { ArtworkGuidelines } from './artworkGuidelines'
import { GuideLine } from './guideline'

class EyeGuideline extends GuideLine {
    app: FloorPlanApp
    hLine: PIXI.Graphics
    interactionStartHeightGlobal: number
    heightText: PIXI.Text

    constructor(wallWidth: number, wallHeight: number, eyeLineHeight: number) {
        super()
        // console.log("CREATE WALL: ", eyeLineHeight)
        this.app = FloorPlanApp.getInstance()
        this.rotation = Math.PI
        this.y = wallHeight
        this.height = eyeLineHeight
        this.color = 0xff3333
        this.alpha = 0.7
        this.arrowStart.addChild(new PIXI.Graphics()
            .beginFill(0xff0000)
            .drawRect(-wallWidth, 0, wallWidth, 1)
            .endFill())
        this.hLine = new PIXI.Graphics()
            .beginFill(0x000000, 0.001)
            .drawRect(-wallWidth, -2, wallWidth, 5)
            .endFill()
        this.arrowStart.addChild(this.hLine)

        this.heightText = new PIXI.Text(this.height.toFixed(0), {
            fill: 0xff2323,
            fontSize: 8,
        })
        this.heightText.x = -5
        this.heightText.y = 0
        this.heightText.resolution = 2
        this.heightText.angle = 180
        this.arrowStart.addChild(this.heightText)


        const _this = this
        this.interactionStartHeightGlobal = 0

        function onDragMove(e: InteractionEvent) {
            const interactionPointHeight = e.data.getLocalPosition(_this.app.currentCanvas).y

            _this.height = _this.height + _this.interactionStartHeightGlobal - interactionPointHeight
            if (_this.height < EYE_LINE_HEIGHT_CM_MIN)
                _this.height = EYE_LINE_HEIGHT_CM_MIN
            if (_this.height > EYE_LINE_HEIGHT_CM_MAX)
                _this.height = EYE_LINE_HEIGHT_CM_MAX

            _this.heightText.text = _this.height.toFixed(0)
            if (_this.height > EYE_LINE_HEIGHT_CM_MIN && _this.height < EYE_LINE_HEIGHT_CM_MAX)
                _this.interactionStartHeightGlobal = interactionPointHeight

        }

        function onDragStart(e: InteractionEvent) {
            _this.interactionStartHeightGlobal = e.data.getLocalPosition(_this.app.currentCanvas).y
            _this.app.stage.on('pointermove', onDragMove)
            _this.app.stage.on('pointerup', onDragEnd)
        }
        function onDragEnd() {
            _this.app.stage.off('pointermove', onDragMove)
            _this.app.stage.off('pointerup', onDragEnd)

            FloorPlanConnector.setEyeLineHeight(_this.height)
        }

        this.hLine.interactive = true
        this.hLine.on("pointerdown", onDragStart)
        this.hLine.cursor = "ns-resize"
    }
}

function computeHelperSize(artworkHeight: number, artworkPos: number, goalArtworkHeight: number, goalArtworkPos: number) {
    var leftHelperSize = artworkPos + artworkHeight / 2 - goalArtworkPos
    if (leftHelperSize > goalArtworkHeight)
        leftHelperSize -= goalArtworkHeight
    else if (leftHelperSize > 0)
        leftHelperSize = 0
    return leftHelperSize
}

export class DndWall extends PIXI.Container {
    artworks: { [id: string]: ArtworkDndGraphic }
    private artworksContainer: PIXI.Container<ArtworkDndGraphic>
    private artworkGuidelines: ArtworkGuidelines
    private eyeGuideline: EyeGuideline

    wallHeight: number
    wallWidth: number

    private tops: { [key: string]: number } = {}
    private bottoms: { [key: string]: number } = {}
    private rights: { [key: string]: number } = {}
    private lefts: { [key: string]: number } = {}


    constructor(wallWidth: number, wallHeight: number, eyeLineHeight?: number) {
        super()
        this.artworks = {}

        this.wallHeight = wallHeight
        this.wallWidth = wallWidth

        const bgWall = new PIXI.Graphics()
            .beginFill(0x5c5c5c)
            .drawRect(0, 0, wallWidth, wallHeight)
            .endFill()

        bgWall.filters = [new PIXI.filters.BlurFilter(10)]
        this.addChild(bgWall)

        this.addChild(
            new PIXI.Graphics()
                .beginFill(0xffffff)
                .drawRect(0, 0, wallWidth, wallHeight)
                .endFill()
        )

        const wallWidthGuideline = new GuideLine()
        wallWidthGuideline.height = wallHeight
        wallWidthGuideline.text.text = wallHeight
        wallWidthGuideline.text.x = -5 - wallWidthGuideline.text.width
        wallWidthGuideline.x = -10
        this.addChild(wallWidthGuideline)

        const wallHeightGuideline = new GuideLine()
        wallHeightGuideline.height = wallWidth
        wallHeightGuideline.text.text = wallWidth.toFixed(0)
        wallHeightGuideline.text.angle = 90
        wallHeightGuideline.text.x = 5 + wallHeightGuideline.text.height
        wallHeightGuideline.y = -10
        wallHeightGuideline.angle = -90
        this.addChild(wallHeightGuideline)

        this.eyeGuideline = new EyeGuideline(wallWidth, wallHeight, eyeLineHeight || 0)
        if (eyeLineHeight) {
            this.addChild(this.eyeGuideline)
        }

        this.artworksContainer = new PIXI.Container()
        this.addChild(this.artworksContainer)

        this.artworkGuidelines = new ArtworkGuidelines(wallWidth, wallHeight)
        this.addChild(this.artworkGuidelines)

    }

    private updatePositions(artworkDnd: ArtworkDndGraphic) {
        this.tops[artworkDnd.mapRefUUID] = artworkDnd.y
        this.bottoms[artworkDnd.mapRefUUID] = artworkDnd.y + artworkDnd.height
        this.rights[artworkDnd.mapRefUUID] = artworkDnd.x
        this.lefts[artworkDnd.mapRefUUID] = artworkDnd.x + artworkDnd.width
    }

    addArtwork(artworkData: FloorPlanArtwork) {
        // console.log("ADD ARTWORK", artworkData.mapRefUUID)
        const newArtworkDnd = new ArtworkDndGraphic(artworkData)
        this.artworks[artworkData.mapRefUUID] = newArtworkDnd
        this.artworksContainer.addChild(newArtworkDnd)

        this.updatePositions(newArtworkDnd)

        return newArtworkDnd
    }

    removeArtwork(artworkId: string) {
        const artworkDnd = this.artworks[artworkId]
        if (!artworkDnd) {
            console.warn("Coudn't find artwork")
            return
        }

        delete this.tops[artworkId]
        delete this.bottoms[artworkId]
        delete this.rights[artworkId]
        delete this.lefts[artworkId]

        artworkDnd.destroy()
        delete this.artworks[artworkId]
        this.hideGuideLines()
    }

    updateArtwork(artworkData: FloorPlanArtwork) {
        var artworkDndGraphic = this.artworks[artworkData.mapRefUUID]
        if (!artworkDndGraphic) {
            artworkDndGraphic = this.addArtwork(artworkData)
        }
        artworkDndGraphic.update(artworkData)

        this.updatePositions(artworkDndGraphic)

        const bot = Object.entries(this.tops)
            .filter((v) => v[1] > artworkData.y + artworkData.fullHeight)
            .reduce((pr, cr) => cr[1] < pr[1] ? cr : pr, ["", this.wallHeight])

        const top = Object.entries(this.bottoms)
            .filter((v) => v[1] < artworkData.y)
            .reduce((pr, cr) => cr[1] > pr[1] ? cr : pr, ["", 0])

        const right = Object.entries(this.lefts)
            .filter((v) => v[1] < artworkData.x)
            .reduce((pr, cr) => cr[1] > pr[1] ? cr : pr, ["", 0])

        const left = Object.entries(this.rights)
            .filter((v) => v[1] > artworkData.x + artworkData.fullWidth)
            .reduce((pr, cr) => cr[1] < pr[1] ? cr : pr, ["", this.wallWidth])

        // console.log(left, Object.values(this.rights))

        const goalArtworkLeft = this.artworks[left[0]]
        const leftHelperSize = goalArtworkLeft ?
            computeHelperSize(
                artworkData.fullHeight,
                artworkData.y,
                goalArtworkLeft.height,
                goalArtworkLeft.y
            ) : undefined

        const goalArtworkRight = this.artworks[right[0]]
        const rightHelperSize = goalArtworkRight ?
            -computeHelperSize(
                artworkData.fullHeight,
                artworkData.y,
                goalArtworkRight.height,
                goalArtworkRight.y
            ) : undefined

        const goalArtworkTop = this.artworks[top[0]]
        const topHelperSize = goalArtworkTop ?
            computeHelperSize(
                artworkData.fullWidth,
                artworkData.x,
                goalArtworkTop.width,
                goalArtworkTop.x
            ) : undefined

        const goalArtworkBot = this.artworks[bot[0]]
        const botHelperSize = goalArtworkBot ?
            -computeHelperSize(
                artworkData.fullWidth,
                artworkData.x,
                goalArtworkBot.width,
                goalArtworkBot.x
            ) : undefined



        this.artworkGuidelines.update(
            artworkData,
            top[1],
            bot[1],
            right[1],
            left[1],
            topHelperSize,
            botHelperSize,
            rightHelperSize,
            leftHelperSize
        )

    }

    hideGuideLines() {
        this.artworkGuidelines.visible = false
    }


    tidyUpArtwork(artworkId: string) {
        const artwork = this.artworks[artworkId]
        if (!artwork)
            return

        const left = Object.values(this.lefts)
            .filter((v) => v < artwork.x)
            .reduce((pr, cr) => cr > pr ? cr : pr, 0)

        const right = Object.values(this.rights)
            .filter((v) => v > artwork.x + artwork.width)
            .reduce((pr, cr) => cr < pr ? cr : pr, this.wallWidth)

        artwork.x = left + (right - left) / 2 - artwork.width / 2
        FloorPlanConnector.moveElementTo(artwork.x, artwork.y, artworkId, "Artwork")
    }

    tidyUpArtworks() {
        const orderedArtworks = Object.values(this.artworks).sort((a, b) => a.x - b.x)

        const widthSum = orderedArtworks.reduce((cr, pr) => cr + pr.width, 0)
        const equalLength = (this.wallWidth - widthSum) / (orderedArtworks.length + 1)

        var cursor = 0
        orderedArtworks.forEach((a, i) => {
            a.x = equalLength + cursor
            cursor = a.x + a.width
            FloorPlanConnector.moveElementTo(a.x, a.y, a.mapRefUUID, "Artwork")
            this.updatePositions(a)
        })
    }

    centerArtwork(artworkId: string) {
        const artwork = this.artworks[artworkId]
        if (!artwork)
            return
        artwork.y = this.wallHeight - this.eyeGuideline.height - artwork.height / 2
        FloorPlanConnector.moveElementTo(artwork.x, artwork.y, artworkId, "Artwork")
    }

    centerArtworks() {
        Object.keys(this.artworks).forEach(artworkId => {
            this.centerArtwork(artworkId)
        })
    }
}