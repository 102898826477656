import { SxProps } from "@mui/material/styles";
import { Box } from "@mui/material";

export function InspectorFooter({ children, sx }: { children: React.ReactNode, sx?: SxProps }) {

    return (
        <Box className='inspector-body footer' sx={sx}>
            {children}
        </Box>
    )
}