import { Fragment, useState, useContext, useEffect } from "react"
import { InspectorHeader } from "../Layout/inspectorHeader";
import { Subinspector } from "../Layout/subInspector";
import { HideOverflowYContext } from "../Layout/inspectorBody";
import { BasePropertyInput } from "./BasePropertyInput";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PropertyInputLabel } from "./PropertyInputLabel";
import { SxProps } from "@mui/material/styles";

const Button = ({ onClick, header }: { onClick: () => void, header: string }) => {
    return (
        <BasePropertyInput onClick={onClick} >
            <PropertyInputLabel  >{header}</PropertyInputLabel>
            <ArrowForwardIosIcon
                sx={{
                    marginRight: "auto"
                }}
                fontSize="small" />
        </BasePropertyInput >
    )
}

export function SubinspectorPropertyInput({
    children, header, sx, headerSx
}: {
    children: React.ReactElement,
    header: string,
    sx?: SxProps,
    headerSx?: SxProps
}) {

    const [isIn, setIsIn] = useState(false)
    const hideOverflowYContext = useContext(HideOverflowYContext)

    useEffect(() => {
        hideOverflowYContext.setHideOverflowY(isIn)
    }, [isIn, hideOverflowYContext])

    return (
        <Fragment>
            <Subinspector in={isIn} sx={sx}>
                <InspectorHeader sx={headerSx} onClick={() => setIsIn(false)}>
                    {header}
                </InspectorHeader>
                {children}
            </Subinspector>
            <Button header={header} onClick={() => setIsIn(true)} />
        </Fragment >
    )
}