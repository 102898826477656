import clsx from "clsx";
import { IconButton, Box } from "@mui/material";

export type InspectorOption = {
    selected: boolean,
    iconSrc: string,
    onClick: () => void,
    title: string,

}

export function InspectorOptions({ children, options }: { children?: React.ReactNode, options: InspectorOption[] }) {
    return (
        <Box display="flex" justifyContent={"space-evenly"} my={1} >
            {options.map((o, i) => {
                return (
                    <IconButton key={o.title + i} sx={{ p: "4px" }} className={clsx({ "selected": o.selected })} title={o.title} onClick={o.onClick}>
                        <img src={o.iconSrc} className="icon" alt={o.title + " Icon"} />
                    </IconButton>
                )
            })}
            {children}
        </Box>
    )
}