import * as PIXI from 'pixi.js'
import { FloorPlanApp } from '../floorPlanApp'

const defaultStyle: Partial<PIXI.ITextStyle> = {
    fontSize: 18,

}

export class MouseText extends PIXI.Container {
    private static _active: "INACTIVE" | "SOFTACTIVE" | "ACTIVE"
    private static _instance: MouseText
    private _bg: PIXI.Graphics
    private _text: PIXI.Text

    constructor(text?: string | number | undefined, style?: Partial<PIXI.ITextStyle> | PIXI.TextStyle | undefined, canvas?: HTMLCanvasElement | undefined) {
        super()

        this._text = new PIXI.Text(text, style || defaultStyle, canvas)
        this._text.resolution = 1.5
        MouseText._instance = this
        MouseText._active = "INACTIVE"
        this._bg = new PIXI.Graphics()
            .beginFill(0xdfdfdf)
            .drawRect(0, 0, 10, 10)
            .endFill()

        this.addChild(this._bg)
        this.addChild(this._text)

        this.visible = false
    }

    private followMouse(e: PIXI.InteractionEvent) {
        // console.log("pointermove")
        MouseText._instance.moveTo(e.data.global.x, e.data.global.y)
    }

    private moveTo(x: number, y: number) {
        MouseText._instance.x = x + 10
        MouseText._instance.y = y
    }


    public static showTextWarning(text: string, delay: number = 0) {
        this._active = "SOFTACTIVE"
        setTimeout(() => {
            if (this._active === "SOFTACTIVE") {
                this.active = true
                this.text = text
            }
        }, delay)
    }

    static set text(text: string) {
        this._instance._text.text = text

        this._instance._bg.width = this._instance.width
        this._instance._bg.height = this._instance.height
    }

    static set active(active: boolean) {
        this._active = "ACTIVE"
        MouseText._instance.visible = active
        const fpa = FloorPlanApp.getInstance()

        this._instance.moveTo(
            fpa.renderer.plugins.interaction.mouse.global.x,
            fpa.renderer.plugins.interaction.mouse.global.y
        )

        if (active)
            fpa.stage.on("pointermove", MouseText._instance.followMouse)
        else
            fpa.stage.off("pointermove", MouseText._instance.followMouse)
    }
    get active() { return MouseText._active }
}