import { OrientableComponent } from "./OrientableComponent.types";

export type SculptureFileFormat = 'Sculpture' | 'FlatImage' | 'BoxImage' | 'CrossImage'

export const SculptureFileFormatToEngText = {
  'Sculpture': "Sculpture",
  'FlatImage': "Image - Flat",
  'BoxImage': "Image - Box",
  'CrossImage': "Image - Cross"
}

export type Dimensions = {
  width: number;
  height: number;
  depth: number;
};

export class Sculpture extends OrientableComponent {
  // private static num = 0
  uuid: string | null

  z: number
  dimensions: Dimensions
  hideTopAndBottom: boolean
  errorWithFileProvided: boolean

  constructor(mapRefUUID?: string) {
    super(mapRefUUID)

    this.z = 0
    this.dimensions = { height: 50, width: 50, depth: 50 }
    this.hideTopAndBottom = true
    this.errorWithFileProvided = true

    this.uuid = mapRefUUID || null
  }
}