import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks";
import { LightsToolInspector } from "./lightsToolInspector";
import { SculpturesToolInspector } from "./sculpturesToolInspector";
import { PlaceArtworksToolInspector } from "./placeArtworksToolInspector";
import { SelectToolInspector } from "./selectToolInspector";
import { SpotsToolInspector } from "./spotsToolInspector";
import { DrawWallsToolInspector } from "./drawWallsToolInspector";
import { SettingsInspector } from "./settingsInspector";
import { EntranceToolInspector } from "./entranceToolInspector";
import { HelpToolInspector } from "./helpToolInspector";
import { Box, Slide, IconButton } from "@mui/material";
import { Button } from "../../styles/MUI/Button";
import clsx from "clsx";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { setEditorSetting } from "../../store/reducers/Editor";

function InspectorSelectorExpanded() {

    const tool = useAppSelector(state => state.editor.currentTool.type)

    switch (tool) {
        case "Select":
            return <SelectToolInspector />
        case "PlaceArtworks":
            return <PlaceArtworksToolInspector />
        case "Spots":
            return <SpotsToolInspector />
        case "Lights":
            return <LightsToolInspector />
        case "Sculptures":
            return <SculpturesToolInspector />
        case "DrawWalls":
            return <DrawWallsToolInspector />
        case "Settings":
            return <SettingsInspector />
        case "Entrance":
            return <EntranceToolInspector />
        case "Help":
            return <HelpToolInspector />
    }
    return <div></div>
}

export function InspectorSelector() {
    const inspectorExpanded = useAppSelector(s => s.editor.editorSettings.inspectorExpanded)
    const dispatch = useAppDispatch()

    return (
        <Box sx={{ position: "absolute", right: 0, top: 0, bottom: 0 }}>
            <Slide direction="left" in={inspectorExpanded} >
                <Box className='inspector'>
                    < InspectorSelectorExpanded />
                </Box>
            </Slide>
            <Box sx={{ height: "100%" }}>
                <Box className={clsx("inspector-hide-show-button", { "hide": inspectorExpanded })}>
                    <IconButton color="info" onClick={() => dispatch(setEditorSetting({ setting: "inspectorExpanded", value: !inspectorExpanded }))}>
                        {inspectorExpanded ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
                    </IconButton>
                </Box>
            </Box>
        </Box>
    )
}