import { Fragment } from "react"
import { InspectorHeader } from "./Layout/inspectorHeader"
import { SpotsSelectedInspector } from "./SelectedInspector/spotsSelectedInspector"



export function SpotsToolInspector() {

    return (
        <Fragment>
            <InspectorHeader>New spot</InspectorHeader>
            <SpotsSelectedInspector canDelete={false} />
        </Fragment >
    )
}