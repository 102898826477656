import * as PIXI from 'pixi.js'
import { VisiblePoint } from "./visiblePoint";

// import faker from 'faker';
import { FloorPlanElement } from './floorPlanElement';
// import { ContactlessOutlined, LocalConvenienceStoreOutlined, StayCurrentLandscapeRounded } from '@material-ui/icons';
import { FloorPlanApp } from '../floorPlanApp';
import { InteractionEvent } from 'pixi.js';
import { ArtworkElement } from './artworkElement';
import { Wall } from '../../../../types/FloorPlan/Wall.types';

const ZOOM_TEXT = true

function computeText(
    textElement: PIXI.Text,
    text: string,
    wallAngle: number,
    wallWidth: number,
    wallThickness: number,
    offset: number
) {
    if (textElement.visible) {

        if (wallWidth > WallElement.MINIMUM_WIDTH) {
            textElement.text = text

            const textY = wallThickness * .5 + offset
            if (wallAngle < -90 || wallAngle > 90) {
                textElement.angle = 180
                textElement.y = textY
            } else {
                textElement.angle = 0
                textElement.y = -textY
            }
        } else
            textElement.text = ""
    }
}

export class WallExteriorPoint extends VisiblePoint {
    app: FloorPlanApp = FloorPlanApp.getInstance()
    otherPoint!: WallExteriorPoint
    wall: WallElement
    exteriorPointType: "start" | "end"

    constructor(x: number, y: number, wall: WallElement, exteriorPointType: "start" | "end", color?: number, radius?: number) {
        super(x, y, color, radius)
        this.wall = wall
        this.exteriorPointType = exteriorPointType
    }

    moveTo(x: number, y: number): void {
        if (this.x === x && this.y === y)
            return

        this.x = x
        this.y = y

        this.wall.moveCenter()

        this.wall.reDraw()
    }
}


export class WallElement extends FloorPlanElement {
    wallContainer: PIXI.Container
    wallGraphic: PIXI.Graphics
    wallDimensionsText: PIXI.Text
    wallNameText: PIXI.Text
    wallName: string

    wallWidth: number
    // wallHeight: number
    wallAngle: number
    private _wallThickness: number
    private _region: number

    start: WallExteriorPoint;
    center: VisiblePoint;
    end: WallExteriorPoint;

    artworks: ArtworkElement[]

    static readonly MINIMUM_WIDTH = 5 //cm

    constructor(
        wall: Wall
    ) {
        super("Wall", wall.mapRefUUID)
        const _this = this

        // console.log("CREATE WALL ", wall)

        this.wallName = wall.name

        this.wallAngle = wall.angle
        this.wallWidth = wall.width
        // this.wallHeight = wall.height
        this._wallThickness = wall.thickness

        this._region = wall.region

        this.start = new WallExteriorPoint(wall.origin.x, wall.origin.y, this, "start")
        this.center = new VisiblePoint(wall.origin.x + wall.destiny.x / 2, wall.origin.y + wall.destiny.y / 2)
        this.end = new WallExteriorPoint(wall.destiny.x, wall.destiny.y, this, "end")

        this.start.otherPoint = this.end
        this.end.otherPoint = this.start

        const app = FloorPlanApp.getInstance()

        this.start.onClick = function (e: InteractionEvent) {
            app.onClick(e, this)
        }
        this.end.onClick = function (e: InteractionEvent) {
            app.onClick(e, this)
        }

        this.moveCenter()

        // wall container, to handle rotation and position of this wall
        this.wallContainer = new PIXI.Container()

        // wall graphics
        this.wallGraphic = new PIXI.Graphics()
        this.wallContainer.addChild(this.wallGraphic)

        this.wallGraphic.tint = 0x000000


        this.wallGraphic.on('pointerdown', (e) => {
            app.onClick(e, _this)
        })

        this.wallGraphic.on('pointerover', () => {
            _this.handlePointerOver()
            if (!_this.selected)
                _this.wallGraphic.tint = 0x9fd7ff
        })

        this.wallGraphic.on('pointerout', () => {
            _this.handlePointerOut()
            if (!_this.selected)
                _this.wallGraphic.tint = 0x000000
        })

        // wall text
        this.wallDimensionsText = new PIXI.Text("", {
            fill: 0xffa740,
            fontSize: 15
        })
        this.wallDimensionsText.resolution = this.app.floorPlanCanvas.scale.x
        this.wallDimensionsText.anchor.set(0.5, 0)
        this.addChild(this.wallDimensionsText) // add to wallContainer

        this.wallNameText = new PIXI.Text("this.wallName", {
            fill: 0x2fa770,
            fontSize: 12
        })
        this.wallNameText.resolution = this.app.floorPlanCanvas.scale.x
        this.wallNameText.anchor.set(0.5, 0)
        this.addChild(this.wallNameText) // add to wallContainer

        // wall artworks
        this.artworks = []

        this.reDraw()


        if (this.wallWidth === 0) {
            this.start.show(true)
            this.end.show(true)
        }

        // add to the container
        super.addChild(this.wallContainer)
        super.addChild(this.start)
        super.addChild(this.end)
    }

    addChild<T extends PIXI.DisplayObject[]>(...children: T): T[0] {
        return this.wallContainer.addChild(...children)
    }

    get x() {
        return this.center.x
    }
    get y() {
        return this.center.y
    }

    set region(region: number) {
        if (this.region === region)
            return

        const oldRegion = this.region
        this._region = region
        this.app.updateGround(oldRegion)
        this.app.updateGround(region)
    }
    get region() {
        return this._region
    }

    set wallThickness(wallThickness: number) {
        this._wallThickness = wallThickness
        this.reDraw()
    }
    get wallThickness() {
        return this._wallThickness
    }

    move(dx: number, dy: number) {
        this.wallContainer.x += dx
        this.wallContainer.y += dy
        /* 
                this.start.x += dx
                this.start.y += dy
        
                this.end.x += dx
                this.end.y += dy */
    }

    moveTo(x: number, y: number) {
        this.wallContainer.x = x
        this.wallContainer.y = y
    }

    select(showPointsOnSelect: boolean = true) {
        super.select()
        this.wallGraphic.tint = 0x007bff

        if (showPointsOnSelect) {
            this.start.show(true)
            this.end.show(true)
        }
    }

    unselect() {
        super.unselect()
        this.wallGraphic.tint = 0x000000

        if (this.wallWidth > 0) {
            this.start.hide()
            this.end.hide()
        }
    }

    setInteractive(interactive: boolean) {
        this.wallGraphic.interactive = interactive
    }

    moveCenter() {
        this.center.x = (this.end.x + this.start.x) / 2
        this.center.y = (this.end.y + this.start.y) / 2

        this.app.updateGround(this.region)
    }

    removeArtwork(artwork: ArtworkElement) {
        const index = this.artworks.indexOf(artwork)
        // console.log("Remove artwork", artwork.mapRefUUID, this.artworks, index)
        if (index >= 0) {
            this.artworks.splice(index, 1)
        }
    }

    addArtwork(artwork: ArtworkElement) {
        this.addChild(artwork)
        this.artworks.push(artwork)
    }

    reDraw() {
        // container
        this.wallContainer.x = this.center.x
        this.wallContainer.y = this.center.y

        this.wallContainer.angle = this.wallAngle

        // graphic
        this.wallGraphic.clear()
        this.wallGraphic.beginFill(0xffffff)/* 
        this.wallGraphic.drawRect(
            - this.wallThickness / 2 - this.wallWidth / 2,
            - this.wallThickness / 2,
            this.wallThickness + this.wallWidth,
            this.wallThickness) */
        this.wallGraphic.drawRect( // REAL
            - this.wallWidth / 2,
            - this.wallThickness / 2,
            this.wallWidth,
            this.wallThickness)
        this.wallGraphic.endFill()


        // Text
        computeText(
            this.wallDimensionsText,
            this.wallWidth.toFixed(2).toString() + "cm",
            this.wallAngle,
            this.wallWidth,
            this.wallThickness,
            ZOOM_TEXT ? 20 : 20 / this.app.currentCanvas.scale.x
        )
        computeText(
            this.wallNameText,
            this.wallName,
            this.wallAngle,
            this.wallWidth,
            this.wallThickness,
            ZOOM_TEXT ? 40 : 40 / this.app.currentCanvas.scale.x
        )

        this.artworks.forEach(aw => aw.reDraw())
    }

    movePointToSetWidth(newWidth: number, point: WallExteriorPoint = this.end) {
        // move point
        const invertAngle = point === this.end


        point.x = (invertAngle ? 1 : -1) * (newWidth) * Math.cos(this.wallAngle * Math.PI / 180)
            + point.otherPoint.position.x
        point.y = (invertAngle ? 1 : -1) * (newWidth) * Math.sin(this.wallAngle * Math.PI / 180)
            + point.otherPoint.position.y

        this.moveCenter()
    }

    setTextVisible(visible: boolean) {
        if (this.wallDimensionsText.visible === visible)
            return

        this.wallDimensionsText.visible = visible
        this.wallNameText.visible = visible
        if (visible)
            this.reDraw()
    }

    setArtworkVisible(visible: boolean) {
        this.artworks.forEach(artwork => {
            artwork.visible = visible
        });
    }

    onWindowResize() {
        if (ZOOM_TEXT) {
            this.wallDimensionsText.resolution = this.app.currentCanvas.scale.x
            this.wallNameText.resolution = this.app.currentCanvas.scale.x
        } else {
            this.wallDimensionsText.scale.set(1 / this.app.currentCanvas.scale.x)
            this.wallNameText.scale.set(1 / this.app.currentCanvas.scale.x)
        }
        this.reDraw()
    }

    destroy(options?: boolean | PIXI.IDestroyOptions | undefined): void {
        const oldRegion = this.region
        this._region = -1
        this.app.updateGround(oldRegion)
        for (let i = 0; i < this.artworks.length; i++) {
            const artwork = this.artworks[i];
            this.app.removeElement(artwork)
        }
        super.destroy(options)
    }
}