import * as PIXI from 'pixi.js'

export class GuideLine extends PIXI.Container {
    line: PIXI.Graphics
    arrowStart: PIXI.Graphics
    arrowEnd: PIXI.Graphics
    text: PIXI.Text
    readonly arrowSize = 5

    constructor() {
        super()

        this.line = new PIXI.Graphics()
            .lineStyle(1, 0xffffff)
            .lineTo(0, 1)
            .endFill()

        this.line.y = this.arrowSize
        this.addChild(this.line)

        this.arrowStart = new PIXI.Graphics()
            .beginFill(0xffffff)
            .moveTo(-this.arrowSize / 2, -this.arrowSize)
            .lineTo(this.arrowSize / 2, -this.arrowSize)
            .lineTo(0, 0)
            .lineTo(-this.arrowSize / 2, -this.arrowSize)
            .closePath()
            .endFill()

        this.addChild(this.arrowStart)

        this.arrowEnd = this.arrowStart.clone()
        this.arrowEnd.rotation = Math.PI
        this.addChild(this.arrowEnd)

        const fontSize = 10
        const textStyle: PIXI.TextStyle = new PIXI.TextStyle({
            fill: 0x404040,
            fontSize,
            stroke: 0xffffff,
            strokeThickness: 2,
        })
        this.addChild(this.text = new PIXI.Text("", textStyle))
        this.text.resolution = 4
        this.text.x = 5
        this.text.alpha = .8
        this.color = 0xcccccc
    }

    set height(height: number) {
        this.text.y = height / 2
        this.arrowStart.y = height
        this.line.height = height - this.arrowSize * 2
    }

    get height() {
        return this.arrowStart.y
    }

    set color(color: number) {
        this.arrowEnd.tint = color
        this.arrowStart.tint = color
        this.line.tint = color
    }
}