import { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setPropertyBlurryHaloPr, setPropertyColor, setPropertyIntensity, setPropertyName, setPropertyOpeningAngle, setPropertyOrientation, setPropertyVerticalAngle, setPropertyX, setPropertyY } from "../../../store/reducers/FloorPlan/FloorPlan";
import { Light } from "../../../types/FloorPlan/Light.types";
import { ComponentOrientations, ComponentOrientationsToEngText } from "../../../types/FloorPlan/OrientableComponent.types";
import { NumberPropertyInput } from "../Properties/NumberPropertyInput";
import { ColorPropertyInput } from "../Properties/ColorPropertyInput";
import { PropertyInput } from "../Properties/PropertyInput";
import { SelectPropertyInput } from "../Properties/SelectPropertyInput";
import { ButtonPropertyInput } from "../Properties/ButtonPropertyInput";
import { InspectorBody } from "../Layout/inspectorBody";
import { InspectorFooter } from "../Layout/inspectorFooter";
import { confirmDeleteModalOpen } from "../../../store/reducers/UI";
import { ImageInfoPanel } from "../Properties/InfoPanels";
import { DefaultInspectorOptions } from "../Components/defaultInspectorOptions";
import WarningIcon from '@mui/icons-material/Warning';
import { Box } from "@mui/material";

export function LightsSelectedInspector({ canDelete = true }: { canDelete?: boolean }) {

    const allLights = useAppSelector(state => state.floorPlan.floorPlan?.lights)
    const selectedData = useAppSelector(state => state.editor.selectedData)

    const [lights, setLights] = useState<Light[]>([])

    const dispatch = useAppDispatch()
    /* 
        const lights: Light[] = []
        
        if (allLights) {
            selectedData.filter((e) => e.type === "Light").forEach(element => {
                const light = allLights[element.id]
                if (light)
                    lights.push(light)
                else
                    console.warn(element.id + " not found as a light")
            })
        } */
    useEffect(() => {
        if (allLights) {
            const newLights: Light[] = []
            selectedData.filter((e) => e.type === "Light").forEach(element => {
                const light = allLights[element.id]
                if (light)
                    newLights.push(light)
                else
                    console.warn(element.id + " not found as a light")
            })
            setLights(newLights)
        }
    }, [allLights, selectedData])

    // useEffect(() => console.log("RRELOAD ALL LIGHTS"), [])


    return (
        <Fragment>
            <InspectorBody>
                {
                    lights.reduce<boolean>((pr, cr) => pr === cr.warning ? pr : false, lights[0] ? lights[0].warning : false) &&
                    <Box
                        sx={{
                            position: "absolute",
                            marginTop: "-40px",
                            right: "40px"
                        }}
                    >
                        <WarningIcon titleAccess={lights[0]?.warningText || ""} color="warning" />
                    </Box>
                }
                <PropertyInput
                    propertyName="name"
                    label="Name"
                    objects={lights}
                    onValueChange={(name: string) => {
                        selectedData.forEach(data => {
                            dispatch(setPropertyName({
                                type: data.type,
                                id: data.id,
                                name
                            }))
                        })
                    }}
                />
                <NumberPropertyInput
                    propertyName={"x"}
                    label="X"
                    objects={lights}
                    onValueChange={(x) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyX({
                                type: data.type,
                                id: data.id,
                                x
                            }))
                        })
                    }
                />
                <NumberPropertyInput
                    propertyName={"y"}
                    label="Y"
                    objects={lights}
                    onValueChange={(y) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyY({
                                type: data.type,
                                id: data.id,
                                y
                            }))
                        })
                    }
                />

                <SelectPropertyInput<ComponentOrientations, "orientation">
                    keyValue={ComponentOrientationsToEngText}
                    propertyName="orientation"
                    label="Orientation"
                    objects={lights}
                    onValueChange={(orientation) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyOrientation({
                                type: data.type,
                                id: data.id,
                                orientation
                            }))
                        })
                    }
                />


                <NumberPropertyInput
                    propertyName={"intensity"}
                    label="Intensity"
                    objects={lights}
                    onValueChange={(intensity) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyIntensity({
                                type: data.type,
                                id: data.id,
                                intensity
                            }))
                        })
                    }
                    min={0}
                    max={100}
                    step={5}
                    floatDecimals={0}
                    transformOperation={(a) => a * 100}
                    inverseTransformOperation={(a) => a / 100}
                    endAdornment="%"
                    info={<ImageInfoPanel imageSrc="https://d22j8f47u6lqy6.cloudfront.net/gifs/cbecf09e55fbf5ebe7bda789cb710aec.gif" description="Set the intensity of the light" defaultValue="60%" />}
                />
                <NumberPropertyInput
                    propertyName={"blurryHaloPr"}
                    label="Blurry Halo"
                    objects={lights}
                    onValueChange={(blurryHaloPr) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyBlurryHaloPr({
                                type: data.type,
                                id: data.id,
                                blurryHaloPr
                            }))
                        })
                    }
                    floatDecimals={0}
                    min={0}
                    max={100}
                    step={5}
                    endAdornment="%"
                    info={<ImageInfoPanel imageSrc="https://d22j8f47u6lqy6.cloudfront.net/gifs/0a2c6bc69d52b64ef7affe8bde3d70e7.gif" description="Set the strenght of the halo" defaultValue="50%" />}
                />

                <NumberPropertyInput
                    propertyName={"openingAngle"}
                    label="Radius"
                    objects={lights}
                    onValueChange={(openingAngle) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyOpeningAngle({
                                type: data.type,
                                id: data.id,
                                openingAngle
                            }))
                        })
                    }
                    min={10}
                    max={120}
                    step={5}
                    endAdornment="º"
                    info={<ImageInfoPanel imageSrc="https://d22j8f47u6lqy6.cloudfront.net/gifs/1167208e3ac4351a19285ef54d52e0bc.gif" description="The opening angle of the light" defaultValue="70º" />}
                />
                <NumberPropertyInput
                    propertyName={"verticalAngle"}
                    label="Vertical angle"
                    objects={lights}
                    onValueChange={(verticalAngle) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyVerticalAngle({
                                type: data.type,
                                id: data.id,
                                verticalAngle
                            }))
                        })
                    }
                    min={0}
                    max={90}
                    step={5}
                    endAdornment="º"
                    info={<ImageInfoPanel imageSrc="https://d22j8f47u6lqy6.cloudfront.net/gifs/fc4372231b7c6a720aa1c1fa6ec846b3.gif" description="The vertical angle of the light" defaultValue="45º" />}
                />

                <ColorPropertyInput
                    propertyName="color"
                    objects={lights}
                    onValueChange={(color: string) => {
                        selectedData.forEach(data => {
                            dispatch(setPropertyColor({
                                type: data.type,
                                color,
                                id: data.id
                            }))
                        })
                    }}
                    info={<ImageInfoPanel imageSrc="https://d22j8f47u6lqy6.cloudfront.net/gifs/8067fce81217e6e957067d4ee688b7b9.gif" defaultValue="#FFFFFF" />}
                />


                {/* 
            <div className={clsx('input-box', { error: false })}>
                <label>Real intensity</label>
                <Input
                    id="light-name"
                    type="text"
                    value={lights.reduce<number | "-">((pr, cr) => pr === cr.intensity ? pr : "-", lights[0]?.intensity || "-")}
                    // error={formErrors.width}
                    onChange={(event: any) => {
                        const { value } = event.target;
                    }}
                />
            </div> */}

            </InspectorBody>

            <InspectorFooter>
                <ButtonPropertyInput
                    onClick={() => {
                        dispatch(confirmDeleteModalOpen())
                    }}
                    color={"error"}
                    variant={"contained"}
                    disabled={!canDelete}
                >
                    <span>Delete</span>
                </ButtonPropertyInput>
                <DefaultInspectorOptions />
            </InspectorFooter>
        </Fragment >
    )
} 