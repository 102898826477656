import { useEffect, useState } from "react";
import { BasePropertyInput } from "./BasePropertyInput";
import { PropertyInputLabel } from "./PropertyInputLabel";

export function ColorPropertyInput<K extends string>(
    {
        propertyName,
        label,
        objects,
        onValueChange,
        disabled,
        title,
        error,
        info,
        endAdornment
    }: {
        propertyName: K
        label?: string
        objects: { [key in typeof propertyName]: string }[]
        onValueChange: (a: string) => void
        disabled?: boolean
        error?: boolean
        info?: React.ReactNode
        title?: string
        endAdornment?: React.ReactNode
    }) {
    const [val, setVal] = useState<string | "-">("-")
    useEffect(() => {
        setVal(objects.reduce<string | "-">((pr, cr) => pr === cr[propertyName] ? pr : "-", objects[0] ? objects[0][propertyName] : "-"))
    }, [objects, propertyName])

    return (
        <BasePropertyInput disabled={disabled} error={error}>            
            <PropertyInputLabel content={info}>{label ? label : "Color"}</PropertyInputLabel>
            <div className='color-picker-container'>
                <input
                    className="input-text"
                    value={val}
                    maxLength={7}
                    onChange={(e: any) => {
                        const { value } = e.target;
                        onValueChange(value)
                        setVal(value)
                    }}
                    type="text"
                    disabled={disabled}
                ></input>
                <input
                    className="input-color"
                    type="color"
                    value={val === "-" ? "#ffffff" : val}
                    onChange={(e: any) => {
                        const value = `${e.target.value}`
                        onValueChange(value)
                        setVal(value)
                    }}
                    disabled={disabled}
                ></input>
                {endAdornment && endAdornment}
            </div>
        </BasePropertyInput>
    )
}