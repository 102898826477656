import { FloorPlanData } from "./FloorPlanElement.types";

export type ComponentOrientations = 'TOP' | 'TOP_RIGHT' | 'RIGHT' | 'BOTTOM_RIGHT' | 'BOTTOM' | 'BOTTOM_LEFT' | 'LEFT' | 'TOP_LEFT'

export const ComponentOrientationsToEngText = {
    TOP: "Up",
    BOTTOM: "Down",
    RIGHT: "Right",
    LEFT: "Left",
    TOP_LEFT: "Up Left",
    TOP_RIGHT: "Up Right",
    BOTTOM_RIGHT: "Down Right",
    BOTTOM_LEFT: "Down Left"
}

export function orientationToRad(orientation: ComponentOrientations) {
    const PI_4 = Math.PI / 4
    switch (orientation) {
        case "TOP":
            return 0
        case "TOP_RIGHT":
            return PI_4
        case "RIGHT":
            return PI_4 * 2
        case "BOTTOM_RIGHT":
            return PI_4 * 3
        case "BOTTOM":
            return PI_4 * 4
        case "BOTTOM_LEFT":
            return PI_4 * 5
        case "LEFT":
            return PI_4 * 6
        case "TOP_LEFT":
            return PI_4 * 7
        default:
            return 0
    }
}



export class OrientableComponent extends FloorPlanData {
    orientation: ComponentOrientations

    constructor(mapRefUUID?: string) {
        super(mapRefUUID)

        this.orientation = "TOP"
    }
}
