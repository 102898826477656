
import { useEffect } from 'react'

import { useSearchParams } from 'react-router-dom';
import { getExhibitionRequest, setExhibitionStatus } from '../store/reducers/Exhibition';
import { useAppDispatch, useAppSelector } from '../hooks';
import { getFloorPlanRequest } from '../store/reducers/FloorPlan/FloorPlan';
import { getArtworksRequest } from '../store/reducers/Artworks';

export function useFloorPlanMapData() {

    const [searchParams] = useSearchParams();

    const dispatch = useAppDispatch()

    // get exhibition
    const exhibitionUuid = searchParams.get("uuid")
    useEffect(() => {
        if (exhibitionUuid) {
            dispatch(getExhibitionRequest(exhibitionUuid))
            dispatch(getArtworksRequest(exhibitionUuid))
        } else
            dispatch(setExhibitionStatus("Error_NoUuidProvided"))
    }, [exhibitionUuid, dispatch]);

    // get floor plan map
    const mapUuid = useAppSelector((state) => state.exhibition.exhibition?.mapUuid)
    const exhibitionTitle = useAppSelector((state) => state.exhibition.exhibition?.title)
    useEffect(() => {
        if (mapUuid)
            dispatch(getFloorPlanRequest(mapUuid))
    }, [mapUuid, dispatch]);

    const authStatus = useAppSelector(state => state.auth.status)
    const exhibitionStatus = useAppSelector(state => state.exhibition.exhibitionStatus)

    // console.log("useFloorPlanMap")

    var loadingPr: number = 0
    var loadingText: string = ""
    if (authStatus === "valid_token") {
        loadingPr += 40
        loadingText = "Authentification completed!"
    }
    if (mapUuid) {
        loadingPr += 30
        loadingText = "Exhibition " + exhibitionTitle + " Loaded!"
    }
    if (exhibitionStatus === "Success_Map") {
        loadingPr += 30
        loadingText = "Floor Plan Loaded!"
    }

    return { exhibitionStatus, loadingPr, loadingText }
}