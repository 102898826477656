import logoExpanded from './../assets/img/logo-expanded.svg'
import { Fragment } from 'react'

export default function FullScreenPage({ title, subtitle, children }: { title: string, subtitle?: string, children?: React.ReactNode }) {
    return (
        <div className="full-screen-container">
            <div className="dots-pattern"></div>
            <div className="header">
                <img src={logoExpanded} alt="Exhibify" className="logo" />
            </div>
            <div className="centered-wrapper">
                <h1>
                    {title}
                </h1>
                {subtitle &&
                    <Fragment>
                        <br />
                        <h2>
                            {subtitle}
                        </h2>
                    </Fragment>
                }
                {children &&
                    <Fragment>
                        <br />
                        {children}
                    </Fragment>
                }
            </div>
        </div>

    )
}