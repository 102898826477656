import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { ArtworksState } from "../../types/Artworks.types";
import { ArtworkData, ArtworkMediaType } from "../../types/FloorPlan/Artwork.types";
import { SculptureFileFormat } from "../../types/FloorPlan/Sculpture.types";
// import { RootState } from "../../store";

const initialState: ArtworksState = { artworks: {} }

export const artworks = createSlice({
    name: "exhibition",
    initialState,
    reducers: {
        getArtworksRequest: (state, action: PayloadAction<string>) => { },
        setArtworks: (state, { payload }: PayloadAction<{ [id: string]: ArtworkData }>) => {
            // console.log("setArtworks", payload)
            state.artworks = { ...payload }
        },
        updateArtwork: (state, { payload }: PayloadAction<{ id: string, data: ArtworkData }>) => {
            const { id, data } = payload
            // console.log("updateArtwork", payload, state.artworks[id])

            state.artworks[id] = data
            // console.log("updateArtwork after", payload, state.artworks[id])

        },
        checkArtworkStatus: (state, { payload }: PayloadAction<{ type: ArtworkMediaType, artworkUuid: string, mediaUuid: string }>) => { },
        changeSculptureFileFormat: (state, { payload }: PayloadAction<{ artworkUuid: string, sculptureFormat: SculptureFileFormat }>) => { }
    }
})

export const selectImageArtworks = (state: RootState) => Object.fromEntries(Object.entries(state.artworks.artworks).filter(a => a[1].mediaType === "IMAGE" || a[1].mediaType === "VIDEO"))
export const selectSculputreArtworks = (state: RootState) => Object.fromEntries(Object.entries(state.artworks.artworks).filter(a => a[1].mediaType === "SCULPTURE"))

export const { setArtworks, getArtworksRequest, updateArtwork, checkArtworkStatus, changeSculptureFileFormat } = artworks.actions

export default artworks.reducer