import * as PIXI from 'pixi.js'
import { FloorPlanEditorSetting } from '../../../../types/FloorPlan/FloorPlan.types'
import { FloorPlanElement } from '../components/floorPlanElement'
import { EntranceElement } from '../components/orientableFloorPlanElements/entranceElement'
import { SpotElement } from '../components/orientableFloorPlanElements/spotElement'
import { LightElement } from '../components/orientableFloorPlanElements/lightElement'
import { WallElement } from '../components/wallElement'
import { SculptureElement } from '../components/orientableFloorPlanElements/sculptureElement'
import { ArtworkElement } from '../components/artworkElement'

export class MainLayer extends PIXI.Container {
    walls: PIXI.Container<WallElement>
    lights: PIXI.Container<LightElement>
    spots: PIXI.Container<SpotElement>
    sculptures: PIXI.Container<SculptureElement>
    entrance: PIXI.Container<EntranceElement>

    selectedElements: PIXI.Container

    selectedArtworkUuid?: string

    constructor() {
        super()


        this.walls = new PIXI.Container()
        this.addChild(this.walls)

        this.lights = new PIXI.Container()
        this.addChild(this.lights)

        this.spots = new PIXI.Container()
        this.addChild(this.spots)

        this.sculptures = new PIXI.Container()
        this.addChild(this.sculptures)

        this.entrance = new PIXI.Container()
        this.addChild(this.entrance)

        this.selectedElements = new PIXI.Container()
        this.addChild(this.selectedElements)
    }


    setVisible(setting: FloorPlanEditorSetting, visible: boolean) {
        //TODO: OPTIMISE
        switch (setting) {
            case 'lightsVisible':
                if (visible)
                    this.addChildAt(this.lights, 0)
                else
                    this.removeChild(this.lights)
                break;
            case 'spotsVisible':
                if (visible)
                    this.addChildAt(this.spots, 0)
                else
                    this.removeChild(this.spots)
                break;
            case 'artworksVisible':
                this.walls.children
                    .forEach((w) => {
                        w.setArtworkVisible(visible)
                    })

                this.selectedElements.children
                    .forEach((e) => {
                        if (e instanceof WallElement)
                            e.setArtworkVisible(visible)
                    })

                break;
            case 'dimensionsVisible':
                this.walls.children
                    .forEach((w) => {
                        w.setTextVisible(visible)
                    })

                this.selectedElements.children
                    .forEach((e) => {
                        if (e instanceof WallElement)
                            e.setTextVisible(visible)
                    })

                break;
/* 
            case 'ruler':
                this.ruler.visible = visible */
        }
    }

    onSelectChange() {
        this.setSelectedArtwork()
    }

    setSelectedArtwork(artworkUUID?: string) {
        if (!this.selectedArtworkUuid && !artworkUUID)
            return

        this.selectedArtworkUuid = artworkUUID

        this.walls.children.forEach(wall => {
            wall.artworks.forEach(artwork => {
                artwork.setHighlight(!!artworkUUID && artwork.artworkData.mapRefUUID === artworkUUID);
            })
        })
    }

    onWindowResize() {

        this.walls.children
            .forEach((w) => {
                w.onWindowResize()
            })

        this.selectedElements.children
            .forEach((e) => {
                if (e instanceof WallElement)
                    e.onWindowResize()
            })
    }

    addElement(element: FloorPlanElement) {
        if (!element || element === null)
            return

        // console.log(this, element)

        if (element instanceof WallElement)
            this.walls.addChild(element)
        else if (element instanceof LightElement)
            this.lights.addChild(element)
        else if (element instanceof SpotElement)
            this.spots.addChild(element)
        else if (element instanceof SculptureElement)
            this.sculptures.addChild(element)
        else if (element instanceof EntranceElement)
            this.entrance.addChild(element)
        else if (element instanceof ArtworkElement) { }
        else
            console.warn("adding unknown element to scene: " + element.constructor)
    }
}