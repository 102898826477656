import { useState, useEffect, Fragment } from "react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step, TooltipRenderProps } from "react-joyride";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setIsHelpRunning, setSelectData, setTool } from "../../store/reducers/Editor";
import { Box } from '@mui/material';
import { ToolType } from "../../types/Editor/Tool.types";
import CloseIcon from '@mui/icons-material/Close';
import { FloorPlanDataType } from "../../types/FloorPlan/FloorPlan.types";
import { RootState } from "../../store";
import { open3dPreview } from "../../store/reducers/Exhibition";
import { Button } from "../../styles/MUI/Button";


type NextStateConditions = false
    | { condition: "Select Tool", tool: ToolType }
    | { condition: "Walls Change" }
    | { condition: "Lights Change" }
    | { condition: "Select Wall Side" }
    | { condition: "ESC" }
    | { condition: "Select Data", data: FloorPlanDataType }
    | { condition: "Spots Change" }

interface CustomStep extends Step {
    targetInteractive?: boolean,
    nextStateCondition?: NextStateConditions,
    size?: "small",
    setTool?: ToolType
}

interface CustomCallBackProps extends CallBackProps {
    step: CustomStep
}

interface CustomTooltipRenderProps extends TooltipRenderProps {
    step: CustomStep
}

function DefaultTooltip({
    backProps,
    index,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
    isLastStep
}: CustomTooltipRenderProps) {
    const dispatch = useAppDispatch()

    return (
        <Box
            my={1}
            {...tooltipProps}
            maxWidth={step.size === "small" ? 187 : 420}
            minWidth={step.size === "small" ? 30 : 350}
            overflow="hidden"
            bgcolor="white"
            borderRadius={1}
            textAlign={"center"}
            position="relative"
        >
            <Button {...skipProps} sx={{ minWidth: 0, position: "absolute", right: 0, margin: 1 }} size="small" variant="text" color="primary" title="Exit step by step tutorial">
                <CloseIcon sx={{ fontSize: 20 }} />
            </Button>
            {step.title && (
                <Box paddingTop={2} >
                    <span style={{ color: "gray" }}>
                        {step.title}
                    </span>
                </Box>
            )}
            <Box p={4} >
                {step.content && <span style={{ lineHeight: 1.5 }}>{step.content}</span>}
            </Box>
            <Box p={2} paddingTop={0} display={"flex"} justifyContent={"space-between"}>
                <Button {...backProps} sx={{ minWidth: 0 }} variant="text" color="primary" style={{ marginRight: 5 }} disabled={index === 0}>
                    <span>Back</span>
                </Button>
                {isLastStep &&
                    <Button sx={{ minWidth: 0 }} onClick={() => dispatch(open3dPreview())} variant="contained" color="primary">
                        <span>Open in 3D</span>
                    </Button>
                }
                {(index === 0) &&
                    <Button sx={{ minWidth: 0 }}{...skipProps} variant="contained" color="primary">
                        <span>Skip</span>
                    </Button>
                }
                <Button {...primaryProps} sx={{ minWidth: 0 }} variant="contained" color="info" disabled={!!step.nextStateCondition}>
                    <span>{isLastStep ? "Thanks!" : "Next"}</span>
                </Button>
            </Box>
        </Box >
    );
}

const defaultStep = {
    disableBeacon: true,
    tooltipComponent: DefaultTooltip
}
const stepTargetInteractive = {
    disableOverlayClose: true,
    targetInteractive: true,
    disableCloseOnEsc: true,
}

const steps: CustomStep[] = [
    // PANELS
    {
        ...defaultStep,
        placement: "center",
        target: '.body',
        title: "FLOOR PLAN INSTRUCTIONS",
        content: (
            <div style={{ textAlign: "left", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p>Wellcome to the tutorial.</p>
                <p>We will learn how to</p>
                <div style={{ marginTop: 10 }}>
                    <li>Draw walls</li>
                    <li>Place artwork</li>
                    <li>Place lights</li>
                    <li>Place viewing spots</li>
                    <li>Set an entrance</li>
                </div>
            </div>
        ),
    },
    {
        ...defaultStep,
        placement: "right",
        target: '.tools-panel',
        content: "This is the tool panel.",
    },
    {
        ...defaultStep,
        target: '.inspector',
        content: "This is the inspector.",
        placement: "left",
    },
    {
        ...defaultStep,
        target: '.inspector',
        content: "From here you will be able to edit the properties of the exposition and its elements.",
        placement: "left"
    },
    {
        ...defaultStep,
        target: '.app-canvas',
        content: "This is the canvas.",
        placement: "right",
        size: "small",
    },

    // DRAW WALLS
    {
        ...defaultStep,
        ...stepTargetInteractive,
        placement: "right",
        target: '.tools-panel',
        content: 'Start by selecting the "Draw Walls" tool.',
        nextStateCondition: { condition: "Select Tool", tool: "DrawWalls" },
        setTool: "Help"
    },
    {
        ...defaultStep,
        target: '.app-canvas',
        content: 'Good! Now let\'s draw some walls.',
        placement: "right",
        size: "small",
        setTool: "DrawWalls",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.app-canvas',
        content: 'Click anywere on the canvas to draw a wall.',
        placement: "right",
        nextStateCondition: { condition: "Walls Change" },
        size: "small",
        setTool: "DrawWalls",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.app-canvas',
        content: 'Perfect! You can exit at any time by pressing "ESC".',
        placement: "right",
        disableCloseOnEsc: false,
        nextStateCondition: { condition: "ESC" },
        size: "small",
        setTool: "DrawWalls",
    },
    {
        ...defaultStep,
        target: '.tool-button-Select',
        content: 'Now the "Select" tool is active.',
        placement: "right",
        setTool: "Select",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.app-canvas',
        content: 'Select a wall to edit its properties.',
        placement: "right",
        nextStateCondition: { condition: "Select Data", data: "Wall" },
        size: "small",
        setTool: "Select",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.inspector',
        content: "You can now edit the properties of this wall in the inspector.",
        placement: "left",
        setTool: "Select",
    },

    // PLACE ARTWORKS
    {
        ...defaultStep,
        target: '.tool-button-PlaceArtworks',
        content: "Now we will put artwork on a wall.",
        placement: "right",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        placement: "right",
        target: '.tools-panel',
        content: 'Click on the "Place Artowrks" tool.',
        nextStateCondition: { condition: "Select Tool", tool: "PlaceArtworks" },
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.app-canvas',
        content: 'Select the wall to put the artwork.',
        placement: "right",
        nextStateCondition: { condition: "Select Data", data: "Wall" },
        size: "small",
        setTool: "PlaceArtworks",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.app-canvas',
        content: 'Good! Now select the side that you want the artwork to be on.',
        placement: "right",
        nextStateCondition: { condition: "Select Wall Side" },
        size: "small",
        setTool: "PlaceArtworks",
    },
    {
        ...defaultStep,
        target: '.inspector',
        content: 'Now, in the inspector, you can see the artworks avaliable.',
        placement: "left",
        setTool: "PlaceArtworks",
    },
    {
        ...defaultStep,
        target: '.inspector',
        content: 'Drag an artwork to the wall.',
        placement: "left-end",
        disableOverlay: true,
        setTool: "PlaceArtworks",
    },
    {
        ...defaultStep,
        target: '.inspector-header',
        content: 'You can go back with this button.',
        placement: "left",
        setTool: "PlaceArtworks",
    },

    // LIGHTS
    {
        ...defaultStep,
        target: '.tool-button-Lights',
        content: "Let's place some lights!",
        placement: "right",
        setTool: "Select"
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        placement: "right",
        target: '.tools-panel',
        content: 'Click on the "Lights" tool.',
        nextStateCondition: { condition: "Select Tool", tool: "Lights" },
        setTool: "Select"
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.inspector',
        content: 'You can edit the properties of the lights that you are going to put in the inspector.',
        placement: "left",
        setTool: "Lights",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        placement: "right",
        target: '.app-canvas',
        content: 'Click anywhere to place them.',
        nextStateCondition: { condition: "Lights Change" },
        size: "small",
        setTool: "Lights",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        placement: "right",
        target: '.app-canvas',
        content: 'Good! Place as many as you want. When you are done, exit with "ESC".',
        nextStateCondition: { condition: "ESC" },
        size: "small",
        setTool: "Lights",
        disableCloseOnEsc: false
    },
    {
        ...defaultStep,
        target: '.tool-button-Select',
        content: 'Now the "Select" tool is active.',
        placement: "right",
        setTool: "Select",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        placement: "right",
        target: '.app-canvas',
        content: 'You can now adjust their position and orientation.',
        size: "small",
        setTool: "Select",
    },


    // SPOTS
    {
        ...defaultStep,
        target: '.tool-button-Spots',
        content: "Perfect! Now lets create a Viewing Spot. These will appear on the floor, and when the viewer clicks on them, they will be moved there.",
        placement: "right",
        setTool: "Select"
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        placement: "right",
        target: '.tools-panel',
        content: 'Click on the "Spots" tool.',
        nextStateCondition: { condition: "Select Tool", tool: "Spots" },
        setTool: "Select"
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        placement: "right",
        target: '.app-canvas',
        content: 'Click anywhere to place a spot.',
        nextStateCondition: { condition: "Spots Change" },
        size: "small",
        setTool: "Spots",
    },
    {
        ...defaultStep,
        target: '.tool-button-Select',
        content: 'Let\'s select the spot we just created.',
        placement: "right",
        setTool: "Spots",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.tools-panel',
        content: 'Click on the "Select" tool or press "ESC".',
        nextStateCondition: { condition: "Select Tool", tool: "Select" },
        placement: "right-start",
        setTool: "Spots",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.app-canvas',
        content: 'Select the spot.',
        nextStateCondition: { condition: "Select Data", data: "Spot" },
        placement: "right",
        size: "small",
        setTool: "Select",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.inspector',
        content: 'This are the properties of the spot.',
        placement: "left",
        setTool: "Select",
    },
    {
        ...defaultStep,
        target: '.Type-Selector',
        content: (
            <Fragment>
                <p>There are two types of Spots:</p>
                <br />
                <li>Artwork: The viewer will look at an artwork</li>
                <li>Movement: The viewer will look at the specified direction</li>
            </Fragment>
        ),
        placement: "left",
        setTool: "Select",
    },
    {
        ...defaultStep,
        target: '.preview-image-list',
        content: 'Here you can select the Artwork that it is pointing at.',
        placement: "left",
        setTool: "Select",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.inspector',
        content: 'Select the Artwork that we have put previously.',
        placement: "left",
        setTool: "Select",
    },

    // ENTRANCE
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.tool-button-Entrance',
        content: (
            <Fragment>
                <p>Good!</p>
                <p>Finally we will set the entrance of the exhibition.</p>
                <p>This will be the starting position of the viewer.</p>
            </Fragment>
        ),
        placement: "right",
        setTool: "Select",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        target: '.tools-panel',
        content: 'Click on the "Entrance" tool.',
        nextStateCondition: { condition: "Select Tool", tool: "Entrance" },
        placement: "right-start",
        setTool: "Select",
    },
    {
        ...defaultStep,
        ...stepTargetInteractive,
        placement: "right",
        target: '.app-canvas',
        content: 'Place the entrance and edit its direction.',
        size: "small",
        setTool: "Entrance",
    },

    // Congratulations
    {
        ...defaultStep,
        target: 'body',
        content: (<Fragment><p>Congratulations on finishing your first</p><p>Exhbify Exhibition.</p></Fragment>),
        placement: "center",
        setTool: "Help",
    },
]

const EmptyElement = <div style={{ display: "none" }}></div>

function WatchChangeTool({ tool, onConditionMeet }: { tool: ToolType, onConditionMeet: () => void }) {
    const currentTool = useAppSelector(s => s.editor.currentTool)

    useEffect(() => {
        if (currentTool.type === tool) {
            onConditionMeet()
        }
    }, [currentTool, onConditionMeet, tool])

    return EmptyElement
}

function WatchSelectWallSide({ onConditionMeet }: { onConditionMeet: () => void }) {
    const currentTool = useAppSelector(s => s.editor.currentTool)

    useEffect(() => {
        if (currentTool.type === "PlaceArtworks" && currentTool.sideSelected) {
            onConditionMeet()
        }
    }, [currentTool, onConditionMeet])

    return EmptyElement
}

function WatchSelectData({ onConditionMeet, dataType }: { onConditionMeet: () => void, dataType: FloorPlanDataType }) {
    const selectedData = useAppSelector(s => s.editor.selectedData)

    useEffect(() => {
        if (selectedData[0]?.type === dataType)
            onConditionMeet()
    }, [selectedData, onConditionMeet, dataType])

    return EmptyElement
}

function WatchNumberChange({ onConditionMeet, numberSelector }: { onConditionMeet: () => void, numberSelector: (s: RootState) => number }) {
    const number = useAppSelector(numberSelector)
    const [initialNum] = useState<number>(number)

    useEffect(() => {
        if (initialNum !== number)
            onConditionMeet()
    }, [number, initialNum, onConditionMeet])

    return EmptyElement
}


export function StepByStepHelp() {
    const isHelpRunning = useAppSelector(state => state.editor.isHelpRunning)
    const currentTool = useAppSelector(state => state.editor.currentTool)

    const dispatch = useAppDispatch()

    const [stepIndex, setStepIndex] = useState(0)

    const [nextStepCondition, setNextStepCondition] = useState<NextStateConditions>(false)

    const nextStep = () => {
        setStepIndex(i => i + 1)
        setNextStepCondition(false)
    }


    const handleJoyrideCallback = (data: CustomCallBackProps) => {
        const { status, type, step, index, action } = data
        // console.log("callback", data)

        // END
        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
            dispatch(setIsHelpRunning(false))
            setNextStepCondition(false)
            setStepIndex(0)

            // reset element zIndex
            if (step.targetInteractive) {
                const element = typeof (step.target) === "string" ? document.querySelector(step.target) : step.target
                if (element instanceof HTMLElement)
                    element.style.zIndex = ""
            }
            return
        }

        if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
            if (action === ACTIONS.PREV)
                setStepIndex(index - 1)
            else
                setStepIndex(index + 1)
        }

        if (step.targetInteractive) {
            const element = typeof (step.target) === "string" ? document.querySelector(step.target) : step.target
            if (element instanceof HTMLElement) {
                if (type === EVENTS.TOOLTIP) {
                    element.style.zIndex = Number(element.style.zIndex) + 10000 + ""
                }
                if (type === EVENTS.STEP_AFTER) {
                    element.style.zIndex = ""
                }
            }
        }

        if (type === EVENTS.TOOLTIP) {
            if (step.nextStateCondition)
                setNextStepCondition(step.nextStateCondition)

            if (step.setTool)
                if (currentTool.type !== step.setTool)
                    dispatch(setTool(step.setTool))

            if (step.nextStateCondition && step.nextStateCondition.condition === "Select Data")
                dispatch(setSelectData([]))
        }
    }

    return (
        <Fragment>
            < Joyride
                continuous
                scrollToFirstStep
                steps={steps}
                run={isHelpRunning}
                callback={handleJoyrideCallback}
                showSkipButton
                hideCloseButton
                disableScrolling
                stepIndex={stepIndex}
                disableScrollParentFix
            />
            {nextStepCondition && nextStepCondition.condition === "Select Tool" &&
                < WatchChangeTool onConditionMeet={nextStep} tool={nextStepCondition.tool} />
            }
            {nextStepCondition && nextStepCondition.condition === "Select Data" &&
                <WatchSelectData onConditionMeet={nextStep} dataType={nextStepCondition.data} />
            }
            {nextStepCondition && nextStepCondition.condition === "Select Wall Side" &&
                <WatchSelectWallSide onConditionMeet={nextStep} />
            }
            {nextStepCondition && nextStepCondition.condition === "Walls Change" &&
                <WatchNumberChange
                    onConditionMeet={nextStep}
                    numberSelector={s => s.floorPlan.floorPlan?.walls ? Object.values(s.floorPlan.floorPlan.walls).length : 0}
                />
            }
            {nextStepCondition && nextStepCondition.condition === "Lights Change" &&
                <WatchNumberChange
                    onConditionMeet={nextStep}
                    numberSelector={s => s.floorPlan.floorPlan?.lights ? Object.values(s.floorPlan.floorPlan.lights).length : 0}
                />
            }
            {nextStepCondition && nextStepCondition.condition === "Spots Change" &&
                <WatchNumberChange
                    onConditionMeet={nextStep}
                    numberSelector={s => s.floorPlan.floorPlan?.spots ? Object.values(s.floorPlan.floorPlan.spots).length : 0}
                />
            }
        </Fragment>
    )
}