import * as PIXI from 'pixi.js'
import { WallSide } from '../../../../types/FloorPlan/Wall.types'
import { DndWall } from '../artworkDnd/dndWall'
import { ArtworkElement } from '../components/artworkElement'
import { WallElement } from '../components/wallElement'
import { CanvasBase } from './canvasBase'
import FloorPlanConnector from '../floorPlanConnector'

export class ArtworkDndCanvas extends CanvasBase {
    private dndWall?: DndWall
    private backgroundGraphic: PIXI.Graphics

    constructor() {
        super()

        this.backgroundGraphic = new PIXI.Graphics()
            .clear()
            .beginFill(0xe0e0e0)
            .drawRect(-10000, -10000, 20000, 20000)
            .endFill()

        this.backgroundGraphic.interactive = true
        this.backgroundGraphic.on("pointerdown", (e) => {
            this.app.onClick(e)
        })

        this.addChild(this.backgroundGraphic)

        this.center()

        this.show()
    }


    destroy() {
        this.clear()
        this.backgroundGraphic.off("pointerdown")
        super.destroy()
    }

    center() {
        this.x = this.app.getAppWidth() / 2
        this.y = this.app.getAppHeight() / 2

        if (!this.dndWall)
            return


        this.x -= this.dndWall.width / 2
        this.y -= this.dndWall.height / 2
    }

    show() {
        this.alpha = 1
        this.interactiveChildren = true
    }

    hide() {
        this.alpha = 0
        this.interactiveChildren = false
    }

    hideGuidelines() {
        if (this.dndWall) this.dndWall.hideGuideLines()
    }


    showWall(wall: WallElement, side: WallSide) {
        // console.log("Show wall", wall)
        this.clear()

        // console.log("eyeLineHeight", this.app.eyeLineHeight)
        this.dndWall = new DndWall(wall.wallWidth, this.app.wallsHeight, this.app.eyeLineHeight)
        this.addChild(this.dndWall)

        wall.artworks.forEach(artwork => {
            if (artwork.artworkData.side === side)
                this.dndWall?.addArtwork(artwork.artworkData)
        })

        this.center()
    }

    clear() {
        // TODO: clear artworks 

        if (this.dndWall) {
            this.removeChild(this.dndWall)
            this.dndWall.destroy({ children: true })
        }
    }

    moveTo(x: number, y: number) {
        /* 
                const minX = 0
                const minY = 0
        
                const maxX = this.app.domElement?.offsetWidth
                const maxY = this.app.domElement?.offsetHeight
        
                if (x < minX)
                    x = minX
                if (x > maxX)
                    x = maxX
        
                if (y < minY)
                    y = minY
                if (y > maxY)
                    y = maxY */

        super.moveTo(x, y)
    }

    update(artworkId: string) {
        if (!this.dndWall)
            return

        const artwork = this.app.floorPlanElements[artworkId] as ArtworkElement

        if (artwork)
            this.dndWall.updateArtwork(artwork.artworkData)
        else
            this.dndWall.removeArtwork(artworkId)
    }

    tidyUpArtwork(artworkId: string) {
        this.dndWall?.tidyUpArtwork(artworkId)
    }

    tidyUpArtworks() {
        this.dndWall?.tidyUpArtworks()
    }

    centerArtwork(artworkId: string) {
        this.dndWall?.centerArtwork(artworkId)
    }

    centerArtworks() {
        this.dndWall?.centerArtworks()
    }

    getArtworkGraphic(artworkId: string) {
        if (!this.dndWall?.artworks)
            return
        // console.log(Object.values(this.dndWall?.artworks), artworkId)
        return this.dndWall?.artworks[artworkId]
    }
}