import { Box } from '@mui/material'
import { useEffect, useRef, useState, Fragment, useMemo } from 'react'
import FloorPlan from '../components/Content/FloorPlan'
import { useFloorPlanMapData } from '../hooks/useFloorPlanMapData'
import { LoadingPage } from './LoadingPage'
import { FloorPlanApp } from '../components/Content/pixi/floorPlanApp'
import { useAppSelector } from '../hooks'
import { Button } from '../styles/MUI/Button'
import exhibifyLogo from './../assets/img/logo-expanded.svg'
import { WallSide } from '../types/FloorPlan/Wall.types'

function ImageToLoad(
    {
        subtitle, title, onLoad, imageToExport, width, height, maxHeight
    }: {
        title?: string,
        subtitle?: string,
        onLoad?: () => void,
        imageToExport: HTMLImageElement,
        width?: number,
        height?: number,
        maxHeight?: number
    }) {
    const refImg = useRef<HTMLImageElement>(null)
    const [imageLoaded, setImageLoaded] = useState(false)
    const [imgMaxHeight, setMaxHeight] = useState(maxHeight)

    useEffect(() => {
        if (imageLoaded)
            return

        // console.log("IMPORT FINISHED")

        const getImages = async (refImg: React.RefObject<HTMLImageElement>) => {
            const image = imageToExport
            // console.log("Get images")

            if (image && refImg.current) {
                refImg.current.src = image.src
                onLoad && onLoad()
                setImageLoaded(true)
            }
        }

        getImages(refImg)

    }, [refImg, onLoad, setImageLoaded, imageLoaded, imageToExport, setMaxHeight])

    return <Box className='print-group'>
        {title && <h2>{title}</h2>}
        {subtitle && <h3>{subtitle}</h3>}
        <img
            alt=""
            className='blueprint-image'
            style={{
                maxHeight: imgMaxHeight,
                height,
                width: width ? width : 700,
                objectFit: "contain",
            }} ref={refImg} />
    </Box>
}

function ArtowrkWalls(
    { floorPlanApp, setLoadingPr }: {
        floorPlanApp: FloorPlanApp, setLoadingPr: (v: number) => void
    }) {
    const walls = useAppSelector((s) => s.floorPlan.floorPlan?.walls)
    const artworks = useAppSelector((s) =>
        s.floorPlan.floorPlan?.artworks ?
            Object.values(s.floorPlan.floorPlan?.artworks).filter(artwork => !!artwork.wallId)
            : undefined
    )

    const wallsAndArtworks = useMemo<
        {
            wallId: string,
            wallName: string,
            side: WallSide,
            selectedArtworkId?: string,
            selectedArtworkName?: string,
            selectedArtworkWidth?: number,
            selectedArtworkHeight?: number,
        }[]
    >(() => {
        if (!artworks)
            return []
        const artworkWalls = artworks.map(a => a.wallId ? { wallId: a.wallId, side: a.side } : "")
            .reduce<({ wallId: string, side: WallSide })[]>((pr, cr) => {
                if (cr === "")
                    return pr
                if (pr.filter(a => a.wallId === cr.wallId && a.side === cr.side).length > 0)
                    return pr
                return [...pr, cr]
            }, [])

        const wallsAndArtworks: {
            wallId: string,
            wallName: string,
            side: WallSide,
            selectedArtworkId?: string,
            selectedArtworkName?: string,
            selectedArtworkWidth?: number,
            selectedArtworkHeight?: number,
        }[] = []

        artworkWalls.forEach(({ wallId, side }) => {
            const wallName = walls ? Object.values(walls).filter(w => w.mapRefUUID === wallId)[0].name : "Wall"

            wallsAndArtworks.push({
                wallId,
                wallName,
                side
            })
            artworks.filter(a => a.wallId === wallId && a.side === side)
                .forEach(artwork => {
                    wallsAndArtworks.push(
                        {
                            wallId: wallId,
                            wallName: wallName,
                            selectedArtworkId: artwork.mapRefUUID,
                            side,
                            selectedArtworkName: artwork.title || "Untitled",
                            selectedArtworkWidth: artwork.fullWidth,
                            selectedArtworkHeight: artwork.fullHeight
                        }
                    )
                })
        })
        // console.log("walls", artworkWalls, wallsAndArtworks)

        return wallsAndArtworks
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const [loadedArtworks, setLoadedArtworks] = useState(0)
    useEffect(() => {
        if (wallsAndArtworks.length === 0)
            return

        // console.log("loaded artworks:", loadedArtworks)
        if (loadedArtworks === wallsAndArtworks?.length)
            setLoadingPr(100)
    }, [loadedArtworks, wallsAndArtworks, setLoadingPr])

    if (!!!artworks)
        return <div></div>

    return (
        <Fragment>
            {
                wallsAndArtworks.map((wallArtwork, i) => {

                    const image = floorPlanApp.extract.exportWall(wallArtwork.wallId, wallArtwork.side, wallArtwork.selectedArtworkId)
                    if (!!!image) return <div></div>

                    if (!wallArtwork.selectedArtworkId)
                        return (
                            < ImageToLoad
                                key={i}
                                imageToExport={image}
                                title={wallArtwork.wallName + " - " + wallArtwork.side + " side"}
                                onLoad={() => { setLoadedArtworks((pr) => pr + 1) }}
                                maxHeight={800}
                            />
                        )
                    return (
                        < ImageToLoad
                            key={i}
                            imageToExport={image}
                            subtitle={
                                "Artwork: "
                                + wallArtwork.selectedArtworkName
                                + " (" + wallArtwork.selectedArtworkWidth + "cm x " + wallArtwork.selectedArtworkHeight + "cm)"}
                            onLoad={() => { setLoadedArtworks((pr) => pr + 1) }}
                            maxHeight={400}
                            width={600}
                        />
                    )
                })
            }
        </Fragment>
    )
}

function PrintFooter({ title }: { title?: string }) {
    return <footer className='footer'>
        <img src={exhibifyLogo} alt="" />
        <h1>{title}</h1>
        <Button variant='contained' color="info" onClick={() => window.print()}>Download PDF</Button>
    </footer>
}

export function PrintPage() {
    const { loadingPr: floorPlanDataLoadingPr, loadingText } = useFloorPlanMapData()

    const exhibition = useAppSelector(s => s.exhibition.exhibition)
    const [floorPlanApp, setFloorPlanApp] = useState<FloorPlanApp>()

    const [loadingPr, setLoadingPr] = useState(0)

    useEffect(() => {
        setLoadingPr(floorPlanDataLoadingPr * 0.5)
        if (floorPlanApp && floorPlanDataLoadingPr === 100) {
            floorPlanApp.resourcesLoader.on("loadAll", () => {
                setLoadingPr(75)
            })
        }
    }, [floorPlanDataLoadingPr, floorPlanApp])

    const artworks = useAppSelector((s) => s.floorPlan.floorPlan?.artworks)
    /* 
        useEffect(() => {
            if (loadingPr === 100)
                window.print()
        }, [loadingPr]) */

    useEffect(() => {
        // console.log("floorPlanApp change", !!floorPlanApp)
        if (!floorPlanApp || !artworks)
            return

        Object.values(artworks).forEach((artwork) => {
            floorPlanApp.resourcesLoader.preloadTexture(artwork.imageURL)
        })
    }, [floorPlanApp, artworks])

    return (
        <Box className='print-page full-screen-container'>
            <Box className='print-container'>
                <Box className='print-content'>
                    <h1>Blueprint of {exhibition?.title}</h1>
                    {
                        // Floor plan to export everything
                        (loadingPr >= 50) && (
                            <div style={{ width: (loadingPr === 100 ? 1 : 800), height: (loadingPr === 100 ? 1 : 800) }}>
                                < FloorPlan setFloorPlanApp={setFloorPlanApp} />
                            </div>
                        )
                    }
                    {(loadingPr >= 75) && floorPlanApp && (
                        <Fragment>
                            <ImageToLoad
                                imageToExport={floorPlanApp.extract.exportFloorPlan()}
                                title={"Blueprint"}
                                onLoad={() => { setLoadingPr(85) }}
                                maxHeight={window.innerHeight - 150}
                            />

                            {(loadingPr >= 85) &&
                                <ArtowrkWalls floorPlanApp={floorPlanApp} setLoadingPr={setLoadingPr} />
                            }
                        </Fragment>
                    )}
                </ Box>
            </ Box>
            <PrintFooter title={"Blueprint of " + exhibition?.title} />
            <LoadingPage loadingPr={loadingPr} text={loadingText} displayNone={loadingPr === 100} />
        </Box>
    )
}