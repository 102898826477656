import * as PIXI from 'pixi.js'
import { FloorPlanApp } from '../floorPlanApp'

const rulerWidth = 20
const rulerBorderWidth = 3

class RulerLine extends PIXI.Container {
    app: FloorPlanApp = FloorPlanApp.getInstance()
    axis: "x" | "y"
    texts: PIXI.Container<PIXI.Text>
    graphic: PIXI.Graphics
    firstNum: number

    constructor(axis: "x" | "y") {
        super()
        this.axis = axis
        this.firstNum = -1
        this.addChild(this.graphic = new PIXI.Graphics())
        this.graphic[axis === "x" ? "y" : "x"] = -rulerBorderWidth

        this.addChild(this.texts = new PIXI.Container())

        this.texts[axis] = axis === "x" ? rulerBorderWidth : - rulerBorderWidth

    }

    reDraw(barSizeScaled: number, barSize: number, firstNum: number, force = false) {
        if (firstNum !== this.firstNum || force) {
            for (let i = 0; i < this.texts.children.length; i++) {
                const text = this.texts.getChildAt(i)
                text.text = (firstNum + barSize * (i - 1)).toFixed(0)
                text[this.axis] = barSizeScaled * (i - 1)
            }
            this.firstNum = firstNum

            // offset graphic when first num change (not when forced)
            if (!force)
                this.graphic[this.axis] = this.graphic[this.axis] === 0 ? -barSizeScaled : 0
            else
                this.graphic[this.axis] = this.graphic[this.axis] === 0 ? 0 : -barSizeScaled
        }
    }

    setTextsLength(length: number) {
        if (this.texts.children.length === length)
            return
        while (this.texts.children.length > length) {
            this.texts.getChildAt(this.texts.children.length - 1).destroy()
        }
        const style = { fontSize: rulerWidth - rulerBorderWidth }
        while (this.texts.children.length < length) {
            const text = new PIXI.Text("", style)
            this.texts.addChild(text)
            if (this.axis === "y")
                text.angle = -90
        }
    }

    resize(barSize: number, maxWidth: number) {
        this.graphic.clear()
            .lineStyle(rulerBorderWidth, 0xf0f0f0)

        let count = 0
        for (let width = -barSize; width < maxWidth + barSize; width += barSize, count++) {
            this.graphic.beginFill(count % 2 === 0 ? 0xdddddd : 0xcccccc)/* 
            if (count === 4)
                this.graphic.beginFill(0x00ff00) */

            if (this.axis === "x") {
                this.graphic.drawRect(width, 0, barSize, rulerWidth)

                this.graphic.moveTo(width + barSize / 2, rulerWidth / 2)
                this.graphic.lineTo(width + barSize / 2, rulerWidth)
            } else {
                this.graphic.drawRect(0, width, rulerWidth, barSize)

                this.graphic.moveTo(rulerWidth / 2, width + barSize / 2)
                this.graphic.lineTo(rulerWidth, width + barSize / 2)
            }
        }

        this.setTextsLength(count)
        this.graphic.endFill()

        // console.log(this.axis, count)

    }
}

export class Ruler extends PIXI.Container {
    app: FloorPlanApp = FloorPlanApp.getInstance()
    graphicX: RulerLine
    graphicY: RulerLine

    constructor() {
        super()
        this.addChild(this.graphicY = new RulerLine("y"))
        this.addChild(this.graphicX = new RulerLine("x"))
        this.onWindowResize()
    }

    setVisible(visible: boolean) {
        this.visible = visible

        if (visible)
            this.onWindowResize()
    }

    getBarSize() {
        return this.app.getTileSize() * 2
    }

    onWindowResize() {
        const barSize = this.getBarSize()

        const scale = this.app.currentCanvas.scale.x
        this.graphicX.resize(barSize * scale, this.app.getAppWidth())
        this.graphicY.resize(barSize * scale, this.app.getAppHeight())

        this.handleMove(true)
    }

    handleMove(force = false) {
        const initialPoint = this.app.currentCanvas.toLocal(new PIXI.Point(0, 0))
        const scale = this.app.currentCanvas.scale.x
        const barSize = this.getBarSize()
        const barSizeScaled = barSize * scale


        this.graphicX.x = -initialPoint.x * scale % barSizeScaled
        if (this.graphicX.x < 0) this.graphicX.x += barSizeScaled

        this.graphicY.y = -initialPoint.y * scale % barSizeScaled
        if (this.graphicY.y < 0) this.graphicY.y += barSizeScaled

        this.graphicX.reDraw(barSizeScaled, barSize, barSize * Math.ceil(initialPoint.x / barSize), force)
        this.graphicY.reDraw(barSizeScaled, barSize, barSize * Math.ceil(initialPoint.y / barSize), force)
    }
}