import clsx from 'clsx';
import { ToolType } from "../../types/Editor/Tool.types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setTool } from "../../store/reducers/Editor";
import { ButtonBase } from '@mui/material';

type Props = {
    toolId: ToolType,
    icon: string,
    children?: string
    style?: React.CSSProperties | undefined,
}

function ToolButton({ toolId, icon, children, style }: Props) {
    const currentTool = useAppSelector((state) => state.editor.currentTool.type)

    const isSelected = currentTool === toolId

    const dispatch = useAppDispatch()

    return (
        <ButtonBase
            style={style}
            className={clsx('tool-button-' + toolId, 'tool-btn', { 'selected': isSelected })}
            onClick={() => dispatch(setTool(toolId))}>
            <img src={icon} className='start-icon' alt='icon-edit' />
            <span>{children}</span>
        </ButtonBase>
    )
}
export default ToolButton;