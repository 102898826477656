import { Fragment, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { selectSculputreArtworks } from "../../store/reducers/Artworks"
import { createAndAddNewFloorPlanData, setSelectData } from "../../store/reducers/Editor"
import { setPropertyDimensionHeight, setPropertyDimensionWidth } from "../../store/reducers/FloorPlan/FloorPlan"
import DataPreviewGrid from "./Components/dataPreviewGrid"
import { InspectorBody } from "./Layout/inspectorBody"
import { InspectorHeader } from "./Layout/inspectorHeader"



export function SculpturesToolInspector() {

    const floorPlanSculptures = useAppSelector(state => state.floorPlan.floorPlan?.sculptures)
    const allSculptures = useAppSelector(selectSculputreArtworks)

    const dispatch = useAppDispatch()

    const [placedSculptures, setPlacedSculptures] = useState<{ [key: string]: boolean }>({})
    useEffect(() => {
        setPlacedSculptures(
            Object.fromEntries(Object.entries(allSculptures || []).map(([k, v]) => [k, !!(floorPlanSculptures && floorPlanSculptures[v.uuid] && !floorPlanSculptures[v.uuid].deleted)]))
        )
    }, [allSculptures, floorPlanSculptures])

    function setNewSculptureProperties(uuid: string) {

        const sculpture = allSculptures[uuid]
        if (!sculpture) return

        dispatch(createAndAddNewFloorPlanData({ type: "Sculpture", id: sculpture.uuid }))
        dispatch(setPropertyDimensionHeight({ id: sculpture.uuid, height: sculpture.height, type: "Sculpture" }))
        dispatch(setPropertyDimensionWidth({ id: sculpture.uuid, width: sculpture.width, type: "Sculpture" }))
    }
    return (
        <Fragment>
            <InspectorHeader>Sculptures</InspectorHeader>
            <InspectorBody>
                < DataPreviewGrid
                    data={Object.values(allSculptures).map((a) => {
                        return {
                            id: a.uuid,
                            previewImageURL: a.photo.original,
                            altText: a.title?.en_EN || a.photo.fileName
                        }
                    })}
                    onPointerDownData={(s) => {
                        if (!placedSculptures[s.id]) {
                            setNewSculptureProperties(s.id)
                        }

                        dispatch(setSelectData([{ id: s.id, type: "Sculpture" }]))
                    }}
                    dissabledData={placedSculptures}

                />
            </InspectorBody>
        </Fragment >
    )
}