import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { EditorState, SelectedData } from "../../types/Editor.types";
import { Tool, ToolType } from "../../types/Editor/Tool.types";
import { FloorPlanDataType, FloorPlanEditorSetting } from "../../types/FloorPlan/FloorPlan.types";

const editorSettingPrefix = "editor_settings_"
function getFromCookieDefault(editorSetting: FloorPlanEditorSetting, defaultValue: boolean) {
    if (defaultValue)
        return !(Cookies.get(editorSettingPrefix + editorSetting) === "false") // default true
    else
        return (Cookies.get(editorSettingPrefix + editorSetting) === "true") // default false
}


const initialState: EditorState = {
    currentTool: { type: "Select" },
    selectedData: [],
    editorSettings: {
        artworksVisible: getFromCookieDefault("artworksVisible", true),
        dimensionsVisible: getFromCookieDefault("dimensionsVisible", true),
        lightsVisible: getFromCookieDefault("lightsVisible", true),
        spotsVisible: getFromCookieDefault("spotsVisible", true),
        showRuler: getFromCookieDefault("showRuler", true),
        snapToGrid: getFromCookieDefault("snapToGrid", false),
        floorPreviewVisible: getFromCookieDefault("floorPreviewVisible", false),
        wallName: getFromCookieDefault("wallName", false),

        lockDegrees: getFromCookieDefault("lockDegrees", false),
        inspectorExpanded: getFromCookieDefault("inspectorExpanded", true),
    },
    isHelpRunning: false,
    floorTexturesAvaliable: [],
}

export const toolSlice = createSlice({
    name: 'tool',
    initialState,
    reducers: {
        setTool: (state, { payload }: PayloadAction<ToolType>) => { },
        setToolSuccess: (state, { payload }: PayloadAction<Tool>) => {
            // console.log("setToolSuccess", payload)
            state.currentTool = payload;
        },
        setToolDetails: (state, { payload }: PayloadAction<{ wallSelected?: string, sideSelected?: 'right' | 'left' }>) => {
            const { wallSelected, sideSelected } = payload
            // console.log("setToolDetails", payload)
            if (state.currentTool.type === "PlaceArtworks") {
                state.currentTool.wallSelected = wallSelected
                state.currentTool.sideSelected = sideSelected
            }
        },

        createAndAddNewFloorPlanData: (state, { payload }: PayloadAction<{ type: FloorPlanDataType, id?: string }>) => { },
        duplicateSelection: () => { },

        setSelectData: (state, { payload }: PayloadAction<SelectedData[]>) => {
            // console.log("setSelectData:", payload)
            state.selectedData = payload
        },

        toggleEditorSetting: (state, { payload }: PayloadAction<FloorPlanEditorSetting>) => {
            state.editorSettings = {
                ...state.editorSettings,
                [payload]: !state.editorSettings[payload]
            }
            Cookies.set(
                editorSettingPrefix + payload.toString(),
                state.editorSettings[payload].toString()
            )
        },
        setEditorSetting: (state, { payload }: PayloadAction<{ setting: FloorPlanEditorSetting, value: boolean }>) => {
            state.editorSettings = {
                ...state.editorSettings,
                [payload.setting]: payload.value
            }
            Cookies.set(
                editorSettingPrefix + payload.setting.toString(),
                payload.value.toString()
            )
        },
        setIsHelpRunning: (state, { payload }: PayloadAction<boolean>) => {
            state.isHelpRunning = payload
        }
    }
})

export const {
    setTool,
    setToolSuccess,
    setToolDetails,
    createAndAddNewFloorPlanData,
    duplicateSelection,
    setSelectData,
    toggleEditorSetting,
    setEditorSetting,
    setIsHelpRunning
} = toolSlice.actions

export default toolSlice.reducer