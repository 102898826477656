import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeleteModalOption, UIState } from "../../types/UI.types";


const initialState: UIState = {
    inspector: {
        wallsCornersExpanded: false
    },
    deleteModalOpen: false,
    deleteModalOption: "Selected"
}

export const ui = createSlice({
    name: "exhibition",
    initialState,
    reducers: {
        toggleWallsCorners: (state) => {
            state.inspector.wallsCornersExpanded = !state.inspector.wallsCornersExpanded
        },
        confirmDeleteModalOpen: (state, { payload }: PayloadAction<undefined | DeleteModalOption>) => {
            state.deleteModalOption = payload ? payload : "Selected"
            state.deleteModalOpen = true
        },
        confirmDeleteModalClose: (state) => {
            state.deleteModalOpen = false
        }
    }
})

export const { toggleWallsCorners, confirmDeleteModalOpen, confirmDeleteModalClose } = ui.actions

export default ui.reducer