import * as PIXI from 'pixi.js'
import { InteractionEvent } from "pixi.js";
import { FloorPlanElement } from "../components/floorPlanElement";
import { VisiblePoint } from "../components/visiblePoint";
import FloorPlanConnector from "../floorPlanConnector";
import { Tool } from "./tool"
import { ToolType } from "../../../../types/Editor/Tool.types";
import { FloorPlanDataType } from "../../../../types/FloorPlan/FloorPlan.types";
import { ComponentOrientations } from '../../../../types/FloorPlan/OrientableComponent.types';

function getOrientation(A: PIXI.Point, B: PIXI.Point): ComponentOrientations {
    const delta_x = B.x - A.x;
    const delta_y = B.y - A.y;

    const angle = (Math.atan2(delta_y, delta_x) * 180 / Math.PI + 180) % 360;
    const direction = Math.round(angle / 45) % 8;

    switch (direction) {
        case 1: return "BOTTOM_RIGHT"
        case 2: return "BOTTOM"
        case 3: return "BOTTOM_LEFT"
        case 4: return "LEFT"
        case 5: return "TOP_LEFT"
        case 6: return "TOP"
        case 7: return "TOP_RIGHT"
        default: return "RIGHT"
    }
}

export class PlaceOrientableComponentTool<ElementType extends FloorPlanElement> extends Tool {
    protected floorPlanElement?: ElementType
    private floorPlanDataType: FloorPlanDataType
    private clickActive: PIXI.Point | false

    constructor(toolType: ToolType, floorPlanDataType: FloorPlanDataType) {
        super()
        this.toolType = toolType
        this.floorPlanDataType = floorPlanDataType
        this.clickActive = false
    }

    // interaction    
    onClick(e: InteractionEvent, element?: FloorPlanElement | VisiblePoint): void {
        super.onClick(e, element)
        if (this._draggingMode === 'option') {
            FloorPlanConnector.setTool("Select")
        }

        if (this._draggingMode !== 'tool')
            return

        const floorPlanElement = this.floorPlanElement
        if (!floorPlanElement) {
            console.warn("No element on tool")
            return
        }
        this.clickActive = e.data.getLocalPosition(this.app.floorPlanCanvas).clone()

        this.app.addElement(floorPlanElement)

    }

    onDragMove(e: InteractionEvent): void {
        if (!this.floorPlanElement)
            return

        const interactionPoint = e.data.getLocalPosition(this.app.floorPlanCanvas)


        if (this.clickActive) {
            FloorPlanConnector.setElementOrientation(getOrientation(interactionPoint, this.clickActive), this.floorPlanElement.mapRefUUID, this.floorPlanDataType)
        } else {
            if (this.app.snapToGrid) {
                interactionPoint.x = Math.round((interactionPoint.x) / 50) * 50
                interactionPoint.y = Math.round((interactionPoint.y) / 50) * 50
            }
            FloorPlanConnector.moveElementTo(interactionPoint.x, interactionPoint.y, this.floorPlanElement.mapRefUUID, this.floorPlanDataType)
        }
    }

    onDragEnd(e: InteractionEvent, setPlaced: boolean = true): void {
        if (this._draggingMode !== 'tool' || !!!this.clickActive)
            return

        this.clickActive = false
        if (this.floorPlanElement && setPlaced) {
            FloorPlanConnector.elementPlaced(this.floorPlanDataType, this.floorPlanElement.mapRefUUID)
        }
    }

    enable() {
        this.clickActive = false
    }

    dissable() {
        if (!this.floorPlanElement)
            return

        this.app.removeElement(this.floorPlanElement)
        this.floorPlanElement = undefined
    }
}