import { FloorPlanData } from './FloorPlanElement.types';
// import faker from 'faker';


export interface WallStyle {
  hasTexture: boolean;
  color: string;
  textureURL: string;
  hasColorBack: boolean;
  colorBack: string;
}

export type WallSide = "left" | "right"
export type SimplePoint = { x: number, y: number }

export class Wall extends FloorPlanData {
  name: string
  uuid: string | null
  origin: SimplePoint
  destiny: SimplePoint
  width: number
  // height: number
  thickness: number
  color: string
  colorBack: string
  hasColorBack: boolean
  hasTexture: boolean
  textureURL: string
  angle: number
  region: number

  constructor(mapRefUUID?: string) {
    super(mapRefUUID)

    this.name = "Wall "
    this.origin = { x: 0, y: 0 }
    this.destiny = { x: 0, y: 0 }
    this.width = 0
    this.uuid = null

    this.thickness = 10
    this.color = "#ffffff"
    this.colorBack = "#ffffff"
    this.hasColorBack = false
    this.hasTexture = false
    this.textureURL = ""
    this.angle = 0
    this.region = 0
  }

  computePropertiesFromPoints() {
    // console.log("computePropertiesFromPoints", this.mapRefUUID, this.origin.x, this.origin.y, this.destiny.x, this.destiny.y)

    this.x = (this.destiny.x + this.origin.x) / 2
    this.y = (this.destiny.y + this.origin.y) / 2

    var dx = this.destiny.x - this.origin.x
    var dy = this.destiny.y - this.origin.y
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    //if (theta < 0) theta = 360 + theta; // range [0, 360)

    this.width = Math.sqrt(dy * dy + dx * dx)

    this.angle = theta
  }

  computePointsFromProperties() {

    // console.log("computePointsFromProperties")

    this.destiny.x = this.x + Math.cos(this.angle * Math.PI / 180) * (this.width / 2)
    this.destiny.y = this.y + Math.sin(this.angle * Math.PI / 180) * (this.width / 2)

    this.origin.x = this.x - Math.cos(this.angle * Math.PI / 180) * (this.width / 2)
    this.origin.y = this.y - Math.sin(this.angle * Math.PI / 180) * (this.width / 2)

  }

}