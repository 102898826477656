import { OrientableComponent } from "./OrientableComponent.types";

export enum SpotTypesEnum {
  MOVEMENT = 'MOVEMENT',
  ARTWORK = 'ARTWORK'
}

export class Spot extends OrientableComponent {
  uuid: string | null

  type: SpotTypesEnum
  name: string
  artworkUUID: string | undefined


  constructor(mapRefUUID?: string) {
    super(mapRefUUID)

    this.name = "Spot "

    this.type = SpotTypesEnum.ARTWORK

    this.uuid = null
  }
}