import { useEffect } from 'react'

import { Routes, Route, Outlet, BrowserRouter, useSearchParams } from "react-router-dom";
import Editor from "./pages/EditorPage";
import './App.scss';
import UnknownPage from "./pages/UnknownPage";
import { useAppDispatch, useAppSelector } from "./hooks";
import { authRequest } from './store/reducers/Auth';
import FullScreenPage from './pages/FullScreenPage';
import { PrintPage } from './pages/PrintPage';

const builderVersion = "1.2";
console.log("Builder version: " + builderVersion)

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Editor />} />

          <Route path='/print' element={<PrintPage />} />

          {/* <Route path="/" element={<Editor />} /> */}

          {/* <Route path="authError" element={<AuthErrorPage />} /> */}

          <Route index path="*" element={<UnknownPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Layout() {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const authStatus = useAppSelector((state) => state.auth.status)
  const exhibitionStatus = useAppSelector((state) => state.exhibition.exhibitionStatus)

  useEffect(() => {
    const tt = searchParams.get("tt")
    if (authStatus === "pending")
      dispatch(authRequest(tt || ""))

    else if (tt && authStatus === "valid_token") {
      searchParams.delete("tt")
      setSearchParams(searchParams)
    }
  }, [dispatch, searchParams, setSearchParams, authStatus]);

  if (authStatus === "invalid_token")
    return <FullScreenPage title="Authentification error" subtitle='Please, open the link again from the original site.' />
  if (exhibitionStatus === "Error_NoUuidProvided") {
    return <FullScreenPage title="Error" subtitle='No exhibition id provided' />
  }
  if (exhibitionStatus === "Error_Uuid") {
    return <FullScreenPage title="Error" subtitle='Exhibition not found' />
  }
  if (exhibitionStatus.substring(0, 5) === "Error") {
    return <FullScreenPage title="Error" />
  }
  // return <div>{exhibitionStatus}<Outlet /></div>
  return <Outlet />
}
