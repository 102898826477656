import * as PIXI from 'pixi.js'
import { FloorPlanDataType } from '../../../../types/FloorPlan/FloorPlan.types'
import { FloorPlanApp } from '../floorPlanApp'
// import faker from 'faker';

export class FloorPlanElement extends PIXI.Container {
    app: FloorPlanApp = FloorPlanApp.getInstance()
    selected: boolean = false
    mapRefUUID: string
    type: FloorPlanDataType

    constructor(type: FloorPlanDataType, mapRefUUID: string) {
        super()
        this.type = type
        this.mapRefUUID = mapRefUUID
    }

    select() {
        if (this.selected)
            return

        this.selected = true
        this.alpha = 1
    }
    unselect() {
        if (!this.selected)
            return

        this.selected = false
    }

    handlePointerOver() {
        this.alpha = .75
    }

    handlePointerOut() {
        this.alpha = 1
    }

    move(dx: number, dy: number) {
        this.x += dx
        this.y += dy
    }

    moveTo(x: number, y: number) {
        this.x = x
        this.y = y
    }

    setInteractive(interactive: boolean) {
        throw new Error("setInteractive should be developed by class " + this.constructor.name)
        // developed in child
    }
}