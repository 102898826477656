
import logo from './../../assets/img/logo-icon.svg'
import logoExpanded from './../../assets/img/logo-expanded.svg'
import EntranceIcon from './../../assets/img/icon-entrance.svg';
import SelectIcon from './../../assets/img/icon-select.svg';
import SettingsIcon from './../../assets/img/icon-settings.svg';
import SculpturesIcon from './../../assets/img/icon-sculptures.svg';
import LightsIcon from './../../assets/img/icon-lights.svg';
import SpotsIcon from './../../assets/img/icon-fp-spot.svg';
import PlaceArtworksIcon from './../../assets/img/icon-place-artworks.svg'
import HelpIcon from './../../assets/img/icon-question-mark.svg'
import SaveIcon from './../../assets/img/save-icon.svg'
import SaveIconNotify from './../../assets/img/save-icon-notify.svg'
import SaveIconError from './../../assets/img/save-icon-error.svg'

import DrawWallsIcon from './../../assets/img/icon-draw-walls.svg';
import ToolButton from './ToolButton';
import { Fragment, useEffect, useState } from 'react';
import { Box, ButtonBase, Switch } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { disableAutoSave, enableAutoSave, saveFloorPlanRequest } from '../../store/reducers/FloorPlan/FloorPlan';

function ToolsPanel() {

    const dispatch = useAppDispatch()

    const saveState = useAppSelector(state => state.floorPlan.saveState)
    const autoSave = useAppSelector(state => state.floorPlan.autoSave)

    const [saveIcon, setSaveIcon] = useState(SaveIcon)
    useEffect(() => {
        switch (saveState) {
            case "CHANGES_TO_SAVE":
                setSaveIcon(SaveIconNotify)
                break
            case "SAVE_ERROR":
                setSaveIcon(SaveIconError)
                break
            default:
                setSaveIcon(SaveIcon)
                break
        }
    }, [saveState])

    return (
        <Fragment>

            <Box className="logo">
                <img className='logo-image collapsed' src={logo} alt="" />
                <img className='logo-image expanded' src={logoExpanded} alt="" />
            </Box>

            <ToolButton toolId='Select' icon={SelectIcon}>Select</ToolButton>

            <ToolButton toolId='DrawWalls' icon={DrawWallsIcon}>Draw Walls</ToolButton>

            <ToolButton toolId='Entrance' icon={EntranceIcon}>Entrance</ToolButton>

            <ToolButton toolId='PlaceArtworks' icon={PlaceArtworksIcon}>Place Artworks</ToolButton>

            <ToolButton toolId='Sculptures' icon={SculpturesIcon}>Sculptures</ToolButton>

            <ToolButton toolId='Lights' icon={LightsIcon}>Lights</ToolButton>

            <ToolButton toolId='Spots' icon={SpotsIcon}>Spots</ToolButton>


            <ButtonBase
                style={{ marginTop: "auto" }}
                className={'tool-btn'}
                onClick={() => {
                    dispatch(saveFloorPlanRequest())
                }}
                disabled={saveState === "SAVING"}
            >
                <img
                    src={saveIcon}
                    className='start-icon'
                    alt='icon-edit'
                />
                <span>{saveState === "SAVING" ? "Saving..." : "Save"}</span>
                <Switch
                    title={"auto save " + (autoSave ? "(on)" : "(off)")}
                    color='info'
                    sx={{ ml: "auto" }}
                    checked={autoSave}
                    size="small"
                    onClick={() => {
                        if (autoSave)
                            dispatch(disableAutoSave())
                        else
                            dispatch(enableAutoSave())
                    }}
                />
            </ButtonBase>

            <ToolButton toolId='Settings' icon={SettingsIcon}>Settings</ToolButton>

            <ToolButton toolId='Help' icon={HelpIcon}>Help</ToolButton>

        </Fragment>
    )
}
export default ToolsPanel;