import * as PIXI from 'pixi.js'
import { FloorPlanDataType } from '../../../../../types/FloorPlan/FloorPlan.types';
import { ComponentOrientations } from '../../../../../types/FloorPlan/OrientableComponent.types';
import { FloorPlanApp } from '../../floorPlanApp';
import FloorPlanConnector from '../../floorPlanConnector';
import { FloorPlanElement } from '../floorPlanElement'

class Arc extends PIXI.Graphics {
    orientation: ComponentOrientations
    mainAngle: number
    radius: number
    private _selected: boolean = false
    startAngle: number
    endAngle: number

    constructor(
        orientableComponent: OrientableFloorPlanElement,
        radius: number,
        orientation: ComponentOrientations
    ) {
        super()
        this.orientation = orientation
        this.radius = radius

        switch (orientation) {
            case 'RIGHT':
                this.startAngle = 1.9 * Math.PI
                this.endAngle = 0.1 * Math.PI
                this.mainAngle = 0
                break
            case 'BOTTOM_RIGHT':
                this.startAngle = 0.15 * Math.PI
                this.endAngle = 0.35 * Math.PI
                this.mainAngle = 0.25 * Math.PI
                break
            case 'BOTTOM':
                this.startAngle = 0.4 * Math.PI
                this.endAngle = 0.6 * Math.PI
                this.mainAngle = 0.5 * Math.PI
                break
            case 'BOTTOM_LEFT':
                this.startAngle = 0.65 * Math.PI
                this.endAngle = 0.85 * Math.PI
                this.mainAngle = 0.75 * Math.PI
                break
            case 'LEFT':
                this.startAngle = 0.9 * Math.PI
                this.endAngle = 1.1 * Math.PI
                this.mainAngle = 1 * Math.PI
                break
            case 'TOP_LEFT':
                this.startAngle = 1.15 * Math.PI
                this.endAngle = 1.35 * Math.PI
                this.mainAngle = 1.25 * Math.PI
                break
            case 'TOP':
                this.startAngle = 1.4 * Math.PI
                this.endAngle = 1.6 * Math.PI
                this.mainAngle = 1.5 * Math.PI
                break
            case 'TOP_RIGHT':
                this.startAngle = 1.65 * Math.PI
                this.endAngle = 1.85 * Math.PI
                this.mainAngle = 1.75 * Math.PI
                break
            default:
                this.mainAngle = 0
                this.startAngle = 0
                this.endAngle = 0
                return
        }

        this.reDraw()
        this.interactive = true

        this.on('pointerover', () => {
            this.alpha = .5
            if (!this._selected)
                this.tint = 0x4545fe
        })
        this.on('pointerout', () => {
            this.alpha = 1
            if (!this._selected)
                this.tint = 0xffffff
        })

        // notify component
        this.on('pointerdown', () => {
            orientableComponent.setOrientation(this.orientation)
            orientableComponent.notifyOrientationChange(this.orientation)
        })
    }

    reDraw() {
        this.clear()
            .lineStyle(10, 0xcccccc)
            .arc(0, 0, this.radius, this.startAngle, this.endAngle)

        this.hitArea = this.getLocalBounds()

    }

    setSelected(selected: boolean) {
        if (this._selected === selected)
            return

        this._selected = selected

        if (selected)
            this.tint = 0x00ff00
        else
            this.tint = 0xffffff
    }
}

class ArcsContainer extends PIXI.Container {
    unselectedArcs: PIXI.Container
    selectedArc?: Arc

    constructor() {
        super()

        this.unselectedArcs = new PIXI.Container()
        this.addChild(this.unselectedArcs)
    }
}

export class OrientableFloorPlanElement extends FloorPlanElement {
    // basic orientable component properties
    sprite?: PIXI.Container
    orientation: ComponentOrientations
    rotateSpriteWithOrientation: boolean
    radius: number

    // arcs
    arcs: { [key in ComponentOrientations]: Arc } // all arcs graphics
    arcsContainer: ArcsContainer // the container that will hace all arcs

    // graphic
    circle: PIXI.Graphics

    constructor(
        type: FloorPlanDataType,
        x: number,
        y: number,
        mapRefUUID: string,
        orientation?: ComponentOrientations,
        textureURL?: string,
        rotateSpriteWithOrientation: boolean = false,
        radius: number = 20
    ) {
        super(type, mapRefUUID)

        // set object variables
        this.radius = radius
        this.orientation = orientation || "TOP"
        this.rotateSpriteWithOrientation = rotateSpriteWithOrientation

        // create arcs
        this.arcs = {
            RIGHT:
                new Arc(this, radius, 'RIGHT'),
            BOTTOM_RIGHT:
                new Arc(this, radius, 'BOTTOM_RIGHT'),
            BOTTOM:
                new Arc(this, radius, 'BOTTOM'),
            BOTTOM_LEFT:
                new Arc(this, radius, 'BOTTOM_LEFT'),
            LEFT:
                new Arc(this, radius, 'LEFT'),
            TOP_LEFT:
                new Arc(this, radius, 'TOP_LEFT'),
            TOP:
                new Arc(this, radius, 'TOP'),
            TOP_RIGHT:
                new Arc(this, radius, 'TOP_RIGHT'),
        }

        // add arcs container
        this.arcsContainer = new ArcsContainer()
        this.addChild(this.arcsContainer)
        Object.values(this.arcs).forEach(arc => {
            this.arcsContainer.unselectedArcs.addChild(arc)
        })

        // circle that is gonna define the bounds of the object
        this.circle = new PIXI.Graphics()
        this.addChild(this.circle)
        this.reDraw()

        // interactivity
        this.circle.alpha = 0

        this.circle
            .on('pointerover', () => {
                this.handlePointerOver()
                this.circle.alpha = 1
            }).on('pointerout', () => {
                this.handlePointerOut()
                this.circle.alpha = 0
            }).on('pointerdown', (e) => {
                FloorPlanApp.getInstance().onClick(e, this)
            })

        // set sprite
        if (textureURL) {
            // outsample the sprite, to hace better resolution when scaling
            /* const texture = PIXI.Texture.from(textureURL,
                { resourceOptions: { height: radius * 5, width: radius * 5 } })
            this.app.resourcesLoader.add(type)
            // console.log("Start", type, rnd)
            texture.on("update", () => {
                this.app.resourcesLoader.load(type)
                // console.log("loadd", type, rnd)
            }) */
            const texture = this.app.resourcesLoader.getTexture(textureURL, { resourceOptions: { height: radius * 5, width: radius * 5 } })
            const sprite = new PIXI.Sprite(texture)
            sprite.width = radius * 1.8
            sprite.height = radius * 1.8

            const mask = new PIXI.Graphics() // the mask is a copy of the circle
                .beginFill(0xcccccc)
                .drawCircle(0, 0, radius)
                .endFill()
            this.addChild(mask)
            sprite.mask = mask

            sprite.anchor.set(0.5) // center

            this.sprite = sprite
            this.addChild(sprite) // add
        }

        this.setOrientation(this.orientation)
        this.unselect()

        this.x = Number(x)
        this.y = Number(y)
    }

    handlePointerOver() {
        this.circle.alpha = .75
    }

    handlePointerOut() {
        this.circle.alpha = 1
    }

    reDraw() {
        this.circle
            .clear()
            .beginFill(0xdfdfdf)
            .drawCircle(0, 0, this.radius)
            .endFill()
        Object.values(this.arcs).forEach(arc => {
            arc.radius = this.radius + 10
            arc.reDraw()
        })
    }

    resize(radius: number) {
        this.radius = radius
        this.reDraw()
    }

    select(showArc: boolean = true) {
        super.select()
        if (showArc)
            this.showArc()
    }
    unselect() {
        super.unselect()
        this.hideArc()
    }
    showArc() {
        this.arcsContainer.unselectedArcs.alpha = 1
        this.arcsContainer.unselectedArcs.interactiveChildren = true
        if (this.arcsContainer.selectedArc)
            this.arcsContainer.selectedArc.interactive = true
    }
    hideArc() {
        this.arcsContainer.unselectedArcs.alpha = 0
        this.arcsContainer.unselectedArcs.interactiveChildren = false
        if (this.arcsContainer.selectedArc)
            this.arcsContainer.selectedArc.interactive = false
    }

    setInteractive(interactive: boolean) {
        this.circle.interactive = interactive
    }

    hideOrientation() {
        if (this.arcsContainer.selectedArc) {
            this.arcsContainer.selectedArc.setSelected(false)
            this.arcsContainer.unselectedArcs.addChild(this.arcsContainer.selectedArc)
        }
    }

    showOrientation() {
        this.setOrientation(this.orientation)
    }

    setOrientation(newOrientation: ComponentOrientations) {
        this.orientation = newOrientation

        // notify old selected arc that its not
        if (this.arcsContainer.selectedArc) {
            this.arcsContainer.selectedArc.setSelected(false)
            this.arcsContainer.unselectedArcs.addChild(this.arcsContainer.selectedArc)
        }

        this.arcsContainer.selectedArc = this.arcs[this.orientation]
        this.arcsContainer.selectedArc.setSelected(true)
        this.arcsContainer.addChild(this.arcsContainer.selectedArc)

        // if rotate sprite, get from the corresponding arc
        if (this.rotateSpriteWithOrientation && this.sprite)
            this.sprite.rotation = this.arcsContainer.selectedArc.mainAngle

    }

    notifyOrientationChange(newOrientation: ComponentOrientations) {
        FloorPlanConnector.setElementOrientation(newOrientation, this.mapRefUUID, this.type)
    }
}