import * as PIXI from 'pixi.js'
import { FloorPlanApp } from '../floorPlanApp'
import { Emitter } from '../../../../utils/EventEmmiter'


export class ResourcesLoader extends Emitter<{ "loadAll": [] }> {
    app: FloorPlanApp = FloorPlanApp.getInstance()
    private toLoad: { [key: string]: { loaded: boolean, texture?: PIXI.Texture } } = {}

    private load(name: string) {
        const loaded = this.toLoad[name]?.loaded
        if (loaded === undefined) {
            console.error("no entry of " + name)
            return
        }

        if (loaded) {
            // console.log("already loaded", name, !!this.toLoad[name].texture?.baseTexture)
            return
        }

        // console.log("LOADED", name, !!this.toLoad[name].texture?.baseTexture)
        this.toLoad[name].loaded = true

        if (Object.values(this.toLoad).filter((o => !o.loaded)).length === 0) {
            // console.log("LOAD ALL")
            this.emit("loadAll")
        }


        /* 
             // console.log("load",
                    name,
                    Object.entries(this.toLoad)
                        .filter((([a, b]) => b.loaded === false))
                        .map(([a, b]) => a)
                ) */

    }

    getTexture(id: string, options?: PIXI.IBaseTextureOptions<any>) {
        // console.log("Get texture", id)
        if (this.toLoad[id])
            return this.toLoad[id].texture

        return this.preloadTexture(id, options)
    }

    preloadTexture(id: string, options?: PIXI.IBaseTextureOptions<any>) {

        const texture = PIXI.Texture.from(id, options, false)
        // console.log("LOAD texture", id, texture.baseTexture)

        this.toLoad[id] = {
            loaded: texture.baseTexture.valid,
            texture
        }

        if (!texture.baseTexture.valid)
            texture.baseTexture.on("loaded", () => {
                this.load(id)
                // console.log("loadd", id)
            })


        return texture
    }
}